import {
  Container,
  Stack,
  Breadcrumbs,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { GridCellParams, GridColDef } from "@mui/x-data-grid";

import useModal from "../../hooks/use-modal";
import { CustomDataGrid } from "../../components/common/datagrid";

import { breadCrumbLink } from "../../styles/common/bread-crumb-link";

import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useQueryEvaluationComponent,
  useQueryListAcadCareerDropDown,
  useQueryListInstitutionDropDown,
  useQueryListTrackDropDown,
} from "../../api/dropdown";
import { DropdownResponseData } from "../../types/dropdown";
import {
  useMutationAddNewEvaluationIndicator,
  useMutationDeleteEvaluationMapping,
  useMutationEvaluationMappingList,
} from "../../api/setup-enrichment-program/evaluation-mapping";
import {
  EvaluationMappingList,
  EvaluationMappingResponse,
} from "../../types/setup-enrichment-program/evaluation-mapping";
import { useMutationDeleteEvaluationComponent } from "../../api/setup-enrichment-program/evaluation-component";

export function EvaluationMapping() {
  const { showModal } = useModal();

  const [institutionDrop, setInstitutionDrop] = useState<
    DropdownResponseData[]
  >([]);
  const [trackDrop, setTrackDrop] = useState<DropdownResponseData[]>([]);
  const [evaluationDrop, setEvaluationDrop] = useState<DropdownResponseData[]>(
    []
  );
  const [acadCareerDrop, setAcadCareerDrop] = useState<DropdownResponseData[]>(
    []
  );

  const [checkedIds, setCheckedIds] = useState([]);

  const handleCheckboxChange = (indicatorId) => {
    setCheckedIds((prev: any) => {
      if (prev.includes(indicatorId)) {
        return prev.filter((itemId) => itemId !== indicatorId);
      }
      return [...prev, { id: indicatorId }];
    });
  };

  const [institution, setInstitution] = useState("");
  const [acadCareer, setAcadCareer] = useState("");
  const [track, setTrack] = useState("");
  const [evaluationComponent, setEvaluationComponent] = useState("");
  const [componentId, setComponentId] = useState("");

  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 1,
  });
  const [evaluationMappingList, setEvaluationMappingList] = useState<
    EvaluationMappingList[]
  >([]);

  const [openAddNewIndicatorModal, setOpenAddNewIndicatorModal] =
    useState(false);

  const getEvaluationMappingList = useMutationEvaluationMappingList({
    query: {
      search: "",
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page,
      orderBy: "id:asc",
    },
    options: {
      onSuccess: async (res) => {
        setEvaluationMappingList(res.data);
      },
      onError: async (err) => {
        setEvaluationMappingList([]);
      },
    },
  });

  const handleSearch = () => {
    getEvaluationMappingList.mutate({
      institution,
      academicCareer: acadCareer,
      track,
      evaluationComponent,
    });
  };

  const submitAddEvaluationIndicator = useMutationAddNewEvaluationIndicator({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Indicator Successfully Created",
        options: {
          variant: "success",
          onOk() {
            setOpenAddNewIndicatorModal(false);
            setCheckedIds([]);
            handleSearch();
          },
        },
      });
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const handleAdd = () => {
    submitAddEvaluationIndicator.mutate({
      institution,
      academicCareer: acadCareer,
      track,
      evaluationComponent,
      indicator: checkedIds,
    });
  };

  const getTrack = useQueryListTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTrackDrop(res.data || []);
      },
      onError: () => {},
    },
  });

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setInstitutionDrop(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setAcadCareerDrop(res.data || []);
      },
      onError: () => {
        setAcadCareerDrop([]);
      },
    },
  });

  const getEvaluationComponent = useQueryEvaluationComponent({
    query: {},
    options: {
      onSuccess: (res) => {
        setEvaluationDrop(res.data || []);
      },
      onError: () => {
        setEvaluationDrop([]);
      },
    },
  });

  const deleteEvaluationMapping = useMutationDeleteEvaluationMapping({
    options: {
      onSettled: async (res) => {
        getEvaluationMappingList.mutate({
          institution,
          academicCareer: acadCareer,
          track,
          evaluationComponent,
        });
      },
      onSuccess: async (res) => {
        showModal({
          title: "Success",
          message: "Delete evaluation component successfully.",
          options: {
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        showModal({
          title: "Failed",
          message: "Delete evaluation component is failed.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No",
      flex: 0.5,
      maxWidth: 100,
      hideable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Indicator",
      flex: 1,
      hideable: false,
    },
    {
      field: "",
      headerName: "Action",
      flex: 1,
      maxWidth: 100,
      hideable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams) => (
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteConfirmation(params.id as string)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleDeleteConfirmation = (idComponent: string) => {
    showModal({
      title: "Alert",
      message: "Are you sure want delete this indicator?",
      options: {
        variant: "info",
        buttonTitle: "Delete",
        buttonTitle2: "Cancel",
        onOk() {
          setComponentId(idComponent);

          deleteEvaluationMapping.mutate({
            id: idComponent,
            institution,
            academicCareer: acadCareer,
            track,
            evaluationComponent,
          });
        },
      },
    });
  };

  return (
    <>
      <Container maxWidth={false}>
        <Stack mt={2} spacing={2}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
              Setup Enrichment Program
            </Link>
            <Typography fontSize={14} color="#0097DA">
              Evaluation Mapping
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Typography variant="h5" gutterBottom mt={2}>
          Evaluation Mapping
        </Typography>
        <Divider />

        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="institution-select">Institution</InputLabel>
              <Select
                labelId="institution-select"
                id="institution"
                value={institution}
                label="Institution"
                onChange={(e) => setInstitution(e.target.value)}
              >
                {institutionDrop.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="acad-career-select">Academic Career</InputLabel>
              <Select
                labelId="acad-career-select"
                id="acad-career"
                value={acadCareer}
                label="Academic Career"
                onChange={(e) => setAcadCareer(e.target.value)}
              >
                {acadCareerDrop.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="track-select">Track</InputLabel>
              <Select
                labelId="track-select"
                id="track"
                value={track}
                label="Track"
                onChange={(e) => setTrack(e.target.value)}
              >
                {trackDrop.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="evaluation-component-select">
                Evaluation Component
              </InputLabel>
              <Select
                labelId="evaluation-component-select"
                id="evaluation-component"
                value={evaluationComponent}
                label="Evaluation Component"
                onChange={(e) => setEvaluationComponent(e.target.value)}
              >
                {evaluationDrop.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid display="flex" justifyContent="end" mt={2} mb={2}>
          <Button
            onClick={() => handleSearch()}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </Grid>

        <Divider />

        <Box mt={3}>
          <Grid display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h5" gutterBottom>
              Evaluation Indicator
            </Typography>
            <Button
              onClick={() => setOpenAddNewIndicatorModal(true)}
              variant="contained"
              color="primary"
            >
              Add New Indicator
            </Button>
          </Grid>

          <Paper elevation={3}>
            <CustomDataGrid
              columnsData={columns}
              rowsData={evaluationMappingList[0]?.indicator || []}
              rowCount={totalItems}
              getRowId={(row) => row.id}
              setPaginationModel={(value) => {
                setPaginationModel(value);
              }}
              paginationModel={paginationModel}
            />
          </Paper>
        </Box>
      </Container>
      <Dialog
        open={openAddNewIndicatorModal}
        onClose={() => {
          setOpenAddNewIndicatorModal(false);
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Add New Evaluation Indicator
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenAddNewIndicatorModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={2}>
            <FormControl size="small" fullWidth>
              <InputLabel id="evaluation-component-select">
                Evaluation Component
              </InputLabel>
              <Select
                labelId="evaluation-component-select"
                id="evaluation-component"
                value={evaluationComponent}
                disabled
                label="Evaluation Component"
                onChange={(e) => setEvaluationComponent(e.target.value)}
              >
                {evaluationDrop.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Evaluation Indicator</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {evaluationMappingList[0]?.indicator.map((row) => (
                    <TableRow>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <Checkbox
                          onChange={() => handleCheckboxChange(row.id)}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAddNewIndicatorModal(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleAdd()}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
