import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../config/api-client";
import { ApiManageUser } from "../constants/ApiManageUser";
import { UseMutationOptions, UseQueryOptions } from "../types/react-query";
import {
  AddUserMappingPayload,
  DropDownFaculty,
  DropDownProgram,
  DropDownRoleList,
  DropDownStatus,
  EditUserMappingPayload,
  GetUserMappingById,
  ShowTableUserMappingPayload,
} from "../types/user-mapping";
import {
  NavbarResponse,
  RoleDelete,
  RoleEditResponse,
  RoleListAll,
  RoleListDropdownResponse,
  RoleListResponse,
  RoleSubmit,
} from "../types/user-role";

export const useQueryListRoleAdd = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<RoleListResponse[]>;
}) =>
  useQuery(
    ["useRole", query],
    () =>
      apiClient
        .post<unknown, { data: RoleListResponse[] }>(
          ApiManageUser.getListRole()
        )
        .then((res) => res.data),
    options
  );

export const useQueryListRoleEdit = ({
  query,
  options,
}: {
  query: {
    roleId: string;
  };
  options?: UseQueryOptions<RoleEditResponse>;
}) =>
  useQuery(
    ["useRoleEdit", query.roleId],
    () =>
      apiClient
        .get<unknown, { data: RoleEditResponse }>(
          ApiManageUser.getListRoleEdit(query.roleId)
        )
        .then((res) => res.data.data),
    options
  );

export const useQueryListNavbar = ({
  query,
  options,
}: {
  query: {
    roleId: string;
  };
  options?: UseQueryOptions<NavbarResponse[]>;
}) =>
  useQuery(
    ["useNavbarList", query.roleId],
    () =>
      apiClient
        .post<unknown, { data: NavbarResponse[] }>(
          ApiManageUser.getListNavbar(query.roleId)
        )
        .then((res) => res.data),
    options
  );

export const useQueryListRoleDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<RoleListDropdownResponse>;
}) =>
  useQuery(
    ["useRoleDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: RoleListDropdownResponse[] }>(
          ApiManageUser.getListRoleDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useMutationAddNewRole = (options?: UseMutationOptions) =>
  useMutation(
    (data: RoleSubmit) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.submitAddNewRoleApi}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationEditRole = (options?: UseMutationOptions) =>
  useMutation(
    (data: RoleSubmit) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.submmitEditRoleApi}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useQueryListRoleAll = ({
  query,
  options,
}: {
  query: {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    search: string;
  };
  options?: UseQueryOptions<RoleListAll>;
}) =>
  useQuery(
    ["roleListAll", query],
    () =>
      apiClient
        .get<unknown, { data: RoleListAll[] }>(
          ApiManageUser.getListRoleAll(
            query.pageSize,
            query.pageNumber,
            query.orderBy,
            query.search
          )
        )
        .then((res) => res.data),
    options
  );

export const useMutationDeleteRole = (options?: UseMutationOptions) =>
  useMutation(
    (data: RoleDelete) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.submmitDeleteRole}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationDeleteUserMapping = (options?: UseMutationOptions) =>
  useMutation(
    (data: RoleDelete) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.submmitDeleteUserMapping}`,
          data
        )
        .then((res) => res.data),
    options
  );
export const useQueryListRoleUserMappingDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropDownRoleList>;
}) =>
  useQuery(
    ["useListRoleDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: DropDownRoleList[] }>(
          ApiManageUser.getListRoleUserMappingDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryFacultyDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropDownFaculty>;
}) =>
  useQuery(
    ["useFacultyDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: DropDownFaculty[] }>(
          ApiManageUser.getFacultyDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryStatusDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropDownStatus>;
}) =>
  useQuery(
    ["useStatusDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: DropDownStatus[] }>(
          ApiManageUser.getStatusDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryProgramDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropDownProgram>;
}) =>
  useQuery(
    ["useProgramDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: DropDownProgram[] }>(
          ApiManageUser.getProgramDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetUserMappingById = ({
  query,
  options,
}: {
  query: {
    id: string;
  };
  options?: UseQueryOptions<GetUserMappingById>;
}) =>
  useQuery(
    ["useGetUserMappingById", query],
    () =>
      apiClient
        .get<unknown, { data: GetUserMappingById }>(
          ApiManageUser.getUserMappingById(query.id)
        )
        .then((res) => res.data),
    options
  );

export const useMutationShowTableUserMapping = ({
  query,
  options,
}: {
  query: {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    search: string;
  };
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: ShowTableUserMappingPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.showTableUserMapping(query.pageSize, query.pageNumber, query.orderBy, query.search)}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationAddUserMapping = (options?: UseMutationOptions) =>
  useMutation(
    (data: AddUserMappingPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.addUserMapping}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationEditUserMapping = ({
  query,
  options,
}: {
  query: {
    id: string;
  };
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: EditUserMappingPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.editUserMapping(query.id)}`,
          data
        )
        .then((res) => res.data),
    options
  );
