/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import { useMutation } from "@tanstack/react-query";
import apiClient from "../../config/api-client";
import { ApiSendApprovalKey } from "../../constants/setup-enrichment-program/ApiSendApporvalKey";
import { UseMutationOptions } from "../../types/react-query";
import { ShowTableSendApprovalKeyPayload } from "../../types/setup-enrichment-program/send-approval-key";

export const useMutationShowTableApprovalKey = (options?: UseMutationOptions) =>
  useMutation(
    (data: ShowTableSendApprovalKeyPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiSendApprovalKey.showTableApprovalKey}`,
          data
        )
        .then((res) => res.data),
    options
  );
