/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import Logout from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Person from "@mui/icons-material/Person";
import Settings from "@mui/icons-material/Settings";
import {
  Backdrop,
  Badge,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAuthUser } from "../../../store/auth/selector";
import { logout } from "../../../store/auth/slice";
import { selectProfile } from "../../../store/profile/selector";
import { layoutPrivateStyle } from "../../../styles/layout/private-routes";
import { MobileMenuProps } from "../../../types/mobile-menu";
// import { ProfileMenuProps } from '../../../types/modul-login';
// import { callMsGraph } from 'utils/graph';

import { Notification } from "../notification";
import { ModulProfileMenu } from "./modul-profile-menu";

type GraphProps = {
  displayName: string;
  userPrincipalName: string;
};

export function Header({ mobileMenu, setMobileMenu }: MobileMenuProps) {
  const navigate = useNavigate();
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [profileMenu, setProfileMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  // const { instance, accounts } = useMsal();
  // const [graphData, setGraphData] = useState<GraphProps>();
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   instance
  //     .acquireTokenSilent({
  //       ...loginRequest,
  //       account: accounts[0],
  //     })
  //     .then((response) => {
  //       callMsGraph(response.accessToken).then((res) => setGraphData(res));
  //     });
  // }, []);

  // const handleLogout = () => {
  //   instance.logout();
  //   dispatch(logout());
  // };

  return (
    <Stack component="header" sx={layoutPrivateStyle.header}>
      <Stack sx={layoutPrivateStyle.headerContainer}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ gap: { xs: "10px", sm: "16px" } }}
        >
          <Box
            component="img"
            src="/assets/icon/icn-semesta-flexible-program.svg"
            onClick={() => setMobileMenu?.(!mobileMenu)}
            sx={layoutPrivateStyle.headerIcon}
          />
          {/* <Box
              component="img"
              src="/assets/logo/logo-binusmaya.svg"
              sx={layoutPrivateStyle.headerImg}
            /> */}
          <Typography sx={layoutPrivateStyle.headerTypography}>            
            Flexible Program
          </Typography>
          {/* <Box sx={layoutPrivateStyle.headerDivider} />
          <Link href="/" underline="none" textTransform="none">
            <Typography sx={layoutPrivateStyle.headerTypography}>
              My Dashboard
            </Typography>
          </Link> */}
        </Box>

        <Stack direction="row" alignItems="center" gap="16px">
          {/* <Stack
            sx={{ position: "relative", cursor: "pointer" }}
            onClick={() => setShowNotification(!showNotification)}
          >
            <IconButton
              sx={{
                width: "32px",
                height: "32px",
                background: showNotification ? "#E6E6E6" : "transparent",
                ":hover": {
                  background: "#E6E6E6",
                },
              }}
            >
              <NotificationsIcon sx={{ color: "#999999" }} />
            </IconButton>
            <Box
              sx={{
                position: "absolute",
                background: "#D73930",
                display: "flex",
                alignItems: "center",
                padding: "1px 4px",
                borderRadius: "14px",
                right: 0,
              }}
            >
              <Typography color="white" fontSize="10px" fontWeight="500">
                99+
              </Typography>
            </Box>
          </Stack> */}

          <Avatar
            src={user?.userPictureUrl}
            sx={layoutPrivateStyle.headerAvatar}
            onClick={() => setProfileMenu?.(!profileMenu)}
          />
        </Stack>
        {/* <Notification
          showNotification={showNotification}
          setShowNotification={setShowNotification}
        /> */}
        <Backdrop
          sx={{
            background: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={profileMenu}
          onClick={() => setProfileMenu?.(!profileMenu)}
        >
          <Box
            sx={{
              width: { xs: "100%", xl: "1750px" },
              position: "relative",
            }}
          >
            <ModulProfileMenu
              profileMenu={profileMenu}
              setProfileMenu={setProfileMenu}
            />
          </Box>
        </Backdrop>
      </Stack>
    </Stack>
  );
}
