/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../../config/api-client";
import { UseMutationOptions, UseQueryOptions } from "../../types/react-query";
import { ShowTableSendApprovalKeyPayload } from "../../types/setup-enrichment-program/send-approval-key";
import { ApiTransferBcs } from "../../constants/setup-enrichment-program/ApiTransferBcs";
import { ShowTableTransferGradeToBcsPayload } from "../../types/setup-enrichment-program/transfer-bcs";

export const useMutationTransferBcsList = (options?: UseMutationOptions) =>
  useMutation(
    (data: ShowTableTransferGradeToBcsPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiTransferBcs.showTableTransferToBcs}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationTransferBcs = (options?: UseMutationOptions) =>
  useMutation(
    (data: { id: [] }) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiTransferBcs.transferBcs}`, data)
        .then((res) => res.data),
    options
  );

export const useMutationDownloadExcelTransferGrade = (
  options?: UseMutationOptions
) =>
  useMutation(
    (data: { id: [] }) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiTransferBcs.exportTransferToBcs}`,
          data,
          {
            headers: { "Content-Type": "application/json" },
            responseType: "arraybuffer",
          }
        )
        .then((res) => res.data),
    options
  );

export const useQueryExportExcelTransferToBcs = ({
  query,
  options,
}: {
  query: { period: string; acadcareer: string; track: string };
  options?: UseQueryOptions<any>;
}) =>
  useQuery(
    ["exportExcelTransferTobBcs", query],
    () =>
      apiClient
        .get<any>(
          ApiTransferBcs.exportTransferToBcs(
            query.period,
            query.acadcareer,
            query.track
          ),
          {
            responseType: "blob",
          }
        )
        .then((res) => res.data),
    options
  );
