import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup'
import { breadCrumbLink } from "../../../../../styles/common/bread-crumb-link";
import { secondTableData, assesmentData } from '../../data';
import { AssesmentDetail } from "../../../../../components/dialog/assesment-evaluation/assesment/AssesmentDetail";
import { Order } from "../../../../../components/table/types";
import EnhancedTableHeadCheckbox from "../../../../../components/table/enhance-table-head-checbox";
import { getComparator, stableSort } from "../../../../../components/table/utils";
import { AssesmentData, AssesmentHeadCells } from "../../../../../types/assesment-evaluation/assesment/assesment-type";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  // pagesWorker: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

export function AssesmentEntry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postProjectSchema = yup.object().shape({
    institution: yup.string(),
    acadCarrer: yup.string(),
    term: yup.string(),
    track: yup.string(),
  });

  const {
    setValue,
    handleSubmit,
    trigger,
    getValues,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(postProjectSchema) });

  const [userType, setUserType] = useState<any>('');
  const [transaction, setTransaction] = useState<any>('');
  const [pagesWorker, setPagesWorker] = useState<any>(0);
  const [rows, setRows] = useState<any[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof AssesmentData>('id');
  const [selected, setSelected] = useState<string[]>([]);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [pages, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [searchInput, setSearchInput] = useState<any>('');
  const [pagesTab, setPagesTab] = useState(0);
  const profileUser = assesmentData; // Use the dummy data
  // useEffect(() => {
  //   axios
  //     .get(`https://func-bion-flexibleprogram-be-dev.azurewebsites.net/api/GetStudent`, {
  //       headers: {
  //         'content-type': 'application/json',
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //       params: {
  //         code: '3j-Xk9PnipOdHwt5rEq8W2K5p1_stqLJie70D9pX_y_HAzFuTmmRYA%3D%3D',
  //         pageSize: pages + 1,
  //         pageNumber: rowsPerPage,
  //         orderBy: 'id:asc',
  //         search: searchInput,
  //       },
  //     })
  //     .then(
  //       (result) => {
  //         setTotalRows(result.data?.info.totalRecord);
  //         setRows(result.data?.returnValue);
  //         setLoading(false);
  //       },
  //       (error) => {
  //         if (
  //           error.response.data.ResultCode === 403 &&
  //           error.response.data.ErrorMessage === 'Invalid token'
  //         ) {
  //           setLoading(true);
  //         }
  //         setLoading(true);
  //       }
  //     );
  // }, [pages, rowsPerPage, searchInput]);

  useEffect(() => {
    // Simulated API call or data fetching
    // Replace this with your actual data fetching logic
    setLoading(true);
    setTimeout(() => {
      // Assuming fetching data is completed
      setRows(secondTableData); // Update rows with fetched data
      setTotalRows(secondTableData.length); // Update totalRows with fetched data length
      setLoading(false);
    }, 1000); // Simulated loading delay
  }, []);

  // Function to handle table sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id.toString());
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    switch (selectedIndex) {
      case -1:
        newSelected = [...selected, name];
        break;
      case 0:
        newSelected = [...selected.slice(1)];
        break;
      case selected.length - 1:
        newSelected = [...selected.slice(0, -1)];
        break;
      default:
        if (selectedIndex > 0) {
          newSelected = [
            ...selected.slice(0, selectedIndex),
            ...selected.slice(selectedIndex + 1),
          ];
        }
    }

    setSelected(newSelected);
  };

  // Function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPagesTab(newValue);
  };


  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleDetailClick = (id: string) => () => {
    navigate(`detail/${id}`);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScoreChange = (e, index, compIndex, indIndex) => {
    const { value } = e.target;

    profileUser.assesmentDetail[index].component[compIndex].indicator[indIndex].score = value;

  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - pages * rowsPerPage);

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Assesment & Evaluation
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Assesment
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Entry Assesment
      </Typography>

      <Paper
        sx={{
          p: '24px 16px',
          mt: 3,
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
          borderRadius: 1,
          overflow: 'auto'
        }}
      >
        <Grid container sx={{ alignItems: 'center', mb: 2 }} spacing={1}>
          <Grid item>
            <Typography
              variant="h5"
              component="div"
              fontWeight={700}
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              View and entry assesment form
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <img
              src={`data:image/jpeg;charset=utf-8;base64,${profileUser?.photo}`}
              alt="user profile"
              style={{ borderRadius: 6 }}
              width={125}
              height={125}
            />
          </Grid>
          <Grid item md={10}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Institution
                </Typography>
                <Typography variant='body2'>
                  {profileUser?.institution !== "null" ? profileUser?.institution : "-"}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Track
                </Typography>
                <Typography variant='body2'>
                  {profileUser?.track !== null ? profileUser?.track : "-"}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Academic Career
                </Typography>
                <Typography variant='body2'>
                  {profileUser?.academicCarrer !== "null" ? profileUser?.academicCarrer : "-"}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Term
                </Typography>
                <Typography variant='body2'>
                  {profileUser?.term !== null ? profileUser?.term : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant='body1' color='#5E5873' fontWeight={700} sx={{ marginTop: 2 }}>
          {profileUser.fullName}
        </Typography>
        <Typography variant='subtitle2' color='#5E5873'>
          {profileUser?.nim !== null ? profileUser?.nim : "-"}
        </Typography>
      </Paper>

      <Paper
        sx={{
          p: '24px 16px',
          mt: 3,
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
          borderRadius: 1,
          overflow: 'auto',
        }}
      >
        <Tabs
          value={pagesTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {profileUser.assesmentDetail.map((detail, index) => (
            <Tab
              key={detail.detail}
              label={<Typography sx={{ textTransform: 'none', fontWeight: 500 }} variant='body2'>{detail.title}</Typography>}
            />
          ))}
        </Tabs>

        {profileUser.assesmentDetail.map((detail, index) => (
          <TabPanel key={detail.detail} value={pagesTab} index={index}>
            {detail.component.map((comp, compIndex) => (
              <Box key={comp.comp} sx={{ mt: 2 }}>
                <Typography variant='h6' sx={{ fontWeight: 600 }}>
                  {comp.title}
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Indicator</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {comp.indicator.map((ind, indIndex) => (
                        <TableRow key={ind.no}>
                          <TableCell>{ind.no}</TableCell>
                          <TableCell>{ind.descr}</TableCell>
                          <TableCell>{ind.weight}</TableCell>
                          <TableCell>
                            <TextField
                              type="text"
                              value={ind.score}
                              onChange={(e) => handleScoreChange(e, detail, comp, indIndex)}
                              InputProps={{
                                style: { textAlign: 'center' },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Score: {' '}
                            <span style={{ fontWeight: 'bold' }}>1. Poor</span> {' '}
                            <span style={{ fontWeight: 'bold' }}>2. Fair</span> {' '}
                            <span style={{ fontWeight: 'bold' }}>3. Good</span> {' '}
                            <span style={{ fontWeight: 'bold' }}>4. Excellent</span>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </TabPanel>
        ))}

        <Box columnGap={2} display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
          <Typography
            fontWeight={600}
            color="#000"
            sx={{ textTransform: 'none' }}
            variant='body2'
            width={50}
            marginTop={1}
            textAlign='center'
          >
            GRADE
          </Typography>
          <TextField
            type="text"
            value="A"
            variant="outlined"
            InputProps={{
              readOnly: true,
              style: { width: '50px', textAlign: 'center', fontWeight: 'bold' },
            }}
          />
        </Box>
        <Box columnGap={2} display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>

          <Button variant="contained" color="secondary" sx={{ ml: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" sx={{ ml: 2 }}>
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};