import { string } from "yup";
import { ApiService, azureFunctionCode } from "./ApiService";

export const ApiStudentRegistration = {
  getStudentTrackApi: `${ApiService.apiPostman}/GetStudentTrack?code=${azureFunctionCode}`,
  getStudentBulk: (search?: string, pageSize?: number, pageNumber?: number, orderBy?: string) => `${ApiService.apiPostman}/GetStudentBulk?code=${azureFunctionCode}&search=${search}&pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}`,
  submitUploadRegistration: `${ApiService.apiPostman}/ImportStudentTrackRegistration?code=${azureFunctionCode}`,
  submitSetMappingBulk: `${ApiService.apiPostman}/AddStudentPeriod?code=${azureFunctionCode}`,
  downloadTemplate: `${ApiService.apiPostman}/ExportStudentTrackRegistration?code=${azureFunctionCode}`,
  downloadReport: `${ApiService.apiPostman}/ExportStudentTrackRegistration?code=${azureFunctionCode}`,
  getStudentIdApi: (id?: string, partitionKey?: string) =>
    `${ApiService.apiPostman}/GetStudentById?code=${azureFunctionCode}&id=${id}&partitionKey=${partitionKey}`,
  getTermDropDown: () =>
    `${ApiService.apiPostman}/GetTerm?code=${azureFunctionCode}`,
  getIndividual: (studentId?: string) =>
    `${ApiService.apiPostman}/GetStudentRegistration?code=${azureFunctionCode}&studentId=${studentId}`,
  getTrackDropDown: () =>
    `${ApiService.apiPostman}/GetTrack?code=${azureFunctionCode}`,
  getAssignPartner: () =>
    `${ApiService.apiPostman}/DropDownReAssignPartner?code=${azureFunctionCode}`,
  getCheckForDropDown: () =>
    `${ApiService.apiPostman}/DropDownCheckFor?code=${azureFunctionCode}`,
  getAcadCareerDropDown: () =>
    `${ApiService.apiPostman}/GetAcadCareer?code=${azureFunctionCode}`,
  getStudentPeriod: (pageSize?: number, pageNumber?: number) =>
    `${ApiService.apiPostman}/GetStudentPeriod?pageSize=${pageSize}&pageNumber=${pageNumber}&code=${azureFunctionCode}`,
  submitStudentTrackReg: (
    institution?: string,
    status?: string,
    acadCareer?: string,
    acadOrg?: string,
    acadProg?: string,
    acadPlan?: string,
    studentId?: string,
    period?: string,
    admitTerm?: string
  ) =>
    `${ApiService.apiPostman}/StudentTrackRegistration?code=${azureFunctionCode}&institution=${institution}&status=${status}&acadCareer=${acadCareer}&acadOrg=${acadOrg}&acadProg=${acadProg}&acadPlan=${acadPlan}&studentId=${studentId}&period=${period}&admitTerm=${admitTerm}`,

  submitChangeStudent: (
    institution?: string,
    campus?: string,
    acadCareer?: string,
    acadGroup?: string,
    acadProg?: string,
    acadPlan?: string,
    studentId?: string,
    period?: string,
    admitTerm?: string
  ) =>
    `${ApiService.apiPostman}/ChangeStudentTrack?code=${azureFunctionCode}&institution=${institution}&campus=${campus}&acadCareer=${acadCareer}&acadGroup=${acadGroup}&acadProg=${acadProg}&acadPlan=${acadPlan}&studentId=${studentId}&period=${period}&admitTerm=${admitTerm}`,

  submitDropStudent: (studentId?: string) =>
    `${ApiService.apiPostman}/DropStudentTrack?code=${azureFunctionCode}&studentId=${studentId}`,
  getDetailStudentPeriod: (id?: string) =>
    `${ApiService.apiPostman}/DetailStudentPeriod?code=${azureFunctionCode}&id=${id}`,
  
  // Student Period Mapping
  resetMapping: `${ApiService.apiPostman}/ResetStudentPeriod?code=${azureFunctionCode}`,

  // Student Track Registration
  getDetailStudentEnrichmentTrack: (id?: string) => `${ApiService.apiPostman}/DetailStudentTrack?code=${azureFunctionCode}&id=${id}`,

  // Change and Drop Student Track
  getStudentTrackChangeDrop: (studentId?: string) => `${ApiService.apiPostman}/GetStudentTrackChange?code=${azureFunctionCode}&studentId=${studentId}`,
};
