/* eslint-disable no-param-reassign */
/* eslint-disable spaced-comment */

import { createSlice } from '@reduxjs/toolkit';
import { fetchEvaluationData, fetchEvaluationDataById } from '../action/evaluation.action';

interface EvaluationState {
  loading: boolean;
  data: any[];
  totalRows: number;
  error: string | null;
}

const initialState: EvaluationState = {
  loading: false,
  data: [],
  totalRows: 0,
  error: null,
};

const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //Evaluation Data
      .addCase(fetchEvaluationData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvaluationData.fulfilled, (state, action) => {        
        state.loading = false;
        state.data = action.payload.data;
        state.totalRows = action.payload.paginationInfo.itemsPerPage;
        state.error = null;
      })
      .addCase(fetchEvaluationData.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.totalRows = 0;
        state.error = action.payload as string;
      })
      //Evaluation Entry Data
      .addCase(fetchEvaluationDataById.pending, (state) => {
        state.loading = true;
        state.totalRows = 0;
        state.error = null;
      })
      .addCase(fetchEvaluationDataById.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = 0;
        state.loading = false;
      })
      .addCase(fetchEvaluationDataById.rejected, (state, action) => {
        state.loading = false;
        state.totalRows = 0;
        state.error = action.payload as string;
      });
  },
});

export default evaluationSlice.reducer;
