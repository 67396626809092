import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { FC, memo, useState } from "react";

interface DialogProps {
    myId: string;
    onClose: () => void; // Callback function to close the form  
}

const detailDummyData = {
    institution: "Binus University",
    academicCarrer: "Undergraduate",
    term: "2024, Even Semester",
    track: "Internship",
    studentName: "Joseft",
    course: [
        { course: "12345 - Calculus", gradeEntry: "B", finalGrade: "A" },
        { course: "12345 - Calculus 2", gradeEntry: "C", finalGrade: "A" }
    ]
};

const DialogEvaluationForm: FC<DialogProps> = function DialogEvaluationForm(props) {
    const { myId, onClose } = props;
    const [approvalKey, setApprovalKey] = useState<any>("")

    const handleSubmit = () => {
        // Handle form submission logic here
        onClose(); // Close the form dialog
    };

    return (
        <Dialog
            open
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            // maxWidth="md"
            // fullWidth
        >
            <DialogTitle id="alert-dialog-title">Approve Evaluation Form</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container columnSpacing={1} sx={{ mb: -1 }}>

                    <Grid item md={12}>
                        <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                            Note
                        </Typography>
                        <FormControl sx={{ mb: 2 }} size="small" fullWidth>
                            <TextField
                                type="text"
                                size="small"
                                multiline
                                disabled
                                placeholder="Institution"
                                value="Please keep in mind that the score cannot be changed once it had been approved"
                                fullWidth
                            />
                        </FormControl>
                    </Grid>


                    <Grid item md={12}>
                        <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                            Approval Key
                        </Typography>
                        <FormControl sx={{ mb: 2 }} size="small" fullWidth>
                            <TextField
                                type="text"
                                size="small"                                
                                placeholder="Approval Key"
                                value={approvalKey}
                                onChange={(e) => setApprovalKey(e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="secondary" sx={{ marginRight: 2 }}>
                        Back
                    </Button>
                    <Button onClick={onClose} variant="contained" color="primary">
                        Submit
                    </Button>
                </Box>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                        Forgot Your Approval Key ?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ width: '100%' }} style={{ backgroundColor: '#2986cc', color: 'white' }} variant="contained">
                    Request Approval Key
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const EvaluationForm = memo(DialogEvaluationForm);
