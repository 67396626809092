import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  MenuItem,
  OutlinedInput,
  Stack,
  TextareaAutosize,
  Typography,
  InputLabel,
  TextField
} from "@mui/material";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import {
  useMutationSubmitDropStudent,
  useQueryGetStudentTrackChangeDrop,
  useQueryGetTermDropDown,
} from "../../api/student-registration";
import useModal from "../../hooks/use-modal";
import {
  TermDataList,
  StudentTrackChangeDropData,
} from "../../types/student-registration";
import { Link } from "react-router-dom";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DropdownResponseData } from "../../types/dropdown";
import {
  useQueryAcadGroup,
  useQueryAcadProg,
  useQueryDropDownAdmitTerm,
  useQueryListAcadCareerDropDown,
  useQueryListCampusLocationDropDown,
  useQueryListInstitutionDropDown,
} from "../../api/dropdown";

export function DropStudentTrack() {
  const { showModal } = useModal();
  const [nim, setNim] = useState("");
  const [termSelect, setTermSelect] = useState("");
  const [dataStudentTrack, setDataStudentTrack] = useState<StudentTrackChangeDropData>({} as StudentTrackChangeDropData);
  const [showBox, setShowBox] = useState(false);

  // Dropdown Data
  const [listTerm, setListTerm] = useState<TermDataList[]>([]);
  const [dropInstitution, setDropInstitution] = useState<
    DropdownResponseData[]
  >([]);
  const [dropCampus, setDropCampus] = useState<DropdownResponseData[]>([]);
  const [dropAcadCareer, setDropAcadCareer] = useState<DropdownResponseData[]>(
    []
  );
  const [dropAcadGroup, setDropAcadGroup] = useState<DropdownResponseData[]>(
    []
  );
  const [dropAcadProg, setDropAcadProg] = useState<DropdownResponseData[]>([]);
  const [dropAcadPlan, setDropAcadPlan] = useState<DropdownResponseData[]>([]);
  const [dropAdmitTerm, setDropAdmitTerm] = useState<DropdownResponseData[]>(
    []
  );

  // Value Drop Down
  const [termValue, setTermValue] = useState("");
  const [institutionValue, setInstitutionValue] = useState("");
  const [campusValue, setCampusValue] = useState("");
  const [acadCareerValue, setAcadCareerValue] = useState("");
  const [acadGroupValue, setAcadGroupValue] = useState("");
  const [acadProgValue, setAcadProgValue] = useState("");
  const [acadPlanValue, setAcadPlanValue] = useState("");
  const [admitTermValue, setAdmitTermValue] = useState("");
  const [reason, setReason] = useState("");

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setDropInstitution(res.data || []);
        setInstitutionValue(res.data[0].Code);
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setDropAcadCareer(res.data || []);
        setAcadCareerValue("OS1");
      },
      onError: () => {},
    },
  });

  const getStudentTrack = useQueryGetStudentTrackChangeDrop({
    query: {
      studentId: nim,
    },
    options: {
      enabled: false,
      retry: false,
      onSuccess: (res) => {
        setDataStudentTrack(res.data);
        setShowBox(true);
      },
      onError: (err) => {
        showModal({
          title: "Failed",
          message:
            (err.response?.data as { message?: string })?.message ??
            "An error occurred",
          options: {
            buttonTitle: "Ok",
            variant: "failed",
          },
        });
      },
    },
  });

  const handleSubmitIndividual = () => {
    getStudentTrack.refetch();
  };

  const submitTrackRegIndividual = useMutationSubmitDropStudent({
    query: {
      studentId: nim,
    },
    options: {
      onSuccess: async (res: any) => {
        setShowBox(false);
        showModal({
          title: "Success",
          message: `Drop student track success!`,
          options: {
            buttonTitle: "Ok",
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        const checkError = err?.response?.data.message;

        const errorMessage = checkError === "Error" 
        ? err?.response?.data.details 
        : err?.response?.data.message;

        showModal({
          title: "Failed",
          message: errorMessage || "Drop student track is failed.",
          options: {
            buttonTitle: "Ok",
            variant: "failed",
          },
        });
      },
    },
  });

  const handleConfirmationDropData = () => {
    showModal({
      title: "Alert",
      message: `Are you sure you want to drop this data?`,
      options: {
        buttonTitle: "Ok",
        buttonTitle2: "Cancel",
        variant: "info",
        onOk() {
          submitIndividualStudentTrack();
        },
      },
    });
  };

  const submitIndividualStudentTrack = async () => {
    submitTrackRegIndividual.mutate({
      reason,
    });
  };

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Student Registration
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Drop Student Track
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Drop Student Track
      </Typography>

      <Divider />

      <Box mt={4} mb={2}>
        <Grid container spacing={2}>
          <Grid mb={2} xs={6} item>
            <FormControl fullWidth size="small">
              <InputLabel id="institution-select-label">Institution</InputLabel>
              <Select
                disabled
                labelId="institution-select-label"
                id="institution-select"
                label="Institution"
                value={institutionValue}
                onChange={(e) => {
                  setInstitutionValue(e.target.value as string);
                }}
              >
                {dropInstitution.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid mb={2} xs={6} item>
            <FormControl fullWidth size="small">
              <InputLabel id="academicCareer-select-label">Academic Career</InputLabel>
              <Select
                disabled
                labelId="academicCareer-select-label"
                id="academicCareer-select"
                label="academicCareer"
                value={acadCareerValue}
                onChange={(e) => {
                  setAcadCareerValue(e.target.value as string);
                }}
              >
                {dropAcadCareer.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small" 
              id="studentId"
              label={
                <>
                  Student ID<Typography component="span" color="error">*</Typography>
                </>
              } 
              variant="outlined"
              value={nim}
              onChange={(e) => setNim(e.target.value)}
            />
          </Grid>

          <Grid display="flex" justifyContent="end" xs={12} item>
            <Box component="div">
              <Button onClick={handleSubmitIndividual} variant="contained">
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {showBox && <Divider />}

      <Box display={!showBox ? "none" : ""} mt={2} mb={2} component="div">
        <Typography variant="h6" mb={2}>
          Showing Form for Term : {dataStudentTrack.termDesc}
        </Typography>

        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="studentId"
              label="Student ID"
              variant="outlined"
              value={dataStudentTrack.externalSystemId || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="studentName"
              label="Student Name"
              variant="outlined"
              value={dataStudentTrack.name || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="faculty/school"
              label="Faculty / School"
              variant="outlined"
              value={dataStudentTrack.campusDesc || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="academicProgram"
              label="Academic Program"
              variant="outlined"
              value={dataStudentTrack.acadOrgDesc || "-"}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="track"
              label="Track"
              variant="outlined"
              value={(`${dataStudentTrack.track} - ${dataStudentTrack.trackDesc}`) || "-"}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="reason"
              label={
                <>Reason <span style={{ color: "red" }}>*</span></>
              }
              variant="outlined"
              multiline
              rows={4}
              placeholder="Type here"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography 
              component="span"
              color="error" 
              sx={{ fontWeight: "bold", fontStyle: "italic" }}>
                *Track dropped in Flexible Program is not directly updated to BCS, please check and drop the student&apos;s track in BCS manually
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end">
            <Button variant="contained" onClick={handleConfirmationDropData}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
