import {
  Container,
  Stack,
  Breadcrumbs,
  Typography,
  Divider,
  Box,
  Grid,
  Skeleton,
  TextField,
  Avatar,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useState } from "react";
import { Link } from "react-router-dom";

import { useQueryDashboardGet } from "../api/dashboard";
import { DashboardResponse } from "../types/dashboard";

export function Dashboard() {
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  const [dataDashboard, setDataDashboard] = useState<DashboardResponse>();

  const getDashboard = useQueryDashboardGet({
    query: {
      binusianId: userData.binusianId,
    },
    options: {
      enabled: true,
      onSuccess: (res) => {
        setDataDashboard(res);
      },
      onError: () => {},
    },
  });

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Typography fontSize={14} color="#0097DA">
            Dashboard
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Dashboard
      </Typography>

      <Divider />

      {dataDashboard ? (
        <Box display="flex">
          <Box
            mt={1}
            mr={1}
            component="img"
            src="/assets/icon/yellow-info-icon.svg"
          />

          <Typography fontWeight="bold" fontSize={14} gutterBottom mt={2}>
            Enrichment Registration period is open from {dataDashboard?.startDate}{" "}
            until {dataDashboard?.endDate}
          </Typography>
        </Box>
      ) : (
        <Skeleton variant="text" width={700} sx={{ fontSize: '14px' }} />
      )}
      
      <Typography variant="h6" fontSize={12} gutterBottom mt={2}>
        Running Period
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          {dataDashboard ? (
            <TextField
              disabled
              fullWidth
              size="small" 
              id="runningPeriod"
              variant="outlined"
              value={dataDashboard?.runningPeriodDesc || "-"}
            />
          ) : (
            <Skeleton variant="rounded" height={40} />
          )}
          
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid container item xs={6}>
          <Grid item xs={2}>
            {dataDashboard ? (
              <Avatar
                sx={{
                  width: "120px",
                  height: "120px"
                }} 
              />
            ) : (
              <Skeleton variant="circular" width={120} height={120} />
            )} 
          </Grid>
          <Grid item xs>
            <Box ml={2}>
              {dataDashboard ? (
                <Typography
                  variant="h6"
                  fontSize={18}
                  color="#0097da"
                  fontWeight="bold"
                >
                  {userData.name}
                </Typography>
              ) : (
                <Skeleton variant="text" width="100%" sx={{ fontSize: '14px' }} />
              )}

              {dataDashboard ? (
                <Typography variant="h6" fontSize={14} fontWeight="bold">
                  ENRICHMENT TRACK
                </Typography>
              ) : (
                <Skeleton variant="text" width="100%" sx={{ fontSize: '14px' }} />
              )}

              {dataDashboard ? (
                <Typography variant="h6" fontSize={14}>
                  {dataDashboard?.trackDesc}
                </Typography>
              ) : (
                <Skeleton variant="text" width="100%" sx={{ fontSize: '14px' }} />
              )}

              {dataDashboard ? (
                <Box mt={1}>
                  <Link to="/enrichment/update-profile">
                    <Button
                      sx={{ marginRight: "10px" }}
                      color="warning"
                      variant="contained"
                    >
                      {dataDashboard?.updateProfile ? "View Profile" : "Update Profile"}
                    </Button>
                  </Link>

                  <Link to="/enrichment/choose-enrichment-track">
                    <Button 
                      variant="contained" 
                      color="primary"
                      disabled={!dataDashboard?.registration}
                    >
                      {dataDashboard?.registration ? "Register for Enrichment" : "Can't Choose Track"}
                    </Button>
                  </Link>
                </Box>
              ) : (
                <>
                  <Skeleton variant="text" width="100%" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" width="100%" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" width="100%" sx={{ fontSize: '14px' }} />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {dataDashboard?.updateProfile === false && (
        <Typography mt={2} color="#DD413C" fontSize={14}>
          *Update your profile before registering for enrichment
        </Typography>
      )}
    </Container>
  );
}
