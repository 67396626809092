import { yupResolver } from "@hookform/resolvers/yup";
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { visuallyHidden } from '@mui/utils';
import axios from "axios";
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup'
import { breadCrumbLink } from "../../../styles/common/bread-crumb-link";
import { EyeIcon, InfoCircleBold, PasswordBold } from "../../../assets/icon-apps";
import { firstTableData, secondTableData, firstTableColumns, secondTableColumns } from './data';
import { EvaluationData, EvaluationHeadCells } from "../../../types/assesment-evaluation/evaluation/evaluation-type";
import { Order } from "../../../components/table/types";
import EnhancedTableHeadCheckbox from "../../../components/table/enhance-table-head-checbox";
import { getComparator, stableSort } from "../../../components/table/utils";
import { EvaluationDetail } from "../../../components/dialog/assesment-evaluation/evaluation/EvaluationDetail";
import { fetchEvaluationData } from "../../../store/assesment-evaluation/action/evaluation.action";
import { AppDispatch, RootState } from "../../../store";
import { institution } from "../../../store/dropdown/institution/action/dropdown.action";
import { acadCarrer } from "../../../store/dropdown/acad-carrer/action/dropdown.action";
import { term } from "../../../store/dropdown/term/action/dropdown.action";
import { track } from "../../../store/dropdown/track/action/dropdown.action";

function formatDate(dateString: string) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    };
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
}

// Function to customize how options are displayed
const getOptionLabel = (option: any) => option?.Name ?? '';

// Function to limit the number of displayed options to 5
const limitOptions = (options: any[], { inputValue }: { inputValue: string }) =>
    options.filter((option, index) => index < 5);

export function Evaluation() {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const postProjectSchema = yup.object().shape({
        institution: yup.string(),
        acadCarrer: yup.string(),
        term: yup.string(),
        track: yup.string(),
    });

    const {
        setValue,
        handleSubmit,
        trigger,
        getValues,
        control,
        formState: { errors },
    } = useForm({ resolver: yupResolver(postProjectSchema) });

    const [userType, setUserType] = useState<any>('');
    const [rows, setRows] = useState<any[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof EvaluationData>('id');
    const [selected, setSelected] = useState<string[]>([]);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [pages, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(true);
    const [searchInput, setSearchInput] = useState<any>('');
    //     axios.post(
    //         'https://func-bion-flexibleprogram-be-dev.azurewebsites.net/api/GetEvaluationData?code=3j-Xk9PnipOdHwt5rEq8W2K5p1_stqLJie70D9pX_y_HAzFuTmmRYA%3D%3D',
    //         null,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             params: {
    //                 // code: '3j-Xk9PnipOdHwt5rEq8W2K5p1_stqLJie70D9pX_y_HAzFuTmmRYA%3D%3D', // Ensure this is the correct value
    //                 pageSize: rowsPerPage,
    //                 pageNumber: pages,
    //                 orderBy: 'id:asc',
    //                 search: searchInput,
    //             },
    //         }
    //     )
    //         .then(
    //             (result) => {
    //                 console.log(result)
    //                 setTotalRows(result.data?.paginationInfo?.itemsPerPage);
    //                 setRows(result.data?.data);
    //                 setLoading(false);
    //             },
    //             (error) => {
    //                 if (
    //                     error.response.data.ResultCode === 403 &&
    //                     error.response.data.ErrorMessage === 'Invalid token'
    //                 ) {
    //                     setLoading(true);
    //                 } else {
    //                     setLoading(false);
    //                 }
    //             }
    //         );
    // }, [pages, rowsPerPage, searchInput]);  

    const { loading, data, totalRows, error } = useSelector((state: RootState) => state.evaluation);

    // Payload Variable
    const [institutionPayload, setInstitutionPayload] = useState<any>('');
    const [academicCareerPayload, setAcademicCarrerPayload] = useState<any>('');
    const [termPayload, setTermPayload] = useState<any>('');
    const [trackPayload, setTrackPayload] = useState<any>('');

    // DropDown variable
    const institutionDropdown = useSelector((state: RootState) => state.dropdownInstitution);
    const acadCarrerDropdown = useSelector((state: RootState) => state.dropdownAcadCarrer);
    const termDropdown = useSelector((state: RootState) => state.dropdownTerm);
    const trackDropdown = useSelector((state: RootState) => state.dropdownTrack);

    useEffect(() => {
        dispatch(institution());
        dispatch(acadCarrer());
        dispatch(term());
        dispatch(track());
    }, [dispatch]);


    useEffect(() => {
        dispatch(fetchEvaluationData({
            rowsPerPage,
            pages,
            searchInput,
            institution: institutionPayload,
            academicCareer: academicCareerPayload,
            term: termPayload,
            track: trackPayload
        }));
    }, [dispatch, rowsPerPage, pages, searchInput, institutionPayload, academicCareerPayload, termPayload, trackPayload]);

    useEffect(() => {
        setRows(data);
    }, [data]);


    // Function to handle table sorting
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        switch (selectedIndex) {
            case -1:
                newSelected = [...selected, name];
                break;
            case 0:
                newSelected = [...selected.slice(1)];
                break;
            case selected.length - 1:
                newSelected = [...selected.slice(0, -1)];
                break;
            default:
                if (selectedIndex > 0) {
                    newSelected = [
                        ...selected.slice(0, selectedIndex),
                        ...selected.slice(selectedIndex + 1),
                    ];
                }
        }

        setSelected(newSelected);
    };

    // Function to handle pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.includes(name);

    const handleEntryClick = (id: string) => () => {
        navigate(`entry?id=${id}`);
    };

    const handleDetailClick = (id: string) => () => {
        navigate(`detail?${id}`);
    };


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - pages * rowsPerPage);

    return (
        <Container maxWidth={false}>
            <Stack mt={2} spacing={2}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
                        Assesment & Evaluation
                    </Link>
                    <Typography fontSize={14} color="#0097DA">
                        Evaluation
                    </Typography>
                </Breadcrumbs>
            </Stack>
            <Typography variant="h5" gutterBottom mt={2}>
                Evaluation
            </Typography>


            {/* DDL 1 */}
            <Grid container columnSpacing={1} sx={{ mb: -7 }}>
                <Grid item md={6}>
                    <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                        Institution :
                    </Typography>
                    <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
                        {/* <InputLabel id="user-type-label">Institution</InputLabel> */}
                        <Controller
                            name="institution"
                            control={control}
                            defaultValue={institutionDropdown.data?.find((option) => option.Code === '') ?? null}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={institutionDropdown.data ?? []}
                                    onChange={(event, newValue: any) => {
                                        field.onChange(newValue);
                                        setInstitutionPayload(newValue ? newValue.Code : "")
                                    }}
                                    // filterOptions={limitOptions}
                                    getOptionLabel={getOptionLabel}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Institution"
                                            placeholder="Select Institution"
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                        Academic Carrer:
                    </Typography>
                    <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
                        {/* <InputLabel id="academic-carrer-label">Academic Carrer</InputLabel> */}
                        <Controller
                            name="acadCarrer"
                            control={control}
                            defaultValue={acadCarrerDropdown.data?.find((option) => option.Id === "") ?? null}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={acadCarrerDropdown.data ?? []}
                                    // filterOptions={limitOptions}
                                    getOptionLabel={getOptionLabel}
                                    onChange={(event, newValue: any) => {
                                        field.onChange(newValue);
                                        setAcademicCarrerPayload(newValue ? newValue.Code : "")
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Academic Carrer"
                                            placeholder="Select Academic Carrer"
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            {/* DDL 2 */}
            <Grid container columnSpacing={1} sx={{ mb: -7 }}>
                <Grid item md={6}>
                    <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                        Term :
                    </Typography>
                    <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
                        {/* <InputLabel id="user-type-label">Term</InputLabel> */}
                        <Controller
                            name="term"
                            control={control}
                            defaultValue={termDropdown.data?.find((option) => option.Id === "") ?? null}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={termDropdown.data ?? []}
                                    // filterOptions={limitOptions}
                                    getOptionLabel={getOptionLabel}
                                    onChange={(event, newValue: any) => {
                                        field.onChange(newValue);
                                        setTermPayload(newValue ? newValue.Code : "")
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Term"
                                            placeholder="Select Term"
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                        Track :
                    </Typography>
                    <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
                        {/* <InputLabel id="track-label">Track</InputLabel> */}
                        <Controller
                            name="track"
                            control={control}
                            defaultValue={trackDropdown.data?.find((option) => option.Id === "") ?? null}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={trackDropdown.data ?? []}
                                    // filterOptions={limitOptions}
                                    getOptionLabel={getOptionLabel}
                                    onChange={(event, newValue: any) => {
                                        field.onChange(newValue);
                                        setTrackPayload(newValue ? newValue.Code : "")
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Track"
                                            placeholder="Select Track"
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            {/* Table */}
            <Box mt={3}>
                <Paper elevation={3}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHeadCheckbox
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                headCells={EvaluationHeadCells}
                            />
                            <TableBody>
                                {
                                    rows.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                No data available
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        stableSort(rows, getComparator(order, orderBy))
                                            .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id.toString());
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, row.id.toString())}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="center">{row.evaluationMonth ?? "-"}</TableCell>
                                                        <TableCell align="center">{row.name ?? "-"}</TableCell>
                                                        <TableCell align="center">{row.evaluationStatus}</TableCell>
                                                        <TableCell align="center">{row.dueDate ? formatDate(row.dueDate.toString()) : "-"}</TableCell>
                                                        <TableCell align="center" sx={{ display: "flex", gap: 2 }}>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: row.evaluationStatus !== 'Approved By Staff' ? '#2986cc' : 'secondary',
                                                                    color: '#fff'
                                                                }}
                                                                disabled={row.evaluationStatus === 'Approved By Staff'}
                                                                onClick={handleEntryClick(row.id.toString())}
                                                            >
                                                                Entry Evaluation
                                                            </Button>
                                                            {/* <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: 'primary',
                                        color: '#fff'
                                    }}
                                    onClick={handleDetailClick(row.id.toString())}
                                >
                                    Detail
                                </Button> */}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    )
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Paper>
            </Box>



            {/* Pagination */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={pages}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Box mt={2} display="flex" justifyContent="flex-end">
                {/* <Button variant="contained" color="primary" sx={{ marginRight: 2 }}>
                    Export Evaluation Report
                </Button> */}
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Approve
                </Button>

                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <DialogTitle>Evaluation Detail</DialogTitle>
                    <DialogContent>
                        {selected.length > 0 ? (
                            <EvaluationDetail myId={selected} onClose={handleClose} />
                        ) : (
                            <p>No Data Selected</p>
                        )}
                    </DialogContent>
                    {/* <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions> */}
                </Dialog>
            </Box>
        </Container>
    );
};