import { SxStyle } from '../../types/style';

const contentStack: SxStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    height: { xs: 'fitContent', md: '100vh' }
};

const contentStackBox: SxStyle = {
    width: '800px'
}

const contentStackBoxTypography: SxStyle = {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    fontSize: '32px',
    textAlign: 'center'
}

// UNAUTHORIZED PAGE

const contentStack2: SxStyle = {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
};

const contentStackBox2: SxStyle = {
    position: 'relative',
    display: 'inline-block'
};

const contentStackBox2Typography1: SxStyle = {
    fontWeight: '700',
    textAlign: 'center'
};

const contentStackBox2Typography2: SxStyle = {
    fontWeight: '500',
    textAlign: 'center'
};


export const waitingStyle = {
    contentStack,
    contentStackBox,
    contentStackBoxTypography,
    contentStack2,
    contentStackBox2,
    contentStackBox2Typography1,
    contentStackBox2Typography2

};