import { ApiService, azureFunctionCode } from "./ApiService";

export const ApiManageUser = {
  getListRole: () =>
    `${ApiService.apiPostman}/GetMenus?code=${azureFunctionCode}&pageSize=10&pageNumber=1&orderBy=id:asc&search=`,
  getListRoleEdit: (roleId?: string) =>
    `${ApiService.apiPostman}/menu/GetMenuByRoleId?code=${azureFunctionCode}&roleId=${roleId}`,
  getListNavbar: (roleId?: string) =>
    `${ApiService.apiPostman}/GetNavbar?code=${azureFunctionCode}&roleId=${roleId}`,
  getListRoleDropDown: () =>
    `${ApiService.apiPostman}/DropDownRole?code=${azureFunctionCode}`,
  getListCampusLocationDropDown: () =>
    `${ApiService.apiPostman}/DropDownCampusLocation?code=${azureFunctionCode}`,
  getListRoleUserMappingDropDown: () =>
    `${ApiService.apiPostman}/DropDownRole?code=${azureFunctionCode}`,
  getFacultyDropDown: () =>
    `${ApiService.apiPostman}/DropDownFaculty?code=${azureFunctionCode}`,
  getStatusDropDown: () =>
    `${ApiService.apiPostman}/DropDownStatus?code=${azureFunctionCode}`,
  getProgramDropDown: () =>
    `${ApiService.apiPostman}/DropDownProg?code=${azureFunctionCode}`,
  getUserMappingById: (id: string) =>
    `${ApiService.apiPostman}/GetUserMappingById?code=${azureFunctionCode}&id=${id}`,

  getListRoleAll: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    search: string
  ) =>
    `${ApiService.apiPostman}/GetRole?code=${azureFunctionCode}&pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&search=${search}`,

  submitAddNewRoleApi: `${ApiService.apiPostman}/AddAccessRole?code=${azureFunctionCode}`,
  submmitEditRoleApi: `${ApiService.apiPostman}/UpdateAccessRole?code=${azureFunctionCode}`,
  submmitDeleteRole: `${ApiService.apiPostman}/DeleteRole?code=${azureFunctionCode}`,
  submmitDeleteUserMapping: `${ApiService.apiPostman}/DeleteUserMapping?code=${azureFunctionCode}`,
  showTableUserMapping: (
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    search: string
  ) => `${ApiService.apiPostman}/GetUserMapping?code=${azureFunctionCode}&pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&search=${search}`,
  editUserMapping: (id: string) =>
    `${ApiService.apiPostman}/EditUserMapping?code=${azureFunctionCode}&id=${id}`,
  addUserMapping: `${ApiService.apiPostman}/AddUserMapping?code=${azureFunctionCode}`,
};
