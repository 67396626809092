/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unicorn/consistent-function-scoping */
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { waitingStyle } from "../../styles/auth/waiting";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import useModal from "../../hooks/use-modal";
import { parseDataAzureAD } from "../../utils/azure-ad";
import { useSignInByEmail } from "../../api/identity";
import { useQueryGetGraphData } from "../../api/graph";
import { GraphDataResponse } from "../../types/graph";
import { setAuth, setAuthAzureAD } from "../../store/auth/slice";
import { setAuthToken, setAuthTokenAzureAD } from "../../store/authToken/slice";
import { store } from "../../store";

export function Waiting() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const {
    // profile: { activeRole },
    authToken: { azureADToken },
  } = store.getState();

  const [graphData, setGraphData] = useState<GraphDataResponse | null>();
  const [accessToken, setAccessToken] = useState("");
  
  const getGraphData = useQueryGetGraphData({
    options: {
      enabled: false,
      onSuccess: (res) => {
        
        setGraphData(res);
      },
      onError: () => {
        setGraphData(null);
      },
    },
  });

  const signByEmail = useSignInByEmail({
    query: {
      email: graphData?.mail,
      token: azureADToken,
    },
    options: {
      enabled: !!graphData?.mail,
      onSuccess: (res) => {
        // dispatch(setAuthToken(res.data.tokenData.token)); // access token jwt binus
        dispatch(setAuth(res.data)); // user profile by binus
        
        res.data.roleId === "2" ? res.data.isPeriodMapping ? Navigate("/dashboard") : Navigate("/unauthorized") :
        res.data.roleId === "3" ? Navigate("/enrichment-approval") :  Navigate("/dashboard")
      },
      onError: () => {
        showModal({
          title: "Login Failed",
          message:
            "Your account is not verified, please contact administrator.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  useEffect(() => {
    if (window.location.hash) {
      const adState = parseDataAzureAD(window.location.hash);
      if (!adState._success) return;

      // console.log(adState);
      // console.log(adState.access_token);
      setAccessToken(adState.access_token as string);
      dispatch(setAuthTokenAzureAD(adState.access_token)); // access token jwt azure ad
      dispatch(setAuthAzureAD(jwtDecode(adState.access_token as string))); // user profile by azure ad token

      getGraphData.refetch();
    }
  }, []);

  return (
    // <Stack component="section" sx={waitingStyle.contentStack}>
    //   <Box sx={waitingStyle.contentStackBox}>
    //     <Typography color="primary" sx={waitingStyle.contentStackBoxTypography}>
    //       Please wait, you will be redirected to the dashboard page shortly.
    //     </Typography>
    //   </Box>
    // </Stack>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      minHeight="100vh"
    >
      <CircularProgress size={16} />
      <Box ml={1}>Loading...</Box>
    </Box>
  );
}
