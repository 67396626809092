import { ApiService, azureFunctionCode } from "../ApiService";

export const ApiAsessmentMapping = {
  showTableAssesmentMapping: `${ApiService.apiPostman}/AssessmentMapping/GetAssessmentMapping?code=${azureFunctionCode}`,
  updateIndicator: `${ApiService.apiPostman}/AssessmentMapping/UpdateIndicatorAssessmentMapping?code=${azureFunctionCode}`,
  deleteIndicator: `${ApiService.apiPostman}/AssessmentMapping/DropAssessmentMapping?code=${azureFunctionCode}`,
  submitAddNewIndicator: `${ApiService.apiPostman}/AssessmentMapping/AddAssessmentMapping?code=${azureFunctionCode}`,
  showTableSetDueDate: `${ApiService.apiPostman}/GetDueDate?code=${azureFunctionCode}`,
  showTableUpdateDueDate: `${ApiService.apiPostman}/GetUpdateDueDate?code=${azureFunctionCode}`,
  generateDueDate: `${ApiService.apiPostman}/GenerateDueDate?code=${azureFunctionCode}`,
  updateDueDateSave: `${ApiService.apiPostman}/GetUpdateDueDate?code=${azureFunctionCode}`,
  setDueDateSave: `${ApiService.apiPostman}/SetDueDate?code=${azureFunctionCode}`,

  getIndicatorById: (id: string) =>
    `${ApiService.apiPostman}/AssessmentMapping/GetIndicatorAssessmentMappingById?code=${azureFunctionCode}&id=${id}`,
};
