import {
  Container,
  Stack,
  Breadcrumbs,
  Typography,
  Divider,
  Grid,
  Box,
  OutlinedInput,
  Paper,
  Chip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { GridColDef, GridCellParams } from "@mui/x-data-grid";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { CustomDataGrid } from "../../../components/common/datagrid";
import { UserRoleCss } from "../../../styles/page/UserRoleCss";
import { breadCrumbLink } from "../../../styles/common/bread-crumb-link";

import { RoleListAllData } from "../../../types/user-role";
import { useQueryListRoleAll } from "../../../api/user-management";

export function UserRole() {
  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const [listRoleAll, setListRoleAll] = useState<RoleListAllData[]>([]);
  const [search, setSearch] = useState("");
  const [idList, setIdList] = useState([]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Role Name",
      flex: 1,
      minWidth: 200,
      hideable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 0.1,
      minWidth: 150,
      hideable: false,
      renderCell: (params) =>
        params.value !== true ? (
          <Chip color="error" component="div" label="Nonactive" />
        ) : (
          <Chip color="warning" component="div" label="Active" />
        ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      align: "center",
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Link to={`/user-management/edit-role?${params.id}`}>
            <IconButton aria-label="delete">
              <EditIcon />
            </IconButton>
          </Link>
        </Grid>
      ),
    },
  ];

  const getListAllRole = useQueryListRoleAll({
    query: {
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page + 1,
      orderBy: "id:asc",
      search,
    },
    options: {
      onSuccess: async (res) => {
        setListRoleAll(res.data || []);
        setTotalItems(res.paginationInfo.totalItems || 0);
      },
      onError: (err) => {
        setListRoleAll([]);
        setTotalItems(0);
      },
    },
  });

  useEffect(() => {
    getListAllRole.refetch();
  }, [paginationModel]); 

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            User Management
          </Link>
          <Typography fontSize={14} color="#0097DA">
            User Role
          </Typography>
        </Breadcrumbs>
      </Stack>

      <Typography variant="h5" gutterBottom mt={2}>
        User Role
      </Typography>

      <Divider />

      <Grid container mt={2}>
        <Grid item>
          <Box sx={UserRoleCss.searchBar}>
            <OutlinedInput
              onChange={(e) => setSearch(e.target.value)}
              sx={UserRoleCss.inputSearch}
              placeholder="Search"
            />
            <SearchIcon sx={UserRoleCss.iconSearch} />
          </Box>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Paper elevation={3}>
          <CustomDataGrid
            columnsData={columns}
            rowsData={listRoleAll}
            rowCount={totalItems}
            getRowId={(row) => row.id}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
            onRowSelectionModelChange={(idRow) => {
              setIdList(idRow);
            }}
          />
        </Paper>
      </Box>
    </Container>
  );
}
