/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unicorn/consistent-function-scoping */
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { waitingStyle } from "../../styles/auth/waiting";

export function Unauthorized() {

  return (
    <Stack component="section" sx={waitingStyle.contentStack2}>
      <Box sx={waitingStyle.contentStackBox2}>
        <Box
          component="img"
          src="/assets/image/cross-abu.png"
          width="40vh"
          mb={3}
        />

        <Typography variant="h3" mb={3} sx={waitingStyle.contentStackBox2Typography1}>SORRY!</Typography>
        <Typography component="span" sx={waitingStyle.contentStackBox2Typography2}>
          YOU ARE UNAUTHORIZED TO VIEW THIS PAGE
        </Typography>
      </Box>
    </Stack>
  );
}
