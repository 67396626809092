import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { FC, memo, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AssesmentForm } from "./AssesmentForm";
import { fetchEvaluationDataById } from "../../../../store/assesment-evaluation/action/evaluation.action";
import { AppDispatch, RootState } from "../../../../store";

interface DialogProps {
    myId: string[];
    onClose: () => void;
}

const detailDummyData = [
    {
        id: 1,
        institution: "Binus University",
        academicCarrer: "Undergraduate",
        term: "2024, Even Semester",
        track: "Internship",
        studentName: "Joseft",
        course: [
            { id: 1, course: "12345 - Calculus", gradeEntry: "B", finalGrade: "A" },
            { id: 2, course: "12345 - Calculus 2", gradeEntry: "C", finalGrade: "A" }
        ]
    },
    {
        id: 2,
        institution: "Binus Malang",
        academicCarrer: "Master Degree",
        term: "2024, Odd Semester",
        track: "Internship",
        studentName: "Kemal",
        course: [
            { id: 1, course: "12345 - Calculus", gradeEntry: "B", finalGrade: "A" },
            { id: 2, course: "12345 - Calculus 2", gradeEntry: "C", finalGrade: "A" }
        ]
    }
];

const DialogAssesmentDetail: FC<DialogProps> = function DialogAssesmentDetail(props) {
    const dispatch: AppDispatch = useDispatch();
    const { myId, onClose } = props;
    const [openDetail, setOpenDetail] = useState(false); // State for AssesmentDetail dialog
    const [openForm, setOpenForm] = useState(false); // State for AssesmentForm dialog
    const navigate = useNavigate();

    const { data, loading, error } = useSelector((state: RootState) => state.evaluation);
    
    useEffect(() => {
        dispatch(fetchEvaluationDataById({ id: myId.toString() }));
    }, [dispatch, myId]);


    const handleOpenDetail = () => {
        setOpenDetail(true);
    };

    const handleCloseDetail = () => {
        setOpenDetail(false);
    };

    const handleOpenForm = () => {
        setOpenForm(true);
    };

    const handleCloseForm = () => {
        setOpenForm(false);
        setOpenDetail(true); // Open AssesmentDetail after closing AssesmentForm
    };

    const handleNext = () => {
        setOpenDetail(false); // Close current dialog
        setOpenForm(true); // Open AssesmentForm dialog
    };

    const selectedDetail = data.find(detail => detail.id.toString() === myId[0]);

    if (!selectedDetail) {
        return null;
    }

    return (
        <Dialog open onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="md" fullWidth>
            <DialogTitle id="alert-dialog-title">Summary Detail</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container columnSpacing={1} sx={{ mb: -1 }}>
                    <Grid item md={6}>
                        <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                            Institution
                        </Typography>
                        <FormControl sx={{ mb: 2 }} size="small" fullWidth>
                            <TextField
                                type="text"
                                size="small"
                                disabled
                                placeholder="Institution"
                                value={selectedDetail.institution}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                            Academic Career
                        </Typography>
                        <FormControl sx={{ mb: 2 }} size="small" fullWidth>
                            <TextField
                                type="text"
                                size="small"
                                disabled
                                placeholder="Academic Career"
                                value={selectedDetail.academicCarrer}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                            Term
                        </Typography>
                        <FormControl sx={{ mb: 2 }} size="small" fullWidth>
                            <TextField
                                type="text"
                                size="small"
                                disabled
                                placeholder="Term"
                                value={selectedDetail.term}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                            Track
                        </Typography>
                        <FormControl sx={{ mb: 2 }} size="small" fullWidth>
                            <TextField
                                type="text"
                                size="small"
                                disabled
                                placeholder="Track"
                                value={selectedDetail.track}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
                            Student Name
                        </Typography>
                        <FormControl sx={{ mb: 2 }} size="small" fullWidth>
                            <TextField
                                type="text"
                                size="small"
                                disabled
                                placeholder="Student Name"
                                value={selectedDetail.studentName}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Course</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Grade Entry</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Final Grade</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedDetail.course.map(course => (
                                <TableRow key={course.id}>
                                    <TableCell>{course.course}</TableCell>
                                    <TableCell align="center">{course.gradeEntry}</TableCell>
                                    <TableCell align="center">{course.finalGrade}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogContentText id="alert-dialog-description">
                    {/** Content */}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNext} variant="contained" color="primary" sx={{ marginRight: 2 }}>
                    Next
                </Button>
            </DialogActions>
            {openForm && (
                <AssesmentForm myId={selectedDetail.id.toString()} onClose={handleCloseForm} />
            )}
        </Dialog>
    );
};

export const AssesmentDetail = memo(DialogAssesmentDetail);
