import { useQuery } from "@tanstack/react-query";
import apiClient from "../config/api-client";
import { ApiDashboard } from "../constants/ApiDashboard";
import { DashboardResponse } from "../types/dashboard";
import { UseQueryOptions } from "../types/react-query";



export const useQueryDashboardGet = ({
  query,
  options,
}: {
  query: { binusianId?: string };
  options?: UseQueryOptions<DashboardResponse>;
}) =>
  useQuery(
    ["dashboardGet", query.binusianId],
    () =>
      apiClient
        .get<unknown, { data: DashboardResponse }>(
          ApiDashboard.getDashboard(
            query.binusianId,
          )
        )
        .then((res) => res.data.data),
    options
  );
