/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unicorn/consistent-function-scoping */
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider/Divider';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import { useSignInByAzureAD } from '../../../api/identity';
import { useMutationProfile } from '../../../api/profile';
import { ModalAlert } from '../../common/modal-alert';
// import { OverlayAzure, OverlayModalLogin } from 'components/layout/overlay';
import useModal from '../../../hooks/use-modal';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthUserAzureAD } from '../../../store/auth/selector';
import { setAuth, setAuthAzureAD } from '../../../store/auth/slice';
import { setAuthToken, setAuthTokenAzureAD, setRefreshToken } from '../../../store/authToken/slice';
import { setActiveRole, setProfile } from '../../../store/profile/slice';
import { loginStyle } from '../../../styles/container/login';
import { ModalLoginProps } from '../../../types/modal-login';
import { loginRequest, parseDataAzureAD, redirectToAzureAd } from '../../../utils/azure-ad';
import { getTokenPayload } from '../../../utils/token';

export function LoginContent({ modalLogin, setModalLogin }: ModalLoginProps) {
  const [modalFailed, setModalFailed] = useState(false);
  const Navigate = useNavigate();
  const userAzureAD = useSelector(selectAuthUserAzureAD);

  const handleLogin = () => {
    redirectToAzureAd({
      prompt: 'select_account',
      state: { continue: '' },
    });
  };

  const dispatch = useDispatch();
  const getProfile = useMutationProfile({
    onSuccess: (res) => {
      dispatch(setProfile(res));
      const primaryRole =
        res?.organizationRoles?.find((role) => role.isPrimary) || res?.organizationRoles?.[0];
      dispatch(setActiveRole(primaryRole));
    },
  });
  const { showModal } = useModal();
  const signByAzureAD = useSignInByAzureAD({
    onSuccess: async (res) => {
      dispatch(setAuthToken(res.accessToken));
      dispatch(setRefreshToken(res.refreshToken));
      getProfile.mutate(userAzureAD?.unique_name, {
        onSuccess: () => {
          const userPayload = getTokenPayload(res.accessToken as string);
          dispatch(setAuth(userPayload));
        },
      });
    },
    onError: (error) => {
      showModal({
        title: 'Login Failed',
        message:
          'User account not found, please contact administrator https://binus.ac.id/binusmaya-support',
        options: {
          variant: 'failed',
        },
      });
    },
  });

  // useEffect(() => {
  //   if (window.location.hash) {
  //     console.log(window.location.hash);
  //     const adState = parseDataAzureAD(window.location.hash);
  //     if (!adState._success) return;

  //     dispatch(setAuthTokenAzureAD(adState.access_token));
  //     dispatch(setAuthAzureAD(jwtDecode(adState.access_token as string)));

  //     signByAzureAD.mutate({
  //       accessToken: adState.access_token || '',
  //     });
  //   }
  // }, []);

  return (
    <Stack component="section" sx={loginStyle.contentStack}>
      <Box sx={loginStyle.contentStackBox}>
        <Typography sx={loginStyle.contentStackBoxTypography1}>Welcome to Flexible Program</Typography>
        <Typography variant="caption" sx={loginStyle.contentStackBoxTypography2}>
          Discover more than teaching and learning experience with your Flexible Program
        </Typography>
        <Divider sx={loginStyle.contentStackBoxDivider} />
        <Stack direction="column" gap="10px">
          {/* <Button
            variant="contained"
            color="info"
            sx={loginStyle.contentStackBoxLoginButton}
            onClick={() => setModalLogin?.(!modalLogin)}
            disabled={signByAzureAD.isLoading}
          >
            <Typography fontFamily="Segoe UI" fontSize={15} fontWeight={600}>
              Login
            </Typography>
          </Button> */}
          <LoadingButton
            variant="contained"
            color="info"
            loadingIndicator={<CircularProgress sx={{ color: '#5E5E5E' }} size="20px" />}
            sx={loginStyle.contentStackBoxLoginButton}
            onClick={handleLogin}
            loading={signByAzureAD.isLoading}
            disabled={signByAzureAD.isLoading}
          >
            <Box
              component="img"
              src="/assets/logo/microsoft.png"
              sx={
                signByAzureAD.isLoading // && getProfile.isLoading
                  ? { display: 'none' }
                  : { display: 'block' }
              }
            />
            <Typography
              fontFamily="Segoe UI"
              fontSize={15}
              fontWeight={600}
              sx={
                signByAzureAD.isLoading // && getProfile.isLoading
                  ? { display: 'none' }
                  : { display: 'block' }
              }
            >
              Sign in with Microsoft
            </Typography>
          </LoadingButton>
          {/* <Button
            sx={loginStyle.contentStackBoxRegisterButton}
            onClick={() => Navigate('/register')}
            disabled={signByAzureAD.isLoading}
          >
            GET YOUR USERNAME
          </Button> */}
        </Stack>
      </Box>
      <Box
        component="img"
        src="/assets/image/ilustrasi-login.png"
        sx={loginStyle.contentStackBoxImg}
      />

      <ModalAlert
        variant="failed"
        title="Login"
        message="User account not found, please contact administrator https://binus.ac.id/binusmaya-support"
        buttonTitle="back to login page"
        open={modalFailed}
        onOk={() => setModalFailed(false)}
        onClose={() => setModalFailed(false)}
      />
    </Stack>
  );
}
