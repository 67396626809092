import {
  Container,
  Stack,
  Breadcrumbs,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { GridCellParams, GridColDef } from "@mui/x-data-grid";

import useModal from "../../hooks/use-modal";
import { CustomDataGrid } from "../../components/common/datagrid";

import { breadCrumbLink } from "../../styles/common/bread-crumb-link";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  useQueryListInstitutionDropDown,
  useQueryListAcadCareerDropDown,
} from "../../api/dropdown";
import {
  InstitutionDropdownResponseData,
  AcadCareerDropdownResponseData,
} from "../../types/dropdown";
import { EvaluationComponentList } from "../../types/setup-enrichment-program/evaluation-component";
import {
  useMutationEvaluationComponentList,
  useMutationAddEvaluationComponent,
  useMutationDeleteEvaluationComponent,
  useMutationUpdateEvaluationComponent,
} from "../../api/setup-enrichment-program/evaluation-component";

export function EvaluationComponent() {
  const { showModal } = useModal();

  const [dataInstitution, setDataInstitution] = useState<
    InstitutionDropdownResponseData[]
  >([]);
  const [dataAcadCareer, setDataAcadCareer] = useState<
    AcadCareerDropdownResponseData[]
  >([]);
  const [institutionFilter, setInstitutionFilter] = useState("");
  const [acadCareerFilter, setAcadCareerFilter] = useState("");
  const [componentName, setComponentName] = useState("");
  const [editComponentName, setEditComponentName] = useState("");
  const [componentId, setComponentId] = useState("");
  const [evaluationComponentList, setEvaluationComponentList] = useState<
    EvaluationComponentList[]
  >([]);

  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [openAddComponentModal, setOpenAddComponentModal] = useState(false);
  const [openEditComponentModal, setOpenEditComponentModal] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No",
      flex: 0.5,
      maxWidth: 100,
      hideable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "desc",
      headerName: "Component",
      flex: 1,
      hideable: false,
    },
    {
      field: "",
      headerName: "Action",
      flex: 1,
      maxWidth: 110,
      hideable: false,
      align: "right",
      headerAlign: "center",
      renderCell: (params: GridCellParams) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setComponentId(params.row.id);
              setEditComponentName(params.row.desc);
              setOpenEditComponentModal(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteConfirmation(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataInstitution(res.data);
      },
      onError: () => {
        setDataInstitution([]);
      },
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataAcadCareer(res.data);
      },
      onError: () => {
        setDataAcadCareer([]);
      },
    },
  });

  const getEvaluationComponentList = useMutationEvaluationComponentList({
    query: {
      search: "",
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page,
      orderBy: "id:asc",
    },
    options: {
      onSuccess: async (res) => {
        setEvaluationComponentList(res.data);
        setTotalItems(res.paginationInfo.totalItems);
      },
      onError: async (err) => {
        setEvaluationComponentList([]);
      },
    },
  });

  const addEvaluationComponentList = useMutationAddEvaluationComponent({
    onSettled: async (res) => {
      getEvaluationComponentList.mutate({
        institution: institutionFilter,
        academicCareer: acadCareerFilter,
      });
    },
    onSuccess: async (res) => {
      setOpenAddComponentModal(false);
      setComponentName("");

      showModal({
        title: "Success",
        message: "Add evaluation component successfully",
        options: {
          variant: "success",
        },
      });
    },
    onError: async (err) => {
      setOpenAddComponentModal(false);
      setComponentName("");

      showModal({
        title: "Failed",
        message: "Add evaluation component is failed.",
        options: {
          variant: "failed",
        },
      });
    },
  });

  const deleteEvaluationComponent = useMutationDeleteEvaluationComponent({
    query: {
      id: componentId,
    },
    options: {
      onSettled: async (res) => {
        getEvaluationComponentList.mutate({
          institution: institutionFilter,
          academicCareer: acadCareerFilter,
        });
      },
      onSuccess: async (res) => {
        showModal({
          title: "Success",
          message: "Delete evaluation component successfully.",
          options: {
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        showModal({
          title: "Failed",
          message: "Delete evaluation component is failed.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  const updateEvaluationComponentList = useMutationUpdateEvaluationComponent({
    query: {
      id: componentId,
    },
    options: {
      onSettled: async (res) => {
        getEvaluationComponentList.mutate({
          institution: institutionFilter,
          academicCareer: acadCareerFilter,
        });
      },
      onSuccess: async (res) => {
        setOpenEditComponentModal(false);
        setEditComponentName("");

        showModal({
          title: "Success",
          message: "Update evaluation component successfully",
          options: {
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        setOpenEditComponentModal(false);
        setEditComponentName("");

        showModal({
          title: "Failed",
          message: "Update evaluation component is failed.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  const handleSearch = () => {
    getEvaluationComponentList.mutate({
      institution: institutionFilter,
      academicCareer: acadCareerFilter,
    });
  };

  const handleAddComponent = () => {
    addEvaluationComponentList.mutate({
      name: componentName,
      institution: institutionFilter,
      acadCareer: acadCareerFilter,
    });
  };

  const handleDeleteConfirmation = (component: string) => {
    setComponentId(component);
    showModal({
      title: "Alert",
      message: `Are you sure want delete this component?`,
      options: {
        buttonTitle: "Delete",
        buttonTitle2: "Cancel",
        variant: "info",
        onOk() {
          deleteEvaluationComponent.mutate({});
        },
      },
    });
  };

  const handleUpdateComponent = () => {
    console.log(componentId);
    updateEvaluationComponentList.mutate({
      name: editComponentName,
      institution: institutionFilter,
      acadCareer: acadCareerFilter,
    });
  };

  useEffect(() => {
    getEvaluationComponentList.mutate({
      institution: institutionFilter,
      academicCareer: acadCareerFilter,
    });
  }, [paginationModel]);

  return (
    <>
      <Container maxWidth={false}>
        <Stack mt={2} spacing={2}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
              Setup Enrichment Program
            </Link>
            <Typography fontSize={14} color="#0097DA">
              Evaluation Component
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Typography variant="h5" gutterBottom mt={2}>
          Evaluation Component
        </Typography>
        <Divider />

        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="institution-select">Institution</InputLabel>
              <Select
                labelId="institution-select"
                id="institution"
                value={institutionFilter}
                label="Institution"
                onChange={(e) => setInstitutionFilter(e.target.value)}
              >
                {dataInstitution.map((item) => (
                  <MenuItem key={item.Id} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="acad-career-select">Academic Career</InputLabel>
              <Select
                labelId="acad-career-select"
                id="acad-career"
                value={acadCareerFilter}
                label="Academic Career"
                onChange={(e) => setAcadCareerFilter(e.target.value)}
              >
                {dataAcadCareer.map((item) => (
                  <MenuItem key={item.Id} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid display="flex" justifyContent="end" mt={2} mb={2}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>

        <Divider />

        <Box mt={3}>
          <Grid display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h5" gutterBottom>
              Evaluation Component
            </Typography>
            <Button
              disabled={institutionFilter === "" || acadCareerFilter === ""}
              onClick={() => setOpenAddComponentModal(true)}
              variant="contained"
              color="primary"
            >
              Add Component
            </Button>
          </Grid>

          <Paper elevation={3}>
            <CustomDataGrid
              columnsData={columns}
              rowsData={evaluationComponentList}
              rowCount={totalItems}
              getRowId={(row) => row.id}
              setPaginationModel={(value) => {
                setPaginationModel(value);
              }}
              paginationModel={paginationModel}
            />
          </Paper>
        </Box>
      </Container>
      <Dialog
        open={openAddComponentModal}
        onClose={() => {
          setOpenAddComponentModal(false);
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Add Evaluation Component
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenAddComponentModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={2}>
            <TextField
              fullWidth
              id="evaluation-component-name"
              label="Evaluation Component Name"
              variant="outlined"
              size="small"
              value={componentName}
              onChange={(event) => {
                setComponentName(event.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenAddComponentModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddComponent}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditComponentModal}
        onClose={() => {
          setOpenEditComponentModal(false);
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Edit Evaluation Component
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenEditComponentModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={2}>
            <TextField
              fullWidth
              id="evaluation-component-name"
              label="Evaluation Component Name"
              variant="outlined"
              size="small"
              value={editComponentName}
              onChange={(event) => {
                setEditComponentName(event.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenEditComponentModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateComponent}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
