import { Search } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  useQueryAcadProg,
  useQueryAssesmentComp,
  useQueryListAcadCareerDropDown,
  useQueryListCampusDropDown,
  useQueryListInstitutionDropDown,
  useQueryListTrackDropDown,
} from "../../api/dropdown";
import {
  useMutationAddNewIndicator,
  useMutationSetMaxWeight,
  useMutationUpdateIndicator,
  useQueryGetIndicatorById,
} from "../../api/setup-enrichment-program/assesment-mapping";
import {
  useMutationGenerateSubmit,
  useMutationShowTableGenerateGradeFall,
  useMutationUploadGradeFail,
  useQueryExportExcelGenerateGradeFail,
} from "../../api/setup-enrichment-program/generate-grade-fail";
import { useQueryGetTermDropDown } from "../../api/student-registration";
import { CustomDataGrid } from "../../components/common/datagrid";
import useModal from "../../hooks/use-modal";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { ComponentCss } from "../../styles/page/ComponentCss";
import { UserRoleCss } from "../../styles/page/UserRoleCss";
import {
  DropDownCampusLocationResponseData,
  DropdownResponseData,
  InstitutionDropdownResponseData,
} from "../../types/dropdown";
import { ShowTableUserMappingData } from "../../types/user-mapping";

export function GenerateGradeFall() {
  const { showModal } = useModal();

  const [dataTable, setDataTable] = useState<ShowTableUserMappingData[]>([]);
  const [institution, setInstitution] = useState<
    InstitutionDropdownResponseData[]
  >([]);
  const [campus, setCampus] = useState<DropDownCampusLocationResponseData[]>(
    []
  );
  const [track, setTrack] = useState<DropdownResponseData[]>([]);
  const [acadProgList, setAcadProgList] = useState<DropdownResponseData[]>([]);
  const [assesmentCompList, setAssesmentCompList] = useState<
    DropdownResponseData[]
  >([]);
  const [acadCareer, setAcadCareer] = useState<DropdownResponseData[]>([]);
  const [term, setTerm] = useState<DropdownResponseData[]>([]);

  const [totalItems, setTotalItems] = useState(0);

  const [uploadFile, setUploadFile] = useState<File | null>(null);

  const [selectTable, setSelectTable] = useState<ShowTableUserMappingData[]>(
    []
  );
  const [listUploadGrade, setListUploadGrade] = useState<
    ShowTableUserMappingData[]
  >([]);

  const [modalListGrade, setModalListGrade] = useState(false);

  const [modalUpload, setModalUpload] = useState(false);
  const [checkedUpdateAll, setCheckedUpdateAll] = useState(false);

  const [id, setId] = useState("");
  const [modalEdit, setModalEdit] = useState(false);

  const [indicator, setIndicator] = useState("");
  const [weight, setWeight] = useState("");
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [doExportExcel, setDoExportExcel] = useState(false);

  // Drop Down Value
  const [institutionDropValue, setInstitutionDropValue] = useState("");
  const [campusDropValue, setCampusDropValue] = useState("");
  const [acadProgValue, setAcadProgValue] = useState("");
  const [trackDropValue, setTrackDropValue] = useState("");
  const [acadCareerValue, setAcadCareerValue] = useState("");
  const [termValue, setTermValue] = useState("");
  const [binusId, setBinusId] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const submitShowTable = useMutationShowTableGenerateGradeFall({
    onSuccess: async (res: any) => {
      setDataTable(res.data || []);
      console.log(res, "RES");
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const submitEditIndicator = useMutationUpdateIndicator({
    onSuccess: async (res: any) => {
      // setDataTable(res.data || []);
      console.log(res, "RES");
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const editIndicator = () => {
    submitEditIndicator.mutate({
      id,
      indicator,
      weight,
      status,
      updateAllAcadProg: checkedUpdateAll,
    });
  };

  const handleUploadChange = (newValue: File | null) => {
    const file = newValue;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB limit

    if (file && file.size > maxSizeInBytes) {
      showModal({
        title: "Upload Failed",
        message: "File size exceeds 5 MB",
        options: {
          variant: "failed",
        },
      });

      setUploadFile(null);
    } else {
      setUploadFile(file);
    }
  };

  const showTable = () => {
    submitShowTable.mutate({
      institution: institutionDropValue,
      acadCareer: acadCareerValue,
      term: termValue,
      campus: campusDropValue,
      track: trackDropValue,
      acadProg: acadProgValue,
      binusianId: binusId,
    });
  };

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setInstitution(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAcadCareer(res.data || []);
      },
      onError: () => {},
    },
  });

  const getTerm = useQueryGetTermDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setTerm(res.data || []);
      },
      onError: () => {},
    },
  });

  const getTrack = useQueryListTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTrack(res.data || []);
      },
      onError: () => {},
    },
  });

  const getCampus = useQueryListCampusDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setCampus(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadProg = useQueryAcadProg({
    query: {
      acadOrg: ""
    },
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAcadProgList(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAssesmentComp = useQueryAssesmentComp({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAssesmentCompList(res.data || []);
      },
      onError: () => {},
    },
  });

  const handleClose = () => {
    setModalUpload(false);
    setModalEdit(false);
    setModalListGrade(false);
  };

  const handleChangeUpdateAll = (event) => {
    setCheckedUpdateAll(event.target.checked);
  };

  const [selectionModel, setSelectionModel] = useState([]);
  const apiRef = useGridApiRef();

  const submitGradeFail = useMutationGenerateSubmit({
    options: {
      onSuccess: async (res: any) => {
        setModalUpload(false);
        setModalListGrade(false);
        showModal({
          title: "Success",
          message: res.message,
          options: {
            variant: "success",
          },
        });
      },
      onError: (e) => {
        console.log(e, "ERROR");
        showModal({
          title: "Failed",
          message: "Submit profile is failed.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  const submitUploadGradeFail = useMutationUploadGradeFail({
    onSuccess: async (res: any) => {
      setModalUpload(false);
      setModalListGrade(true);
      setListUploadGrade(res);
    },
    onError: (e) => {
      console.log(e, "ERROR");
      showModal({
        title: "Failed",
        message: "Submit profile is failed.",
        options: {
          variant: "failed",
        },
      });
    },
  });

  const submitUploadData = () => {
    const formData = new FormData();

    uploadFile
      ? formData.append("file", uploadFile)
      : formData.append("file", new Blob());

    submitUploadGradeFail.mutate(formData);
  };

  const submitGenerate = () => {
    submitGradeFail.mutate(selectTable);
  };

  const submitGenerateModal = () => {
    submitGradeFail.mutate(listUploadGrade);
  };

  const columns: GridColDef[] = [
    {
      field: "binusianId",
      headerName: "Student ID",
      flex: 1,
      hideable: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      hideable: false,
    },
    {
      field: "acadProg",
      headerName: "Academic Program",
      flex: 1,
      hideable: false,
    },
    {
      field: "facultySupervisor",
      headerName: "Faculty Supervisor",
      flex: 1,
      hideable: false,
    },
    {
      field: "track",
      headerName: "Track",
      flex: 1,
      hideable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      hideable: false,
    },
  ];

  // useEffect(() => {
  //   showTable();
  // }, []);

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Setup Enrichment Program
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Generate Grade Fall
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Generate Grade Fall
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Instiution
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setInstitutionDropValue(e.target.value as string)}
          >
            {institution.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Academic Career
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setAcadCareerValue(e.target.value as string)}
          >
            {acadCareer.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Term
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setTermValue(e.target.value as string)}
          >
            {term.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Campus
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setCampusDropValue(e.target.value as string)}
          >
            {campus.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Track
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setTrackDropValue(e.target.value as string)}
          >
            {track.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Academic Program
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setAcadProgValue(e.target.value as string)}
          >
            {acadProgList.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Binusian ID
          </Typography>
          <OutlinedInput
            onChange={(e) => setBinusId(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button onClick={() => showTable()} variant="contained" color="primary">
          Search
        </Button>
      </Grid>

      <Divider />
      <Grid mt={1} spacing={2} container display="flex" justifyContent="end">
        <Grid item>
          <Button onClick={() => setModalUpload(true)} variant="contained">
            Upload Data
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => setDoExportExcel(true)} variant="contained">
            Download Data
          </Button>
        </Grid>
        <Grid item>
          <Box sx={UserRoleCss.searchBar}>
            <OutlinedInput
              onChange={(e) => setSearch(e.target.value)}
              sx={UserRoleCss.inputSearch}
              placeholder="Search"
            />
            <Search sx={UserRoleCss.iconSearch} />
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Paper elevation={3}>
          <CustomDataGrid
            columnsData={columns}
            rowsData={dataTable}
            rowCount={totalItems}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
            checkboxSelection
            getRowId={(row) => row.no}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = dataTable.filter((row) =>
                selectedIDs.has(row.no)
              );
              setSelectTable(selectedRows);
            }}
          />
        </Paper>

        <Grid mt={2} display="flex" justifyContent="end" mb={2}>
          <Grid>
            <Button
              onClick={() => submitGenerate()}
              variant="contained"
              color="primary"
            >
              Generate
            </Button>
          </Grid>
          <Modal
            open={modalUpload}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ComponentCss.modalUploadLowStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontWeight="bold"
              >
                Upload Data
              </Typography>

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Upload Here
              </Typography>
              <OutlinedInput
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleUploadChange(event.target.files?.[0] || null)
                }
                inputProps={{
                  accept:
                    ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
                type="file"
                fullWidth
              />
              <Typography id="modal-modal-description" fontSize={10} mt={1}>
                *Format file must be in .xls or .xlxs
              </Typography>

              <Box mt={2} display="flex" justifyContent="end">
                <Button onClick={() => submitUploadData()} variant="contained">
                  Submit
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={modalListGrade}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ComponentCss.modalUploadBigStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontWeight="bold"
              >
                Upload Grade Fail
              </Typography>

              <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>StudentID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Academic Program</TableCell>
                      <TableCell>Faculty Supervisor</TableCell>
                      <TableCell>Track</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listUploadGrade.map((row) => (
                      <TableRow key={row.no}>
                        <TableCell component="th" scope="row">
                          {row.binusianId}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.acadProgDesc}</TableCell>
                        <TableCell>{row.facultySupervisor}</TableCell>
                        <TableCell>{row.track}</TableCell>
                        <TableCell>{row.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box mt={2} display="flex" justifyContent="end">
                <Button
                  onClick={() => submitGenerateModal()}
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={modalEdit}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ComponentCss.modalUploadLowStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontWeight="bold"
              >
                Edit Indicator
              </Typography>

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Indicator
              </Typography>
              <OutlinedInput
                value={indicator}
                onChange={(e) => setIndicator(e.target.value)}
                fullWidth
              />

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Weight
              </Typography>
              <OutlinedInput
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                fullWidth
              />

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Status
              </Typography>
              <Select
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                fullWidth
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>

              <Box mt={2} display="flex">
                <Checkbox
                  checked={checkedUpdateAll}
                  onChange={handleChangeUpdateAll}
                  sx={{ width: "18px", height: "18px" }}
                />
                <Typography fontSize={14} ml={1}>
                  Update for All Academic Program
                </Typography>
              </Box>

              <Box mt={2} display="flex" justifyContent="end">
                <Button
                  onClick={() => setModalEdit(false)}
                  variant="contained"
                  sx={{ marginRight: "10px" }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={editIndicator} variant="contained">
                  Save
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </Box>
    </Container>
  );
}
