/* eslint-disable no-param-reassign */
import axios from "axios";
import { store } from "../store";

const apiClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  const {
    // profile: { activeRole },
    authToken: { myDashboardToken, azureADToken },
  } = store.getState();

  config.headers = config.headers ?? {};
  const url = config.url ?? "";

  if (myDashboardToken) {
    config.headers.Authorization = `Bearer ${myDashboardToken}`;
  }

  if (url.includes("graph.microsoft.com")) {
    config.headers.Authorization = `Bearer ${azureADToken}`;
  } else if (azureADToken) {
    config.headers.Authorization = `Bearer ${token?.replace(/"/g, '')}`;
    config.headers.AzureADToken = `Bearer ${azureADToken}`;
  }

  // if (activeRole?.roleId) {
  //   config.headers.roleId = activeRole?.roleId;
  //   config.headers.userRoleId = activeRole?.roleId;
  // }

  if (config.method === "post" && config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

apiClient.interceptors.response.use(
  (res) => res,
  (err) =>
    // const { dispatch } = store;
    // if (err.response?.data?.errorCode === 'REFRESH_TOKEN_INVALID') {
    //   window.location.href = '/logout';
    // }

    // if (err.response?.status === 500) {
    //   const {
    //     authToken: { myDashboardToken, refreshToken },
    //   } = store.getState();

    //   return apiClient
    //     .post<IdentityLoginResponse>(`${ApiIdentity.refreshAccessToken}`, {
    //       accessToken: myDashboardToken,
    //       refreshToken,
    //     })
    //     .then((res) => {
    //       dispatch(setAuthToken(res.data.accessToken));
    //       dispatch(setRefreshToken(res.data.refreshToken));
    //     });
    // }
    Promise.reject(err)
);

export default apiClient;
