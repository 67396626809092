import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
// import StepButton from "@mui/material/StepButton";
import StepLabel from '@mui/material/StepLabel';
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from '@mui/icons-material/Delete';
import { GridColDef, GridCellParams } from "@mui/x-data-grid";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextComp } from "../../components/atom/text-comp";
import { CustomDataGrid } from "../../components/common/datagrid";
import useModal from "../../hooks/use-modal";

import { selectAuthUser } from "../../store/auth/selector";
import {
  EnrichmentTrackData,
  // CourseList,
  MinorList,
} from "../../types/enrichment-track";
import {
  useQueryGetEnrichmentTrack,
  // useQueryCourseList,
  useQueryMinorList,
  useMutationSubmitEnrichment,
  useMutationConfirmEnrichment,
} from "../../api/enrichment-track";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";

const steps = [
  "Choose Enrichment",
  "Confirmation",
  "Approved Track",
  "Summary",
];

interface ActionsProps {
  period: string;
  subjectCode: string;
  handleRadioButtonChange: (subjectCode: any, value: any) => void;
  radioButtonValue: {};
}

function RadioPeriodActions({
  period,
  subjectCode,
  handleRadioButtonChange,
  radioButtonValue,
}: ActionsProps) {
  return (
    <Box>
      <Radio
        checked={radioButtonValue[subjectCode] === period}
        onChange={() => handleRadioButtonChange(subjectCode, period)}
        value={period}
        name={`radio-button-${subjectCode}`}
        inputProps={{ "aria-label": `${period}` }}
      />
    </Box>
  );
}

const formatDate = (dateString) => {
  let parsedDateString = "";
  let formattedDate;

  if(dateString != null){
    parsedDateString= dateString.replace(/(\w+), (\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3/$2/$4 $5:$6:$7');

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Asia/Bangkok',
      timeZoneName: 'short'
    };
  
    const date = new Date(parsedDateString);
    formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
  }
  
  return formattedDate
};

export function ChooseEnrichmentTrack() {
  const profile = useSelector(selectAuthUser);

  const { showModal } = useModal();

  const dataPriority = [
    {
      priorityId: "1",
      priorityName: "Priority 1",
    },
    {
      priorityId: "2",
      priorityName: "Priority 2",
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [skipped, setSkipped] = useState(new Set<number>());
  const [enrichmentList, setEnrichmentList] = useState<EnrichmentTrackData | null>(null);
  const [priorityFreeElective, setPriorityFreeElective] = useState("");
  const [priorityIndependentStudy, setPriorityIndependentStudy] = useState("");
  const [priorityMinorTechnopreneur, setPriorityMinorTechnopreneur] = useState("");
  const [priorityMinorAI, setPriorityMinorAI] = useState("");
  const [priorityEntrepreneurship, setPriorityEntrepreneurship] = useState("");
  const [priorityInternship, setPriorityInternship] = useState("");
  // const [priorityFurtherStudy, setPriorityFurtherStudy] = useState("");
  // const [priorityResearch, setPriorityResearch] = useState("");
  // const [priorityCommunityDevelopment, setPriorityCommunityDevelopment] = useState("");
  const [doGetMinorList, setDoGetMinorList] = useState(false);
  const [selectedMinorCode, setSelectedMinorCode] = useState("");
  const [selectedMinorName, setSelectedMinorName] = useState("");
  // const [courseList, setCourseList] = useState<CourseList[]>([]);
  const [minorList, setMinorList] = useState<MinorList[]>([]);
  const [firstTrackSelected, setFirstTrackSelected] = useState("");
  const [firstTrackSelectedDesc, setFirstTrackSelectedDesc] = useState("");
  const [secondTrackSelected, setSecondTrackSelected] = useState("");
  const [secondTrackSelectedDesc, setSecondTrackSelectedDesc] = useState("");
  // const [selectedIdCourses, setSelectedIdCourses] = useState<[]>([]);
  // const [selectedCourses, setSelectedCourses] = useState<{} | null>(null);
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [loadConfirm, setLoadConfirm] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [totalItems, setTotalItems] = useState(0);
  // const [openChooseCourseModal, setOpenChooseCourseModal] = useState(false);
  // const [openChooseMinorModal, setOpenChooseMinorModal] = useState(false);
  const [openViewCourseModal, setOpenViewCourseModal] = useState(false);

  const [selectedValues, setSelectedValues] = useState({});

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNextStepper = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleRadioButtonChange = (subjectCode, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [subjectCode]: value,
    }));
  };

  // const columnsChooseCourse: GridColDef[] = [
  //   {
  //     field: "subjectCode",
  //     headerName: "Subject Code",
  //     flex: 0.5,
  //     minWidth: 100,
  //     hideable: false,
  //   },
  //   {
  //     field: "subjectDesc",
  //     headerName: "Subject",
  //     flex: 1,
  //     minWidth: 200,
  //     hideable: false,
  //   },
  //   {
  //     field: "sks",
  //     headerName: "SKS",
  //     flex: 0.5,
  //     minWidth: 100,
  //     hideable: false,
  //   },
  //   {
  //     field: "periode1",
  //     headerName: "Period 1",
  //     flex: 0.5,
  //     minWidth: 100,
  //     hideable: false,
  //     renderCell: (params) => (
  //       <RadioPeriodActions
  //         period="1"
  //         subjectCode={params.row.subjectCode}
  //         handleRadioButtonChange={handleRadioButtonChange}
  //         radioButtonValue={selectedValues}
  //       />
  //     ),
  //   },
  //   {
  //     field: "periode2",
  //     headerName: "Period 2",
  //     flex: 0.5,
  //     minWidth: 100,
  //     hideable: false,
  //     renderCell: (params) => (
  //       <RadioPeriodActions
  //         period="2"
  //         subjectCode={params.row.subjectCode}
  //         handleRadioButtonChange={handleRadioButtonChange}
  //         radioButtonValue={selectedValues}
  //       />
  //     ),
  //   },
  // ];

  const isOptionDisabled = (priority: string) => {
    const allSelectedOptions = [
      priorityFreeElective,
      priorityIndependentStudy,
      priorityMinorTechnopreneur,
      priorityMinorAI,
      priorityEntrepreneurship,
      priorityInternship
    ];

    return Object.values(allSelectedOptions).includes(priority);
  };

  const mapTrackToPriorityState = (firstTrack, secondTrack) => {
    const trackToStateSetter = {
      FE: setPriorityFreeElective,
      MC: setPriorityIndependentStudy,
      MP: setPriorityMinorTechnopreneur,
      MA: setPriorityMinorAI,
      EN: setPriorityEntrepreneurship,
      IN: setPriorityInternship,
    };
  
    Object.values(trackToStateSetter).forEach(setState => setState(""));
  
    if (trackToStateSetter[firstTrack]) {
      trackToStateSetter[firstTrack]("Priority 1"); 
    }
    if (trackToStateSetter[secondTrack]) {
      trackToStateSetter[secondTrack]("Priority 2");
    }
  };

  const getEnrichmentList = useQueryGetEnrichmentTrack({
    query: {
      email: profile.email,
      binusianid: profile.binusianId,
    },
    options: {
      onSuccess: (res) => {
        setEnrichmentList(res.data);
        mapTrackToPriorityState(res.data.firstTrack, res.data.secondTrack)

        const {checkStatus} = res.data;
        checkStatus === "Submitted Track" ? setActiveStep(1) : 
        checkStatus === "Confirmed Track" ? setActiveStep(2) : 
        checkStatus === "Approved Track" ? setActiveStep(2) : setActiveStep(0);
        // setTotalItems(res.paginationInfo.totalItems);
      },
      onError: () => {
        setEnrichmentList(null);
      },
    },
  });

  // const getCourseList = useQueryCourseList({
  //   query: {
  //     acadcareer: profile.acadCareer,
  //   },
  //   options: {
  //     onSuccess: (res) => {
  //       setCourseList(res.data);
  //       setTotalItems(res.paginationInfo.totalItems);
  //     },
  //     onError: () => {
  //       setCourseList([]);
  //     },
  //   },
  // });

  const getMinorList = useQueryMinorList({
    query: {
      acadcareer: profile.acadCareer,
      track: selectedMinorCode
    },
    options: {
      enabled: doGetMinorList,
      onSuccess: (res) => {
        setMinorList(res.data);
      },
      onError: () => {
        setMinorList([]);
      },
    },
  });

  const submitEnrichment = useMutationSubmitEnrichment({
    onSuccess: async (res) => {
      setLoadSubmit(false);
      setPriorityFreeElective("");
      setPriorityIndependentStudy("");
      setPriorityMinorTechnopreneur("");
      setPriorityMinorAI("");
      setPriorityEntrepreneurship("");
      setPriorityInternship("");

      handleNextStepper();
      getEnrichmentList.refetch();
    },
    onError: () => {
      setLoadSubmit(false);
      showModal({
        title: "Failed",
        message: "Submit enrichment is failed.",
        options: {
          variant: "failed",
        },
      });
    },
  });

  const confirmEnrichment = useMutationConfirmEnrichment({
    onSuccess: async (res) => {
      setLoadConfirm(false);
      showModal({
        title: "Success",
        message:
          "Your enrichment choice has been successfully submitted, waiting for approval.",
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk: () => {
            getEnrichmentList.refetch();
            handleNextStepper();
          },
        },
      });
    },
    onError: () => {
      setLoadConfirm(false);
      showModal({
        title: "Failed",
        message: "Submit enrichment is failed.",
        options: {
          variant: "failed",
        },
      });
    },
  });

  const getFirstAndSecondPriority = () => {
    let firstTrackSelectedTemp = "";
    let firstTrackSelectedDescTemp = "";
    let secondTrackSelectedTemp = "";
    let secondTrackSelectedDescTemp = "";

    const priorityMapping = [
      { priority: priorityFreeElective, code: "FE", desc: "Free Elective" },
      { priority: priorityIndependentStudy, code: "MC", desc: "Certified Specific Independent Study" },
      { priority: priorityMinorTechnopreneur, code: "MP", desc: "Minor Technopreneurship" },
      { priority: priorityMinorAI, code: "MA", desc: "Artificial Intelligence in Business" },
      { priority: priorityEntrepreneurship, code: "EN", desc: "Certified Entrepreneurship" },
      { priority: priorityInternship, code: "IN", desc: "Certified Internship" }
    ];

    priorityMapping.forEach(({ priority, code, desc }) => {
      if (priority === "Priority 1") {
        firstTrackSelectedTemp = code;
        firstTrackSelectedDescTemp = desc;
      } else if (priority === "Priority 2") {
        secondTrackSelectedTemp = code;
        secondTrackSelectedDescTemp = desc;
      }
    });

    setFirstTrackSelected(firstTrackSelectedTemp);
    setFirstTrackSelectedDesc(firstTrackSelectedDescTemp);
    setSecondTrackSelected(secondTrackSelectedTemp);
    setSecondTrackSelectedDesc(secondTrackSelectedDescTemp);

    // if (priorityFurtherStudy !== "") {
    //   if (priorityFurtherStudy === "1") {
    //     setFirstTrackSelected("FS");
    //     setFirstTrackSelectedDesc("Further Study");
    //   } else if (priorityFurtherStudy === "2") {
    //     setSecondTrackSelected("FS");
    //     setSecondTrackSelectedDesc("Further Study");
    //   }
    // }

    // if (priorityResearch !== "") {
    //   if (priorityResearch === "1") {
    //     setFirstTrackSelected("R");
    //     setFirstTrackSelectedDesc("Research");
    //   } else if (priorityResearch === "2") {
    //     setSecondTrackSelected("R");
    //     setSecondTrackSelectedDesc("Research");
    //   }
    // }

    // if (priorityCommunityDevelopment !== "") {
    //   if (priorityCommunityDevelopment === "1") {
    //     setFirstTrackSelected("CD");
    //     setFirstTrackSelectedDesc("Community Development");
    //   } else if (priorityCommunityDevelopment === "2") {
    //     setSecondTrackSelected("CD");
    //     setSecondTrackSelectedDesc("Community Development");
    //   }
    // }

    return {
      firstTrackSelected,
      firstTrackSelectedDesc,
      secondTrackSelected,
      secondTrackSelectedDesc,
    };
  };

  // const handleChooseCourse = () => {
  //   const selectedPeriodResults = selectedIdCourses
  //     .map((id) => {
  //       const selectedRow = courseList.find((row) => row.subjectCode === id);
  //       if (selectedRow) {
  //         return {
  //           subjectCode: id,
  //           sks: selectedRow.sks,
  //           periode: selectedValues[selectedRow.subjectCode] || "",
  //         };
  //       }
  //       return null;
  //     })
  //     .filter(Boolean);

  //   setSelectedCourses(selectedPeriodResults);
  //   setOpenChooseCourseModal(false);
  // };

  // const handleChooseMinor = () => {
  //   setOpenChooseMinorModal(false);
  // };

  const handleDelete = (track: string) => {
    const trackToStateSetter = {
      FE: setPriorityFreeElective,
      MC: setPriorityIndependentStudy,
      MP: setPriorityMinorTechnopreneur,
      MA: setPriorityMinorAI,
      EN: setPriorityEntrepreneurship,
      IN: setPriorityInternship,
    };
  
    if (trackToStateSetter[track]) {
      trackToStateSetter[track]("");
    }
  };

  const handleSubmit = () => {
    setLoadSubmit(true)
    submitEnrichment.mutate({
      id: enrichmentList?.id,
      binusianId: profile.binusianId,
      externalSystemId: profile.externalSystemId,
      firstTrack: firstTrackSelected,
      firstTrackDesc: firstTrackSelectedDesc,
      secondTrack: secondTrackSelected,
      secondTrackDesc: secondTrackSelectedDesc,
      // course: selectedCourses,
      // minorFirstPriority:
      //   priorityMinorTechnopreneur === "1"
      //     ? "Minor Technopreneur"
      //     : priorityMinorAI === "1"
      //     ? "Minor Cyber Security"
      //     : "",
      // minorSecondPriority:
      //   priorityMinorTechnopreneur === "2"
      //     ? "Minor Technopreneur"
      //     : priorityMinorAI === "2"
      //     ? "Minor Cyber Security"
      //     : "",
      action: "submit",
    });
  };

  const handleConfirm = () => {
    showModal({
      title: "Alert",
      message: `Are you sure you want to confirm this data? Once confirmed, the data cannot be changed.`,
      options: {
        buttonTitle: "Ok",
        buttonTitle2: "Cancel",
        variant: "info",
        onOk() {
          setLoadConfirm(true);
          confirmEnrichment.mutate({
            id: enrichmentList?.id,
            binusianId: profile.binusianId,
            externalSystemId: profile.externalSystemId,
            action: "confirm",
          });
        },
      },
    });
  };

  const handleRevise = () => {
    setActiveStep(0)
  };

  useEffect(() => {
    getFirstAndSecondPriority();
  }, [
    priorityFreeElective,
    priorityIndependentStudy,
    priorityMinorTechnopreneur,
    priorityMinorAI,
    priorityEntrepreneurship,
    priorityInternship
    // priorityFurtherStudy,
    // priorityResearch,
    // priorityCommunityDevelopment,
  ]);

  // useEffect(() => {
  //   if (activeStep === 1) {
  //     getEnrichmentList.refetch();
  //   }
  // }, [activeStep]);

  useEffect(() => {
    if (doGetMinorList) {
      getMinorList.refetch();
    }
  }, [selectedMinorCode]);

  return (
    <>
      <Container maxWidth={false}>
        <Stack mt={2} spacing={2}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
              Enrichment
            </Link>
            <Typography fontSize={14} color="#0097DA">
              {activeStep === 0
                ? "Choose Enrichment"
                : activeStep === 1
                ? "Confirmation"
                : activeStep === 2
                ? "Approved Track"
                : "Summary"}
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Box sx={{ width: "100%" }} mt={2}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step
                key={label}
                completed={completed[index]}
              >
                <StepLabel>{label}</StepLabel>
                {/* <StepButton
                  color="inherit"
                  onClick={() => setActiveStep(index)}
                >
                  {label}
                </StepButton> */}
              </Step>
            ))}
          </Stepper>
        </Box>

        {(activeStep === 0 || activeStep === 1 || activeStep === 2) && (
          <>
            <Grid container spacing={2} mt={1}>
            {enrichmentList ? (
            <>
              <Grid item xs={6}>
                <TextComp
                  title="Semester / Term"
                  bodyText={enrichmentList?.termDesc}
                />
                <TextComp
                  title="NIM / Student Id"
                  bodyText={enrichmentList?.externalSystemId}
                />
                <TextComp
                  title="Nama Lengkap /  Full Name"
                  bodyText={enrichmentList?.name}
                />
                <TextComp title="Enrichment Period" bodyText={enrichmentList?.enrichmentPeriode} />
                {activeStep === 2 && (
                  <TextComp
                    title="Tanggal Registrasi / Registration Date"
                    bodyText={formatDate(enrichmentList.submitDate) ?? "-"}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <TextComp
                  title="Jenjang Pendidikan / Academic Career"
                  bodyText={enrichmentList?.acadCareerDesc}
                />
                <TextComp
                  title="Jurusan / Academic Program"
                  bodyText={enrichmentList?.acadProgDesc}
                />
                <TextComp
                  title="Periode Masuk /  Admit Term"
                  bodyText={enrichmentList?.admitTermDesc}
                />
                {activeStep === 2 && (
                  <>
                    <TextComp
                      title="Approved Date"
                      bodyText={
                        enrichmentList?.approvalDate !== null
                          ? formatDate(enrichmentList?.approvalDate)
                          : "Waiting for approval"
                      }
                    />
                    <TextComp
                      title="Approved By"
                      bodyText={
                        enrichmentList?.approvalBy !== null
                          ? enrichmentList?.approvalBy
                          : "Waiting for approval"
                      }
                    />
                  </>
                )}
              </Grid>
            </>
            ) : (
              <>
                <Grid item xs={5} mb={3}>
                  <Stack>
                    <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                    <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                    <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  </Stack>
                </Grid>
                <Grid item xs={7} mb={3}>
                  <Stack>
                      <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                      <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                      <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                      <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                      <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                      <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  </Stack>
                </Grid>
              </>
            ) }
            </Grid>
            <Divider />
          </>
        )}

        {activeStep === 0 && (
          <Box mt={2}>
            {enrichmentList ? (
              <>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{ fontSize: "16px", fontWeight: 600 }}
                >
                  Choose Enrichment for {enrichmentList?.termDesc}
                </Typography>
                {enrichmentList.track.map((list) => (
                  <>
                    <Accordion
                      elevation={0}
                      sx={{ backgroundColor: "transparent" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Stack direction="row" spacing={2}>
                          <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                            {list.name}
                          </Typography>
                          {list.status === "Eligible" && (
                            <Chip
                              size="small"
                              label={list.status}
                              color="warning"
                            />
                          )}
                          {list.status === "Not Eligible" && (
                            <Chip
                              size="small"
                              label={list.status}
                              color="secondary"
                            />
                          )}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Card
                          sx={{
                            backgroundColor: "#666666",
                            minWidth: 275,
                            color: "white",
                            marginBottom: "16px",
                          }}
                        >
                          <CardContent>
                            <Stack direction="row" spacing={2}>
                              <InfoIcon fontSize="small" />
                              <Typography
                                sx={{ fontSize: "12px", fontWeight: "700" }}
                              >
                                Information
                              </Typography>
                            </Stack>
                            <Typography
                              mt={2}
                              sx={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              {list.information}
                            </Typography>
                          </CardContent>
                        </Card>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell align="right">
                                  {list.status === "Eligible" && (
                                    <Chip label={list.status} color="warning" />
                                  )}
                                  {list.status === "Not Eligible" && (
                                    <Chip label={list.status} color="secondary" />
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {list.detail.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.desc}</TableCell>
                                  <TableCell align="right">
                                    <Checkbox
                                      checked={row.isChecked}
                                      inputProps={{ "aria-label": "controlled" }}
                                      size="small"
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
    
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mt={2}
                      mb={2}
                    >
                      {list.name === "Free Elective" && (
                      <Stack direction="row" spacing={2}>
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-free-elective">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-free-elective"
                            id="priority-free-elective"
                            value={priorityFreeElective}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) =>
                              setPriorityFreeElective(e.target.value)
                            }
                          >
                            {list.priority.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={isOptionDisabled(item.id)}
                              >
                                {item.desc}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {priorityFreeElective && (
                          <IconButton aria-label="delete" onClick={() => handleDelete(list.code)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                      )}
                      {list.name === "Certified Specific Independent Study" && (
                      <Stack direction="row" spacing={2}>
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-spesific-independent-study">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-spesific-independent-study"
                            id="priority-spesific-independent-study"
                            value={priorityIndependentStudy}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) =>
                              setPriorityIndependentStudy(e.target.value)
                            }
                          >
                            {list.priority.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={isOptionDisabled(item.id)}
                              >
                                {item.desc}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {priorityIndependentStudy && (
                          <IconButton aria-label="delete" onClick={() => handleDelete(list.code)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                      )}
                      {list.name === "Minor Technopreneurship" && (
                      <Stack direction="row" spacing={2}>
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-minor-techno">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-minor-techno"
                            id="priority-minor-techno"
                            value={priorityMinorTechnopreneur}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) => setPriorityMinorTechnopreneur(e.target.value)}
                          >
                            {list.priority.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={isOptionDisabled(item.id)}
                              >
                                {item.desc}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {priorityMinorTechnopreneur && (
                          <IconButton aria-label="delete" onClick={() => handleDelete(list.code)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                      )}
                      {list.name === "Artificial Intelligence in Business" && (
                      <Stack direction="row" spacing={2}>
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-minor-ai">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-minor-ai"
                            id="priority-minor-ai"
                            value={priorityMinorAI}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) => setPriorityMinorAI(e.target.value)}
                          >
                            {list.priority.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={isOptionDisabled(item.id)}
                              >
                                {item.desc}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {priorityMinorAI && (
                          <IconButton aria-label="delete" onClick={() => handleDelete(list.code)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                      )}
                      {list.name === "Certified Entrepreneurship" && (
                      <Stack direction="row" spacing={2}>
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-entrepreneurship">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-entrepreneurship"
                            id="priority-entrepreneurship"
                            value={priorityEntrepreneurship}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) => setPriorityEntrepreneurship(e.target.value)}
                          >
                            {list.priority.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={isOptionDisabled(item.id)}
                              >
                                {item.desc}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {priorityEntrepreneurship && (
                          <IconButton aria-label="delete" onClick={() => handleDelete(list.code)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                      )}
                      {list.name === "Certified Internship" && (
                      <Stack direction="row" spacing={2}>
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-internship">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-internship"
                            id="priority-internship"
                            value={priorityInternship}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) => setPriorityInternship(e.target.value)}
                          >
                            {list.priority.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={isOptionDisabled(item.id)}
                              >
                                {item.desc}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {priorityInternship && (
                          <IconButton aria-label="delete" onClick={() => handleDelete(list.code)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                      )}
                      {/* {list.name === "Further Study" && (
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-further-study">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-further-study"
                            id="priority-further-study"
                            value={priorityFurtherStudy}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) =>
                              setPriorityFurtherStudy(e.target.value)
                            }
                          >
                            {dataPriority.map((item) => (
                              <MenuItem
                                key={item.priorityId}
                                value={item.priorityId}
                              >
                                {item.priorityName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {list.name === "Research" && (
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-research">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-research"
                            id="priority-research"
                            value={priorityResearch}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) => setPriorityResearch(e.target.value)}
                          >
                            {dataPriority.map((item) => (
                              <MenuItem
                                key={item.priorityId}
                                value={item.priorityId}
                              >
                                {item.priorityName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {list.name === "Community Development" && (
                        <FormControl
                          size="small"
                          sx={{ minWidth: "368px" }}
                          disabled={list.status === "Not Eligible" && true}
                        >
                          <InputLabel id="priority-community-development">
                            {list.status === "Not Eligible"
                              ? "Not Eligible"
                              : "Priority"}
                          </InputLabel>
                          <Select
                            labelId="priority-community-development"
                            id="priority-community-development"
                            value={priorityCommunityDevelopment}
                            label={
                              list.status === "Not Eligible"
                                ? "Not Eligible"
                                : "Priority"
                            }
                            onChange={(e) =>
                              setPriorityCommunityDevelopment(e.target.value)
                            }
                          >
                            {dataPriority.map((item) => (
                              <MenuItem
                                key={item.priorityId}
                                value={item.priorityId}
                              >
                                {item.priorityName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )} */}
                      {/* {list.name === "Free Elective" &&
                        list.status === "Eligible" && (
                          <Button
                            variant="contained"
                            onClick={() => setOpenChooseCourseModal(true)}
                          >
                            Choose Course
                          </Button>
                        )} */}
                      {/* {list.name === "Minor" && list.status === "Eligible" && (
                        <Button
                          variant="contained"
                          onClick={() => setOpenChooseMinorModal(true)}
                        >
                          Choose Minor
                        </Button>
                      )} */}
                      {(list.name === "Minor Technopreneurship" || list.name === "Artificial Intelligence in Business") && (
                        <Button
                          disabled={list.status === "Not Eligible" && true}
                          variant="contained"
                          onClick={() => {
                            setOpenViewCourseModal(true)
                            setDoGetMinorList(true)
                            setSelectedMinorName(list.name)
                            setSelectedMinorCode(list.code)
                          }}
                        >
                          View Course
                        </Button>
                      )}
                    </Stack>
                    <Divider />
                  </>
                ))}
              </>
            ) : (
              <Stack spacing={1}>
                <Skeleton variant="rounded" height={100} />
                <Skeleton variant="rounded" height={100} />
                <Skeleton variant="rounded" height={100} />
                <Skeleton variant="rounded" height={100} />
                <Skeleton variant="rounded" height={100} />
              </Stack>
            )}

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing="2"
              mt={2}
            >
              <LoadingButton
                variant="contained"
                disabled={firstTrackSelected === "" || secondTrackSelected === ""}
                loading={loadSubmit}
                onClick={handleSubmit}
              >
                <span>Submit</span>
              </LoadingButton>
            </Stack>
          </Box>
        )}

        {activeStep === 1 && (
          <Box>
            <Typography
              gutterBottom
              mt={2}
              component="div"
              sx={{ fontSize: "16px", fontWeight: 600 }}
            >
              Konfirmasi Pilihan Enrichment Anda (Confirm Your Enrichment
              Choices)
            </Typography>

            {enrichmentList ? (
            <>
              <Stack direction="column" spacing={1} mt={2} mb={2}>
              <Typography color="secondary" sx={{ fontSize: "12px" }}>
                Prioritas 1 / Priority 1
              </Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {enrichmentList?.firstTrackDesc}
              </Typography>
              {/* {enrichmentList?.firstTrackDesc === "Free Elective" && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography>Periode 1:</Typography>
                      {enrichmentList?.freeElective?.periode1.map((data) => (
                        <Typography>{data.subjectDesc}</Typography>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography>Periode 2:</Typography>
                      {enrichmentList?.freeElective?.periode2.map((data) => (
                        <Typography>{data.subjectDesc}</Typography>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              )} */}
              {/* {enrichmentList?.firstTrackDesc === "Minor" && (
                <Stack direction="column" spacing={2}>
                  <Typography>
                    Priority 1: {enrichmentList?.minor.priority1}
                  </Typography>
                  <Typography>
                    Priority 2 : {enrichmentList?.minor.priority2}
                  </Typography>
                </Stack>
              )} */}
              </Stack>
              <Divider />

              <Stack direction="column" spacing={1} mt={2} mb={2}>
                <Typography color="secondary" sx={{ fontSize: "12px" }}>
                  Prioritas 2 / Priority 2
                </Typography>
                <Typography sx={{ fontWeight: 700 }}>
                  {enrichmentList?.secondTrackDesc}
                </Typography>
                {/* {enrichmentList?.secondTrackDesc === "Free Elective" && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box>
                        <Typography>Periode 1:</Typography>
                        <Typography>Algorithma dan Pemrograman</Typography>
                        <Typography>Matematika</Typography>
                        <Typography>Struktur Data</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <Typography>Periode 2:</Typography>
                        <Typography>Algorithma dan Pemrograman</Typography>
                        <Typography>Matematika</Typography>
                        <Typography>Struktur Data</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )} */}
                {/* {enrichmentList?.secondTrackDesc === "Minor" && (
                  <Stack direction="column" spacing={2}>
                    <Typography>
                      Priority 1: {enrichmentList?.minor.priority1}
                    </Typography>
                    <Typography>
                      Priority 2 : {enrichmentList?.minor.priority2}
                    </Typography>
                  </Stack>
                )} */}
              </Stack>
              <Divider />
            </>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing="2"
              mt={2}
              mb={2}
            >
              {enrichmentList?.checkStatus !== "Submitted Track" ? (
                <Box>
                <Button
                  variant="contained"
                  onClick={() => setActiveStep(2)}
                  endIcon={<ArrowForwardIcon />}
                >
                  Next
                </Button>
              </Box>
              ) : (
                <>
                  <Box>
                    <Button
                      sx={{ margin: "10px" }}
                      variant="contained"
                      color="warning"
                      onClick={handleRevise}
                    >
                      Revise
                    </Button>
                  </Box>
                  <Box>
                    <LoadingButton
                      variant="contained"
                      loading={loadConfirm}
                      onClick={handleConfirm}
                    >
                      <span>Confirm</span>
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Stack>
          </Box>
        )}

        {activeStep === 2 && (
          <Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <TextComp
                  title="Hasil Enrichment Anda / Your Enrichment Result"
                  bodyText={(enrichmentList?.approvalBy === null && enrichmentList?.approvalDate === null) || 
                    (enrichmentList?.approvalBy === "" && enrichmentList?.approvalDate === "") ? 
                    "-" : 
                    enrichmentList?.summaryTrackDesc
                  }
                />
              </Grid>
            </Grid>

            {((enrichmentList?.approvalBy === null && enrichmentList?.approvalDate === null) ||
            (enrichmentList?.approvalBy === "" && enrichmentList?.approvalDate === "")) && (
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ height: "50vh" }}
            >
              {enrichmentList ? (
              <>
                <Box
                  component="img"
                  src="/assets/image/ilustrasi-no-course-progress.svg"
                  sx={{ width: "208px", height: "208px" }}
                />
                <Box>
                  <Typography>
                    Silahkan menunggu informasi lebih lanjut mengenai enrichment
                    program Anda
                  </Typography>
                </Box>
              </>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            )}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing="2"
              mt={2}
              mb={2}
            >
              <Box>
                <Button
                  sx={{ margin: "10px" }}
                  variant="contained"
                  color="warning"
                  onClick={() => setActiveStep(1)}
                  startIcon={<ArrowBackIcon />}
                >
                  Prev
                </Button>
              </Box>
              <Box>
                <Button
                  disabled={enrichmentList?.approvalBy === null && enrichmentList?.approvalDate === null}
                  variant="contained"
                  onClick={() => setActiveStep(3)}
                  endIcon={<ArrowForwardIcon />}
                >
                  Next
                </Button>
              </Box>
            </Stack>
          </Box>
        )}

        {activeStep === 3 && (
          <Box>
            <Grid container spacing={2} mt={1}>
            {enrichmentList ? (
              <Grid item xs={4}>
                <TextComp
                  title="Track Enrichment Anda / Your Enrichment Track"
                  bodyText={enrichmentList.summaryTrackDesc}
                />
                <TextComp
                  title="Tanggal Mulai / Start Date"
                  bodyText={enrichmentList.startDate || "-"}
                />
                <TextComp
                  title="Tanggal Selesai / End Date"
                  bodyText={enrichmentList.endDate || "-"}
                />
              </Grid>
            ) : (
              <Grid item xs={4} mb={2}>
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
              </Grid>
            )}
            </Grid>
            <Divider />
            {enrichmentList ? (
              <Stack spacing={2} mt={2}>
                <Typography>
                  {enrichmentList.summary}{" "}
                </Typography>
              </Stack>
            ) : (
              <Stack spacing={2} mt={2}>
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="rounded" height={100} />
              </Stack>
            )}

            {activeStep === 3 && (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing="2"
                mt={2}
                mb={2}
              >
                <Box>
                  <Button
                    sx={{ margin: "10px" }}
                    variant="contained"
                    color="warning"
                    onClick={() => setActiveStep(2)}
                    startIcon={<ArrowBackIcon />}
                  >
                    Prev
                  </Button>
                </Box>
              </Stack>
            )}
          </Box>
        )}
      </Container>
      {/* <Dialog
        open={openChooseCourseModal}
        onClose={() => {
          setOpenChooseCourseModal(false);
        }}
        fullWidth
        maxWidth="md"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Choose Subject - Free Elective
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenChooseCourseModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <CustomDataGrid
            columnsData={columnsChooseCourse}
            rowsData={courseList}
            rowCount={totalItems}
            getRowId={(row) => row.subjectCode}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
            checkboxSelection
            hideFooterSelectedRowCount
            rowSelectionModel={selectedIdCourses}
            onRowSelectionModelChange={(newRowSelectionMode) => {
              setSelectedIdCourses(newRowSelectionMode);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleChooseCourse}>
            Choose
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openChooseMinorModal}
        onClose={() => {
          setOpenChooseMinorModal(false);
        }}
        fullWidth
        maxWidth="md"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Choose Minor - Minor
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenChooseMinorModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {minorList.map((minor) => (
            <>
              <Accordion
                key={minor.minor}
                elevation={0}
                sx={{ backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  {minor.minor}
                </AccordionSummary>
                <AccordionDetails>
                  <Card
                    sx={{
                      backgroundColor: "#666666",
                      minWidth: 275,
                      color: "white",
                      marginBottom: "16px",
                    }}
                  >
                    <CardContent>
                      <Stack direction="row" spacing={2}>
                        <InfoIcon fontSize="small" />
                        <Typography
                          sx={{ fontSize: "12px", fontWeight: "700" }}
                        >
                          Information
                        </Typography>
                      </Stack>
                      <Typography
                        mt={2}
                        sx={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        {minor.information}
                      </Typography>
                    </CardContent>
                  </Card>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Subject Code</TableCell>
                          <TableCell>Subject</TableCell>
                          <TableCell>SKS</TableCell>
                          <TableCell>Period</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {minor.minorList.map((list) => (
                          <TableRow key={list.subjectCode}>
                            <TableCell>{list.subjectCode}</TableCell>
                            <TableCell>{list.subjectDesc}</TableCell>
                            <TableCell>{list.sks}</TableCell>
                            <TableCell>
                              {list.periode1 === true
                                ? "1"
                                : list.periode2 === true
                                ? "2"
                                : ""}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              {minor.minor === "Minor Technopreneur" && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                  mt={2}
                  mb={2}
                >
                  <FormControl size="small" sx={{ minWidth: "368px" }}>
                    <InputLabel id={`priority-${minor.minor}`}>
                      Priority
                    </InputLabel>
                    <Select
                      labelId={`priority-${minor.minor}`}
                      id={`priority-${minor.minor}`}
                      value={priorityMinorTechnopreneur}
                      label="Priority"
                      onChange={(e) =>
                        setPriorityMinorTechnopreneur(e.target.value)
                      }
                    >
                      {dataPriority.map((item) => (
                        <MenuItem key={item.priorityId} value={item.priorityId}>
                          {item.priorityName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}
              {minor.minor === "Minor Cyber Security" && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                  mt={2}
                  mb={2}
                >
                  <FormControl size="small" sx={{ minWidth: "368px" }}>
                    <InputLabel id={`priority-${minor.minor}`}>
                      Priority
                    </InputLabel>
                    <Select
                      labelId={`priority-${minor.minor}`}
                      id={`priority-${minor.minor}`}
                      value={priorityMinorAI}
                      label="Priority"
                      onChange={(e) => setPriorityMinorAI(e.target.value)}
                    >
                      {dataPriority.map((item) => (
                        <MenuItem key={item.priorityId} value={item.priorityId}>
                          {item.priorityName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleChooseMinor}>
            Choose
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openViewCourseModal}
        onClose={() => {
          setOpenViewCourseModal(false);
        }}
        fullWidth
        maxWidth="md"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          View Course - {selectedMinorName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenViewCourseModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Card
            sx={{
              backgroundColor: "#666666",
              minWidth: 275,
              color: "white",
              marginBottom: "16px",
            }}
          >
            <CardContent>
              <Stack direction="row" spacing={2}>
                <InfoIcon fontSize="small" />
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "700" }}
                >
                  Information
                </Typography>
              </Stack>
              <Typography
                mt={2}
                sx={{ fontSize: "12px", fontWeight: "400" }}
              >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit dolore aperiam molestias pariatur id velit consequatur voluptatibus tenetur dolorum quam at fuga, illo vero, ex repudiandae quas expedita officia vel.
              </Typography>
            </CardContent>
          </Card>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Subject Code</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>SKS</TableCell>
                  <TableCell>Period</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {minorList.length > 0 ? (
                minorList.map((list) => (
                  <TableRow key={list.subjectCode}>
                    <TableCell>{list.subjectCode}</TableCell>
                    <TableCell>{list.subjectDesc}</TableCell>
                    <TableCell>{list.sks}</TableCell>
                    <TableCell>
                      {list.periode1 === true
                        ? "1"
                        : list.periode2 === true
                        ? "2"
                        : ""}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    No rows available
                  </TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => {
            setOpenViewCourseModal(false)
          }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
