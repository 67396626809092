import { SxStyle } from "../../types/style";

// LAYOUT

const headerActive: SxStyle = {
  borderBottom: "2px solid #F29B2B",
  button: {
    padding: "0",
    color: "#F29B2B",
  },
  h6: { padding: "0", color: "#F29B2B", marginBottom: "0px" },
};

const headerInactive: SxStyle = {
  button: {
    padding: "0",
    color: "#333333",
  },
  h6: { padding: "0", color: "#333333", marginBottom: "0px" },
};

const textAreaStyle: SxStyle = {
  border: "1px solid #BDBDBD",
  borderRadius: "20px",
};

const searchBar: SxStyle = {
  position: "relative",
};

const iconSearch: SxStyle = {
  position: "absolute",
  right: 6,
  top: 10,
};

const inputSearch: SxStyle = {
  width: "281px",
};

const modalUploadStyle: SxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "6px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  
};
export const StudentRegCss = {
  headerActive,
  headerInactive,
  textAreaStyle,
  searchBar,
  iconSearch,
  inputSearch,
  modalUploadStyle,
};
