import { Backdrop, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectAuth } from "../../../store/auth/selector";
import { selectProfilePrivileges } from "../../../store/profile/selector";
import { layoutPrivateStyle } from "../../../styles/layout/private-routes";

import { Header } from "./header";
import { SideMenuPrivateRoute } from "../side-menu";
import { SideMenuAdmin } from "./sidemenu-admin";

import { store } from "../../../store";

export function LayoutPrivateRoute({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  console.log({ store: store.getState });
  const listPrivileges = useSelector(selectProfilePrivileges);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [adminMenu, setAdminMenu] = useState(false);

  const location = useLocation();
  const auth = useSelector(selectAuth);
  const [activeMenu, setActiveMenu] = useState("/dashboard/editor");

  // if (!auth.user) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  //   if (listPrivileges?.length === 0) {
  //     return <Navigate to="/" state={{ from: location }} replace />;
  //   }

  return (
    <Box component="main" sx={layoutPrivateStyle.privateRoutes}>
      <Header mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      <Stack direction="row" sx={layoutPrivateStyle.privateRoutesSideMenu}>
        <Box
          sx={{
            background: "#0097DA",
            display: "flex",
            flexDirection: "column",
            height: "auto", // Let the sidebar grow with the content
            minHeight: "100vh", // Ensures it covers the full viewport
          }}
        >
          <SideMenuAdmin
            adminMenu={adminMenu}
            setAdminMenu={setAdminMenu}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        </Box>

        <Box sx={{ width: "100%" }}>{children}</Box>
      </Stack>
    </Box>
  );
}

export default LayoutPrivateRoute;
