import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { visuallyHidden } from '@mui/utils';
import axios from "axios";
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup'
import { breadCrumbLink } from "../../../styles/common/bread-crumb-link";
import { EyeIcon, InfoCircleBold, PasswordBold } from "../../../assets/icon-apps";
import { firstTableData, secondTableData, firstTableColumns, secondTableColumns } from './data';
import { RemoveRedEyeRounded } from "@mui/icons-material";
import { AssesmentDetail } from "../../../components/dialog/assesment-evaluation/assesment/AssesmentDetail";
import { HeadCell, Order } from "../../../components/table/types";
import EnhancedTableHeadCheckbox from "../../../components/table/enhance-table-head-checbox";
import { getComparator, stableSort } from "../../../components/table/utils";
import { AssesmentApprovalData, AssesmentHeadCells } from "../../../types/assesment-evaluation-approval/assesment/assesment-type";
import { acadCarrer } from "../../../store/dropdown/acad-carrer/action/dropdown.action";
import { AppDispatch, RootState } from "../../../store";
import { institution } from "../../../store/dropdown/institution/action/dropdown.action";
import { term } from "../../../store/dropdown/term/action/dropdown.action";
import { campus } from "../../../store/dropdown/campus/action/dropdown.action";
import { acadProg } from "../../../store/dropdown/acad-prog/action/dropdown.action";
import { acadGroup } from "../../../store/dropdown/acad-group/action/dropdown.action";
import { track } from "../../../store/dropdown/track/action/dropdown.action";

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

export function AssesmentApproval() {
  // const dispatch = useDispatch();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const postProjectSchema = yup.object().shape({
    institution: yup.string(),
    acadCarrer: yup.string(),
    term: yup.string(),
    campus: yup.string(),
    acadGroup: yup.string(),
    acadProg: yup.string(),
    track: yup.string(),
  });

  const {
    setValue,
    handleSubmit,
    trigger,
    getValues,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(postProjectSchema) });

  const [userType, setUserType] = useState<any>('');
  const [transaction, setTransaction] = useState<any>('');
  const [rows, setRows] = useState<any[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof AssesmentApprovalData>('id');
  const [selected, setSelected] = useState<string[]>([]);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [pages, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [searchInput, setSearchInput] = useState<any>('');

  // Drop Down variable
  const institutionDropdown = useSelector((state: RootState) => state.dropdownInstitution);
  const acadCarrerDropdown = useSelector((state: RootState) => state.dropdownAcadCarrer);
  const termDropdown = useSelector((state: RootState) => state.dropdownTerm);
  const campusDropdown = useSelector((state: RootState) => state.dropdownCampus);
  const acadGroupDropdown = useSelector((state: RootState) => state.dropdownAcadGroup);
  const acadProgDropdown = useSelector((state: RootState) => state.dropdownAcadProg);
  const trackDropdown = useSelector((state: RootState) => state.dropdownTrack);


  useEffect(() => {
    dispatch(institution());
    dispatch(acadCarrer());    
    dispatch(term());    
    dispatch(campus());  
    dispatch(acadGroup());  
    dispatch(acadProg());  
    dispatch(track());  
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      // Assuming fetching data is completed
      setRows(secondTableData); // Update rows with fetched data
      setTotalRows(secondTableData.length); // Update totalRows with fetched data length
      setLoading(false);
    }, 1000); // Simulated loading delay
  }, []);

  // Function to handle table sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id.toString());
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    switch (selectedIndex) {
      case -1:
        newSelected = [...selected, name];
        break;
      case 0:
        newSelected = [...selected.slice(1)];
        break;
      case selected.length - 1:
        newSelected = [...selected.slice(0, -1)];
        break;
      default:
        if (selectedIndex > 0) {
          newSelected = [
            ...selected.slice(0, selectedIndex),
            ...selected.slice(selectedIndex + 1),
          ];
        }
    }

    setSelected(newSelected);
  };

  // Function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleDetailClick = (id: string) => () => {
    navigate(`entry?${id}`);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - pages * rowsPerPage);

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Assesment & Evaluation Approval
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Assesment
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Assesment
      </Typography>


      {/* DDL 1 */}
      <Grid container columnSpacing={1} sx={{ mb: -7 }}>
        <Grid item md={6}>
          <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
            Institution :
          </Typography>
          <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
            <InputLabel id="user-type-label">Institution</InputLabel>
            <Controller
              name="institution"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="institution-label"
                  id="institution"
                  placeholder="Select Institution"
                  fullWidth
                  value={userType}
                  onChange={(e) => {
                    setUserType(e.target.value as string);
                    field.onChange(e);
                  }}
                >
                  <MenuItem value="">
                    <em>All Institution</em>
                  </MenuItem>
                  {institutionDropdown.data.map((item) => (
                    <MenuItem key={item.Id} value={item.Id}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
            Academic Carrer:
          </Typography>
          <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
            <InputLabel id="academic-carrer-label">Academic Carrer</InputLabel>
            <Select
              labelId="academic-carrer-label"
              id="academic-carrer"
              placeholder="Select Academic Carrer"
              fullWidth
              value={transaction}
              onChange={(e) => setTransaction(e.target.value)}
            >
              <MenuItem value="">
                <em>All Academic Carrer</em>
              </MenuItem>
              {acadCarrerDropdown.data.map((item) => (
                <MenuItem key={item.Id} value={item.Id}>
                  {item.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* DDL 2 */}
      <Grid container columnSpacing={1} sx={{ mb: -7 }}>
        <Grid item md={6}>
          <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
            Term :
          </Typography>
          <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
            <InputLabel id="user-type-label">Term</InputLabel>
            <Controller
              name="term"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="term-label"
                  id="term"
                  placeholder="Select Term"
                  fullWidth
                  value={userType}
                  onChange={(e) => {
                    setUserType(e.target.value as string);
                    field.onChange(e);
                  }}
                >
                  <MenuItem value="">
                    <em>All Term</em>
                  </MenuItem>
                  {termDropdown.data.map((item) => (
                    <MenuItem key={item.Id} value={item.Id}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
            Campus :
          </Typography>
          <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
            <InputLabel id="user-type-label">Campus</InputLabel>
            <Controller
              name="campus"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="campus-label"
                  id="campus"
                  placeholder="Select Campus"
                  fullWidth
                  value={userType}
                  onChange={(e) => {
                    setUserType(e.target.value as string);
                    field.onChange(e);
                  }}
                >
                  <MenuItem value="">
                    <em>All Campus</em>
                  </MenuItem>
                  {campusDropdown.data.map((item) => (
                    <MenuItem key={item.Id} value={item.Id}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* DDL 3 */}
      <Grid container columnSpacing={1} sx={{ mb: -7 }}>
        <Grid item md={6}>
          <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
            Academic Group :
          </Typography>
          <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
            <InputLabel id="user-type-label">Academic Group</InputLabel>
            <Controller
              name="acadGroup"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="acadGroup-label"
                  id="acadGroup"
                  placeholder="Select Academic Group"
                  fullWidth
                  value={userType}
                  onChange={(e) => {
                    setUserType(e.target.value as string);
                    field.onChange(e);
                  }}
                >
                  <MenuItem value="">
                    <em>All Academic Group</em>
                  </MenuItem>
                  {acadGroupDropdown.data.map((item) => (
                    <MenuItem key={item.Id} value={item.Id}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
            Academic Program :
          </Typography>
          <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
            <InputLabel id="acadProg-label">Academic Program</InputLabel>
            <Controller
              name="acadProg"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="acadProgram-label"
                  id="acadProg"
                  placeholder="Select Academic Program"
                  fullWidth
                  value={userType}
                  onChange={(e) => {
                    setUserType(e.target.value as string);
                    field.onChange(e);
                  }}
                >
                  <MenuItem value="">
                    <em>All Academic Program</em>
                  </MenuItem>
                  {acadProgDropdown.data.map((item) => (
                    <MenuItem key={item.Id} value={item.Id}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* DDL 4 */}
      <Grid container columnSpacing={1} sx={{ mb: -7 }}>
        <Grid item md={6}>
          <Typography variant='body2' color='#4F4F4F' fontWeight={500}>
            Track :
          </Typography>
          <FormControl sx={{ mt: 1, mb: 10 }} size="small" fullWidth>
            <InputLabel id="track-label">Track</InputLabel>
            <Controller
              name="track"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="tracks-label"
                  id="track"
                  placeholder="Select Track"
                  fullWidth
                  value={userType}
                  onChange={(e) => {
                    setUserType(e.target.value as string);
                    field.onChange(e);
                  }}
                >
                  <MenuItem value="">
                    <em>All Track</em>
                  </MenuItem>
                  {trackDropdown.data.map((item) => (
                    <MenuItem key={item.Id} value={item.Id}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Table */}
      <Box mt={3}>
        <Paper elevation={3}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHeadCheckbox
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={AssesmentHeadCells}
              />
              <TableBody>
                {
                  stableSort(rows, getComparator(order, orderBy))
                    .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id.toString());
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id.toString())}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.id}
                          </TableCell>
                          <TableCell align="center">{row.track ?? "-"}</TableCell>
                          <TableCell align="center">{row.corrector ?? "-"}</TableCell>
                          <TableCell align="center">{row.student}</TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={pages}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Approve
          </Button>

          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Assessment Detail</DialogTitle>
            <DialogContent>
              {selected.length > 0 ? (
                <AssesmentDetail myId={selected} onClose={handleClose} />
              ) : (
                <p>No Data Selected</p>
              )}
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions> */}
          </Dialog>
        </Box>
      </Box>

    </Container>
  );
};