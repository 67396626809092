/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import { Box, CircularProgress } from '@mui/material';
import { useRefreshAccessToken } from '../../api/identity';
import { useQueryListUserPrivileges } from '../../api/role-category';
import { useAuth } from '../../hooks/use-auth';
import useModal from '../../hooks/use-modal';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuth } from '../../store/auth/selector';
import { selectAuthToken } from '../../store/authToken/selector';
import { setAuthToken, setRefreshToken } from '../../store/authToken/slice';
// import { selectProfileActiveRole, selectProfilePrivileges } from '../../store/profile/selector';
// import { setPrivileges } from '../../store/profile/slice';

export function AuthProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const auth = useSelector(selectAuth);
  const authToken = useSelector(selectAuthToken);
  // const activeRole = useSelector(selectProfileActiveRole);

  const dispatch = useDispatch();
  // const queryPrivileges = useQueryListUserPrivileges({
  //   options: {
  //     retry: 1,
  //     enabled: false,
  //     onSuccess: (res) => {
  //       dispatch(setPrivileges(res));
  //     },
  //     onError: () => {
  //       dispatch(setPrivileges([]));
  //     },
  //   },
  // });
  const refreshAccessToken = useRefreshAccessToken({
    onSuccess: (data) => {
      setIsLoading(false);
      dispatch(setAuthToken(data.accessToken));
      dispatch(setRefreshToken(data.refreshToken));
      // queryPrivileges.refetch();
    },
    onError: (error) => {
      showModal({
        title: 'Attention!',
        message:
          'Your session has expired. You will be redirected to the login page after closing this message.',
        options: {
          buttonTitle: 'Logout',
          variant: 'info',
          onOk: () => {
            window.location.href = '/logout';
          },
        },
      });
    },
  });
  const fetchNewToken = () => {
    refreshAccessToken.mutate({
      accessToken: authToken.myDashboardToken,
      refreshToken: authToken.refreshToken,
    });
  };

  const { isRefreshExpired, isExpiredInOneMinute } = useAuth();
  const firstLoad = useRef(true);
  useEffect(() => {
    if (firstLoad.current && isExpiredInOneMinute) {
      fetchNewToken();
      return;
    }

    setIsLoading(false);
    firstLoad.current = false;
    // eslint-disable-next-line consistent-return
    return () => {
      refreshAccessToken.reset();
    };
  }, [isRefreshExpired, isExpiredInOneMinute]);

  // useEffect(() => {
  //   queryPrivileges.refetch();

  //   return () => {
  //     queryPrivileges.remove();
  //   };
  // }, [activeRole?.roleId]);

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isLoading || refreshAccessToken.isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="100vh"
      >
        <CircularProgress size={16} />
        <Box ml={1}>Loading...</Box>
      </Box>
    );
  }

  return <>{children}</>;
}
