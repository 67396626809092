import {
  Container,
  Stack,
  Breadcrumbs,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { GridCellParams, GridColDef } from "@mui/x-data-grid";

import useModal from "../../hooks/use-modal";
import { CustomDataGrid } from "../../components/common/datagrid";

import { breadCrumbLink } from "../../styles/common/bread-crumb-link";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  useQueryListInstitutionDropDown,
  useQueryListAcadCareerDropDown,
} from "../../api/dropdown";
import {
  InstitutionDropdownResponseData,
  AcadCareerDropdownResponseData,
} from "../../types/dropdown";
import {
  useMutationEvaluationIndicatorList,
  useMutationAddEvaluationIndicator,
  useMutationDeleteEvaluationIndicator,
  useMutationUpdateEvaluationIndicator,
} from "../../api/setup-enrichment-program/evaluation-indicator";
import { EvaluationIndicatorList } from "../../types/setup-enrichment-program/evaluation-indicator";

export function EvaluationIndicator() {
  const { showModal } = useModal();

  const [dataInstitution, setDataInstitution] = useState<
    InstitutionDropdownResponseData[]
  >([]);
  const [dataAcadCareer, setDataAcadCareer] = useState<
    AcadCareerDropdownResponseData[]
  >([]);
  const [institutionFilter, setInstitutionFilter] = useState("");
  const [acadCareerFilter, setAcadCareerFilter] = useState("");
  const [evaluationIndicatorList, setEvaluationIndicatorList] = useState<
    EvaluationIndicatorList[]
  >([]);

  const [indicatorName, setIndicatorName] = useState("");
  const [editIndicatorName, setEditIndicatorName] = useState("");
  const [indicatorId, setIndicatorId] = useState("");

  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [openAddIndicatorModal, setOpenAddIndicatorModal] = useState(false);
  const [openEditIndicatorModal, setOpenEditIndicatorModal] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No",
      flex: 0.5,
      maxWidth: 100,
      hideable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "desc",
      headerName: "Indicator",
      flex: 1,
      hideable: false,
    },
    {
      field: "",
      headerName: "Action",
      flex: 1,
      maxWidth: 110,
      hideable: false,
      align: "right",
      headerAlign: "center",
      renderCell: (params: GridCellParams) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setIndicatorId(params.row.id);
              setEditIndicatorName(params.row.desc);
              setOpenEditIndicatorModal(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteConfirmation(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataInstitution(res.data);
      },
      onError: () => {
        setDataInstitution([]);
      },
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataAcadCareer(res.data);
      },
      onError: () => {
        setDataAcadCareer([]);
      },
    },
  });

  const getEvaluationIndicatorList = useMutationEvaluationIndicatorList({
    query: {
      search: "",
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page,
      orderBy: "id:asc",
    },
    options: {
      onSuccess: async (res) => {
        setEvaluationIndicatorList(res.data);
        setTotalItems(res.paginationInfo.totalItems);
      },
      onError: async (err) => {
        setEvaluationIndicatorList([]);
      },
    },
  });

  const addEvaluationIndicatorList = useMutationAddEvaluationIndicator({
    onSettled: async (res) => {
      getEvaluationIndicatorList.mutate({
        institution: institutionFilter,
        academicCareer: acadCareerFilter,
      });
    },
    onSuccess: async (res) => {
      setOpenAddIndicatorModal(false);
      setIndicatorName("");

      showModal({
        title: "Success",
        message: "Add evaluation indicator successfully",
        options: {
          variant: "success",
        },
      });
    },
    onError: async (err) => {
      setOpenAddIndicatorModal(false);
      setIndicatorName("");

      showModal({
        title: "Failed",
        message: "Add evaluation indicator is failed.",
        options: {
          variant: "failed",
        },
      });
    },
  });

  const deleteEvaluationIndicator = useMutationDeleteEvaluationIndicator({
    query: {
      id: indicatorId,
    },
    options: {
      onSettled: async (res) => {
        getEvaluationIndicatorList.mutate({
          institution: institutionFilter,
          academicCareer: acadCareerFilter,
        });
      },
      onSuccess: async (res) => {
        showModal({
          title: "Success",
          message: "Delete evaluation indicator successfully.",
          options: {
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        showModal({
          title: "Failed",
          message: "Delete evaluation indicator is failed.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  const updateEvaluationIndicatorList = useMutationUpdateEvaluationIndicator({
    query: {
      id: indicatorId,
    },
    options: {
      onSettled: async (res) => {
        getEvaluationIndicatorList.mutate({
          institution: institutionFilter,
          academicCareer: acadCareerFilter,
        });
      },
      onSuccess: async (res) => {
        setOpenEditIndicatorModal(false);
        setEditIndicatorName("");

        showModal({
          title: "Success",
          message: "Update evaluation indicator successfully",
          options: {
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        setOpenEditIndicatorModal(false);
        setEditIndicatorName("");

        showModal({
          title: "Failed",
          message: "Update evaluation indicator is failed.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  const handleSearch = () => {
    getEvaluationIndicatorList.mutate({
      institution: institutionFilter,
      academicCareer: acadCareerFilter,
    });
  };

  const handleAddIndicator = () => {
    addEvaluationIndicatorList.mutate({
      name: indicatorName,
      institution: institutionFilter,
      acadCareer: acadCareerFilter,
    });
  };

  const handleDeleteConfirmation = (indicator: string) => {
    setIndicatorId(indicator);

    showModal({
      title: "Alert",
      message: "Are you sure you want to permanently delete this indicator?",
      options: {
        buttonTitle: "Delete",
        buttonTitle2: "Cancel",
        variant: "info",
        onOk() {
          deleteEvaluationIndicator.mutate({});
        },
      },
    });
  };

  const handleUpdateIndicator = () => {
    updateEvaluationIndicatorList.mutate({
      name: editIndicatorName,
      institution: institutionFilter,
      acadCareer: acadCareerFilter,
    });
  };

  useEffect(() => {
    getEvaluationIndicatorList.mutate({
      institution: institutionFilter,
      academicCareer: acadCareerFilter,
    });
  }, [paginationModel]);

  return (
    <>
      <Container maxWidth={false}>
        <Stack mt={2} spacing={2}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
              Setup Enrichment Program
            </Link>
            <Typography fontSize={14} color="#0097DA">
              Evaluation Indicator
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Typography variant="h5" gutterBottom mt={2}>
          Evaluation Indicator
        </Typography>
        <Divider />

        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="institution-select">Institution</InputLabel>
              <Select
                labelId="institution-select"
                id="institution"
                value={institutionFilter}
                label="Institution"
                onChange={(e) => setInstitutionFilter(e.target.value)}
              >
                {dataInstitution.map((item) => (
                  <MenuItem key={item.Id} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="acad-career-select">Academic Career</InputLabel>
              <Select
                labelId="acad-career-select"
                id="acad-career"
                value={acadCareerFilter}
                label="Academic Career"
                onChange={(e) => setAcadCareerFilter(e.target.value)}
              >
                {dataAcadCareer.map((item) => (
                  <MenuItem key={item.Id} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid display="flex" justifyContent="end" mt={2} mb={2}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>

        <Divider />

        <Box mt={3}>
          <Grid display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h5" gutterBottom>
              Evaluation Indicator
            </Typography>
            <Button
              disabled={institutionFilter === "" || acadCareerFilter === ""}
              onClick={() => setOpenAddIndicatorModal(true)}
              variant="contained"
              color="primary"
            >
              Add Indicator
            </Button>
          </Grid>

          <Paper elevation={3}>
            <CustomDataGrid
              columnsData={columns}
              rowsData={evaluationIndicatorList}
              rowCount={totalItems}
              getRowId={(row) => row.id}
              setPaginationModel={(value) => {
                setPaginationModel(value);
              }}
              paginationModel={paginationModel}
            />
          </Paper>
        </Box>
      </Container>
      <Dialog
        open={openAddIndicatorModal}
        onClose={() => {
          setOpenAddIndicatorModal(false);
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Add Evaluation Indicator
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenAddIndicatorModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={2}>
            <TextField
              fullWidth
              id="evaluation-indicator-name"
              label="Evaluation Indicator Name"
              variant="outlined"
              size="small"
              value={indicatorName}
              onChange={(event) => {
                setIndicatorName(event.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenAddIndicatorModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddIndicator}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditIndicatorModal}
        onClose={() => {
          setOpenEditIndicatorModal(false);
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ fontWeight: 700 }}>
          Edit Evaluation Indicator
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenEditIndicatorModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={2}>
            <TextField
              fullWidth
              id="evaluation-indicator-name"
              label="Evaluation Indicator Name"
              variant="outlined"
              size="small"
              value={editIndicatorName}
              onChange={(event) => {
                setEditIndicatorName(event.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenEditIndicatorModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateIndicator}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
