import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../../config/api-client";
import { UseMutationOptions, UseQueryOptions } from "../../types/react-query";
import { GetIndividual } from "../../types/student-registration";
import {
  AddNewIndicatorPayload,
  DeleteAssesmentPayload,
  MaxWeightPayload,
  ShowTableAssesmentMappingPayload,
  UpdateIndicatorPayload,
} from "../../types/setup-enrichment-program/assesment-mapping";
import { ApiAsessmentMapping } from "../../constants/setup-enrichment-program/ApiAsessmentMapping";
import { EditIndicator, RoleListDropdownResponse } from "../../types/user-role";

export const useMutationShowTableAssesmentMapping = (
  options?: UseMutationOptions
) =>
  useMutation(
    (data: ShowTableAssesmentMappingPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.showTableAssesmentMapping}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationUpdateIndicator = (options?: UseMutationOptions) =>
  useMutation(
    (data: UpdateIndicatorPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.updateIndicator}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationSetMaxWeight = (options?: UseMutationOptions) =>
  useMutation(
    (data: MaxWeightPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.updateIndicator}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationAddNewIndicator = (options?: UseMutationOptions) =>
  useMutation(
    (data: AddNewIndicatorPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.submitAddNewIndicator}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationDeleteIndicator = (options?: UseMutationOptions) =>
  useMutation(
    (data: DeleteAssesmentPayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.deleteIndicator}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetIndicatorById = ({
  query,
  options,
}: {
  query: { id: string };
  options?: UseQueryOptions<EditIndicator>;
}) =>
  useQuery(
    ["getIndicatorById", query.id],
    () =>
      apiClient
        .get<unknown, { data: EditIndicator }>(
          ApiAsessmentMapping.getIndicatorById(query.id)
        )
        .then((res) => res.data?.data),
    options
  );
