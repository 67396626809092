/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import apiClient from '../../config/api-client';
import { useQuery, useMutation } from '@tanstack/react-query';
import { UseQueryOptions, UseMutationOptions } from '../../types/react-query';
import { ApiEvaluationComponent } from '../../constants/setup-enrichment-program/ApiEvaluationComponent';
import { EvaluationComponentRequest, EvaluationComponentResponse, AddEvaluationComponentRequest, UpdateEvaluationComponentRequest } from '../../types/setup-enrichment-program/evaluation-component';

export const useMutationEvaluationComponentList = ({
  query,
  options,
  }: {
  query: { search?: string; pageSize?: number; pageNumber?: number; orderBy?: string; }
  options?: UseMutationOptions<EvaluationComponentResponse>
  }) =>
  useMutation(
    (data: EvaluationComponentRequest) =>
      apiClient
        .post<unknown, { data: EvaluationComponentResponse }>(`${ApiEvaluationComponent.evaluationComponentList(query.search, query.pageSize, query.pageNumber, query.orderBy)}`, data)
        .then((res) => res.data),
    options
  );

export const useMutationAddEvaluationComponent = (options?: UseMutationOptions) =>
  useMutation(
    (data: AddEvaluationComponentRequest) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEvaluationComponent.addEvaluationComponent}`, data)
        .then((res) => res.data),
    options,
  );

export const useMutationDeleteEvaluationComponent = ({
  query,
  options,
  }: {
  query: { id: string; }
  options?: UseMutationOptions
  }) =>
  useMutation(
    () =>
      apiClient
        .post(`${ApiEvaluationComponent.deleteEvaluationComponent(query.id)}`)
        .then((res) => res.data),
    options
  );

export const useMutationUpdateEvaluationComponent = ({
  query,
  options,
  }: {
  query: { id: string; }
  options?: UseMutationOptions
  }) =>
  useMutation(
    (data: UpdateEvaluationComponentRequest) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEvaluationComponent.updateEvaluationComponent(query.id)}`, data)
        .then((res) => res.data),
    options,
  );