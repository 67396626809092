import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useQueryDropDownGradeStatus,
  useQueryDropDownTransferStatus,
  useQueryListAcadCareerDropDown,
  useQueryListTrackDropDown,
} from "../../api/dropdown";
import {
  useMutationDownloadExcelTransferGrade,
  useMutationTransferBcs,
  useMutationTransferBcsList,
  useQueryExportExcelTransferToBcs,
} from "../../api/setup-enrichment-program/transfer-bcs";
import { useQueryGetTermDropDown } from "../../api/student-registration";
import { CustomDataGrid } from "../../components/common/datagrid";
import useModal from "../../hooks/use-modal";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import {
  DropDownCampusLocationResponseData,
  DropdownResponseData,
  InstitutionDropdownResponseData,
} from "../../types/dropdown";
import { ShowTableUserMappingData } from "../../types/user-mapping";

export function TransferGradeToBcs() {
  const { showModal } = useModal();

  const [dataTable, setDataTable] = useState<ShowTableUserMappingData[]>([]);
  const [track, setTrack] = useState<DropdownResponseData[]>([]);
  const [acadCareer, setAcadCareer] = useState<DropdownResponseData[]>([]);
  const [listTerm, setListTerm] = useState<DropdownResponseData[]>([]);
  const [gradeStatus, setGradeStatus] = useState<DropdownResponseData[]>([]);
  const [transferStatus, setTransferStatus] = useState<DropdownResponseData[]>(
    []
  );
  const [doExportExcel, setDoExportExcel] = useState(false);

  const [totalItems, setTotalItems] = useState(0);

  const [acadCareerValue, setAcadCareerValue] = useState("");
  const [termValue, setTermValue] = useState("");
  const [trackValue, setTrackValue] = useState("");
  const [gradeStatusValue, setGradeStatusValue] = useState("");
  const [transferStatusValue, setTransferStatusValue] = useState("");
  const [selectedId, setSelectedId] = useState<[]>([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const submitShowTable = useMutationTransferBcsList({
    onSuccess: async (res: any) => {
      setDataTable(res.data || []);
    },
    onError: async (error) => {},
  });

  const exportExcel = useQueryExportExcelTransferToBcs({
    query: {
      period: termValue,
      acadcareer: acadCareerValue,
      track: trackValue,
    },
    options: {
      enabled: doExportExcel,
      onSuccess: (res) => {
        setDoExportExcel(false);

        const now = new Date();
        const dateNow = now.toISOString().split("T")[0];

        const blob = new Blob([res], { type: "xlsx" });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Transfer Grade To BCS-${dateNow}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);
      },
      onError: () => {
        setDoExportExcel(false);

        showModal({
          title: "Failed",
          message: "Export excel is failed.",
          options: {
            variant: "failed",
          },
        });
      },
    },
  });

  const submitTransferBcs = useMutationTransferBcs({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Transfer Sucess",
        options: {
          buttonTitle: "Ok",
          variant: "success",
        },
      });
    },
    onError: async (error) => {
      showModal({
        title: "Failed",
        message: "Transfer Failed, Please check the data and try again later",
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const transferBcs = () => {
    submitTransferBcs.mutate({
      id: selectedId,
    });
  };

  const showTable = () => {
    submitShowTable.mutate({
      period: termValue,
      acadCareer: acadCareerValue,
      track: trackValue,
      gradeStatus: gradeStatusValue,
      transferStatus: transferStatusValue,
    });
  };

  const getTrack = useQueryListTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTrack(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setAcadCareer(res.data || []);
      },
      onError: () => {
        setAcadCareer([]);
      },
    },
  });

  const getTerm = useQueryGetTermDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setListTerm(res.data || []);
      },
      onError: () => {},
    },
  });

  const getTransferStatus = useQueryDropDownTransferStatus({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTransferStatus(res.data || []);
      },
      onError: () => {},
    },
  });

  const getGradeStatus = useQueryDropDownGradeStatus({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setGradeStatus(res.data || []);
      },
      onError: () => {},
    },
  });

  const handleSelectionChange = (newSelection) => {
    setSelectedId(newSelection);
    console.log(newSelection, "NEW SELECION");
  };

  const columns: GridColDef[] = [
    {
      field: "term",
      headerName: "Binusian ID",
      flex: 1,
      hideable: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      hideable: false,
    },
    {
      field: "acadCareerDesc",
      headerName: "Acad Career",
      flex: 1,
      hideable: false,
    },
    {
      field: "trackDesc",
      headerName: "Enrichment Track",
      flex: 1,
      hideable: false,
    },
    {
      field: "term",
      headerName: "Period",
      flex: 1,
      hideable: false,
    },
    {
      field: "gradeStatus",
      headerName: "Grade Status",
      flex: 1,
      hideable: false,
    },
    {
      field: "courseDesc",
      headerName: "Course",
      flex: 1,
      hideable: false,
    },
    {
      field: "finalGrade",
      headerName: "Final Grade",
      flex: 1,
      hideable: false,
    },
    {
      field: "transferStatus",
      headerName: "Transfer Status",
      flex: 1,
      hideable: false,
    },
    {
      field: "dateIn",
      headerName: "Transfer To BCS",
      flex: 1,
      hideable: false,
    },
  ];

  // useEffect(() => {
  //   showTable();
  // }, []);

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Setup Enrichment Program
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Transfer Grade To BCS
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Transfer Grade To BCS
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Period
          </Typography>
          <Select
            onChange={(e) => setTermValue(e.target.value as string)}
            fullWidth
          >
            {listTerm.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Acad Career
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setAcadCareerValue(e.target.value as string)}
          >
            {acadCareer.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Track
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setTrackValue(e.target.value as string)}
          >
            {track.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Grade Status
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setGradeStatusValue(e.target.value as string)}
          >
            {gradeStatus.map((item) => (
              <MenuItem value={item.Id}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Transfer Status
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setTransferStatusValue(e.target.value as string)}
          >
            {transferStatus.map((item) => (
              <MenuItem value={item.Id}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button
          sx={{ marginRight: "10px" }}
          onClick={() => showTable()}
          variant="contained"
        >
          Search
        </Button>
        <Button variant="contained" sx={{ background: "#0097DA" }}>
          Transfer Monitoring Report
        </Button>
      </Grid>

      <Divider />

      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button
          sx={{ marginRight: "10px" }}
          onClick={() => transferBcs()}
          variant="contained"
        >
          Transfer
        </Button>
        <Button onClick={() => setDoExportExcel(true)} variant="contained">
          Download To Excel
        </Button>
      </Grid>

      <Box mt={3}>
        <Paper elevation={3}>
          <CustomDataGrid
            checkboxSelection
            columnsData={columns}
            rowsData={dataTable}
            rowCount={totalItems}
            getRowId={(row) => row.id}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
            onRowSelectionModelChange={(newSelection) =>
              handleSelectionChange(newSelection)
            }
            rowSelectionModel={selectedId}
          />
        </Paper>
      </Box>
    </Container>
  );
}
