import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { WidgetList } from 'components/widget/widget-list';
// import { WidgetToolbar } from 'components/widget/widget-toolbar';
import { useState } from "react";
import {
  useMutationAddNewRole,
  useQueryListRoleDropDown,
} from "../../../api/user-management";
import CheckboxListAdd from "../../../components/checkbox/checkbox-list-add";
import useModal from "../../../hooks/use-modal";
import { RoleListDropdownResponseData } from "../../../types/user-role";
import { Link, useNavigate } from "react-router-dom";
import { breadCrumbLink } from "../../../styles/common/bread-crumb-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export function AddNewRole() {
  const navigate = useNavigate();

  const [dataRole, setDataRole] = useState([]);
  const [roleName, setRoleName] = useState("Admin");
  const [status, setStatus] = useState("Active");
  const [listRoleDropDown, setListRoleDropDown] = useState<
    RoleListDropdownResponseData[]
  >([] as RoleListDropdownResponseData[]);

  const { showModal } = useModal();

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handle = (event, values) => {
    setRoleName(values.Id);
  };

  const getDropDownRole = useQueryListRoleDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListRoleDropDown(res.data || []);
      },
      onError: (err) => {
        alert(err);
      },
    },
  });

  const submitAddNewRole = useMutationAddNewRole({
    onSuccess: async (res) => {
      showModal({
        title: "Success",
        message: `Add new role success!`,
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk() {
            navigate("/user-management/user-role");
          },
        },
      });
    },
    onError: async (error) => {
      showModal({
        title: "Failed",
        message: `Add new role is failed.`,
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const handleSubmit = () => {
    submitAddNewRole.mutate({
      roleId: roleName,
      IsActive: status === "Active",
      listRole: dataRole,
    });
  };

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            User Management
          </Link>
          <Link
            style={breadCrumbLink.styleBreadCrumbLink}
            to="/user-management/user-role"
          >
            User Role
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Add New Role
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Add New Role
      </Typography>

      <Divider />

      <Box mb={2}>
        <Typography
          fontSize={16}
          color="#333333"
          variant="h5"
          gutterBottom
          mt={2}
        >
          Role
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          options={listRoleDropDown}
          getOptionLabel={(option) => option.Name || ""}
          sx={{ width: "368px" }}
          renderInput={(params) => <TextField {...params} />}
          onChange={handle}
        />
      </Box>
      <Divider />

      <CheckboxListAdd setData={setDataRole} />

      <Box>
        <Typography
          fontSize={16}
          color="#333333"
          variant="h5"
          gutterBottom
          mt={2}
        >
          Status
        </Typography>
        <FormControl size="small" sx={{ minWidth: "368px" }}>
          <Select
            value={status}
            onChange={handleChange}
            sx={{ width: "368px", marginBottom: "16px" }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Nonactive">Nonactive</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Button
        onClick={() => handleSubmit()}
        variant="contained"
        color="primary"
        fullWidth
      >
        Submit
      </Button>
    </Container>
  );
}
