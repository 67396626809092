import { ApiService, azureFunctionCode } from './ApiService';

export const ApiEnrichmentTrack = {
  getJobStatus: `${ApiService.identity}/GetJobStatus?code=${azureFunctionCode}`,
  getBusinessSector: `${ApiService.identity}/GetBusinessSector?code=${azureFunctionCode}`,
  getPosition: `${ApiService.identity}/GetPosition?code=${azureFunctionCode}`,
  getCountry: `${ApiService.identity}/DropDownCountry?code=${azureFunctionCode}`,
  getProvince: `${ApiService.identity}/DropDownProvince?code=${azureFunctionCode}`,
  getSkillType: `${ApiService.identity}/DropDownSkillType?code=${azureFunctionCode}`,
  profile: (binusianid: string) => `${ApiService.enrichmentTrack}/GetProfile?code=${azureFunctionCode}&binusianid=${binusianid}`,
  submitProfile: `${ApiService.enrichmentTrack}/UpdateProfile?code=${azureFunctionCode}`,
  enrichmentList: (email: string, binusianid: string) => `${ApiService.enrichmentTrack}/GetEnrichmentTrack?code=${azureFunctionCode}&email=${email}&binusianid=${binusianid}`,
  courseList: (acadcareer: string) => `${ApiService.enrichmentTrack}/GetCourse?code=${azureFunctionCode}&acadcareer=${acadcareer}`,
  minorList: (acadcareer: string, track: string) => `${ApiService.enrichmentTrack}/GetMinor?code=${azureFunctionCode}&acadcareer=${acadcareer}&track=${track}`,
  submitEnrichment: `${ApiService.enrichmentTrack}/EnrichmentTrack?code=${azureFunctionCode}`,
  confirmEnrichment: `${ApiService.enrichmentTrack}/EnrichmentTrack?code=${azureFunctionCode}`,
};
