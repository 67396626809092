import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useQueryAcadProg,
  useQueryAssesmentComp,
  useQueryListAcadCareerDropDown,
  useQueryListCampusDropDown,
  useQueryListInstitutionDropDown,
  useQueryListTrackDropDown,
} from "../../api/dropdown";
import {
  useMutationAddNewIndicator,
  useMutationSetMaxWeight,
  useMutationShowTableAssesmentMapping,
  useMutationUpdateIndicator,
} from "../../api/setup-enrichment-program/assesment-mapping";
import { useQueryGetTermDropDown } from "../../api/student-registration";
import { CustomDataGrid } from "../../components/common/datagrid";
import useModal from "../../hooks/use-modal";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import {
  DropDownCampusLocationResponseData,
  DropdownResponseData,
  InstitutionDropdownResponseData,
} from "../../types/dropdown";
import { ShowTableUserMappingData } from "../../types/user-mapping";
import { useMutationShowTableApprovalKey } from "../../api/setup-enrichment-program/send-approval-key";

export function SendApprovalKey() {
  const { showModal } = useModal();

  const [dataTable, setDataTable] = useState<ShowTableUserMappingData[]>([]);
  const [institution, setInstitution] = useState<
    InstitutionDropdownResponseData[]
  >([]);
  const [campus, setCampus] = useState<DropDownCampusLocationResponseData[]>(
    []
  );
  const [track, setTrack] = useState<DropdownResponseData[]>([]);
  const [acadCareer, setAcadCareer] = useState<DropdownResponseData[]>([]);
  const [term, setTerm] = useState<DropdownResponseData[]>([]);

  const [totalItems, setTotalItems] = useState(0);

  const [institutionValue, setInstitutionValue] = useState("");
  const [acadCareerValue, setAcadCareerValue] = useState("");
  const [termValue, setTermValue] = useState("");
  const [campusValue, setCampusValue] = useState("");
  const [trackValue, setTrackValue] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const submitShowTable = useMutationShowTableApprovalKey({
    onSuccess: async (res: any) => {
      setDataTable(res.data || []);
    },
    onError: async (error) => {},
  });

  const showTable = () => {
    submitShowTable.mutate({
      institution: institutionValue,
      campus: campusValue,
      acadProg: acadCareerValue,
      track: trackValue,
      term: termValue,
    });
  };

  const getTrack = useQueryListTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTrack(res.data || []);
      },
      onError: () => {},
    },
  });

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setInstitution(res.data || []);
      },
      onError: () => {},
    },
  });

  const getCampus = useQueryListCampusDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setCampus(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setAcadCareer(res.data || []);
      },
      onError: () => {
        setAcadCareer([]);
      },
    },
  });

  const getTerm = useQueryGetTermDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTerm(res.data || []);
      },
      onError: () => {},
    },
  });

  const columns: GridColDef[] = [
    {
      field: "term",
      headerName: "Term",
      flex: 1,
      hideable: false,
    },
    {
      field: "campusDesc",
      headerName: "Campus",
      flex: 1,
      hideable: false,
    },
    {
      field: "trackDesc",
      headerName: "Track",
      flex: 1,
      hideable: false,
    },
    {
      field: "corrector",
      headerName: "Corrector",
      flex: 1,
      hideable: false,
    },
    {
      field: "partitionKey",
      headerName: "Approval Key",
      flex: 1,
      hideable: false,
    },
  ];

  // useEffect(() => {
  //   showTable();
  // }, []);

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Setup Enrichment Program
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Send Approval Key
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Send Approval Key
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Instiution
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setInstitutionValue(e.target.value as string)}
          >
            {institution.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Academic Career
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setAcadCareerValue(e.target.value as string)}
          >
            {acadCareer.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Term
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setTermValue(e.target.value as string)}
          >
            {term.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Campus
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setCampusValue(e.target.value as string)}
          >
            {campus.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Track
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setTrackValue(e.target.value as string)}
          >
            {track.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Corrector
          </Typography>
          <OutlinedInput fullWidth />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button onClick={() => showTable()} variant="contained" color="primary">
          Search
        </Button>
      </Grid>

      <Divider />

      <Box mt={3}>
        <Paper elevation={3}>
          <CustomDataGrid
            checkboxSelection
            columnsData={columns}
            rowsData={dataTable}
            rowCount={totalItems}
            getRowId={(row) => row.id}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
          />
        </Paper>
      </Box>
    </Container>
  );
}
