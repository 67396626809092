/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import { useQuery, useMutation } from '@tanstack/react-query';
import apiClient from '../config/api-client';
import { ApiEnrichmentTrack } from '../constants/ApiEnrichmentTrack';
import { JobStatusResponse, BusinessSectorResponse, PositionResponse, ProvinceResponse, CountryResponse, SkillTypeResponse, ProfileResponse, EnrichmentTrackResponse, CourseListResponse, MinorListResponse, SubmitEnrichmentRequest, ConfirmEnrichmentRequest } from '../types/enrichment-track';
import { UseQueryOptions, UseMutationOptions } from '../types/react-query';

export const useQueryGetJobStatus = ({
  options,
}: {
  options?: UseQueryOptions<JobStatusResponse>;
}) =>
  useQuery(
    ['GetJobStatus'],
    () =>
      apiClient
        .get<unknown, { data: JobStatusResponse }>(ApiEnrichmentTrack.getJobStatus)
        .then((res) => res.data),
    options,
  );

export const useQueryGetBusinessSector = ({
  options,
}: {
  options?: UseQueryOptions<BusinessSectorResponse>;
}) =>
  useQuery(
    ['GetBusinessSector'],
    () =>
      apiClient
        .get<unknown, { data: BusinessSectorResponse }>(ApiEnrichmentTrack.getBusinessSector)
        .then((res) => res.data),
    options,
  );

export const useQueryGetPosition = ({
  options,
}: {
  options?: UseQueryOptions<PositionResponse>;
}) =>
  useQuery(
    ['GetPosition'],
    () =>
      apiClient
        .get<unknown, { data: PositionResponse }>(ApiEnrichmentTrack.getPosition)
        .then((res) => res.data),
    options,
  );

export const useQueryGetProvince = ({
  options,
}: {
  options?: UseQueryOptions<ProvinceResponse>;
}) =>
  useQuery(
    ['GetProvince'],
    () =>
      apiClient
        .get<unknown, { data: ProvinceResponse }>(ApiEnrichmentTrack.getProvince)
        .then((res) => res.data),
    options,
  );

export const useQueryGetCountry = ({
    options,
  }: {
    options?: UseQueryOptions<CountryResponse>;
  }) =>
    useQuery(
      ['GetCountry'],
      () =>
        apiClient
          .get<unknown, { data: CountryResponse }>(ApiEnrichmentTrack.getCountry)
          .then((res) => res.data),
      options,
    );

export const useQueryGetSkillType = ({
    options,
  }: {
    options?: UseQueryOptions<SkillTypeResponse>;
  }) =>
    useQuery(
      ['GetSkillType'],
      () =>
        apiClient
          .get<unknown, { data: SkillTypeResponse }>(ApiEnrichmentTrack.getSkillType)
          .then((res) => res.data),
      options,
    );

export const useQueryGetProfileForValidateEnrichment = ({
  query,
  options,
}: {
  query: { binusianid: string };
  options?: UseQueryOptions<ProfileResponse>;
}) =>
  useQuery(
    ['GetProfileForValidateEnrichment'],
    () =>
      apiClient
        .get<unknown, { data: ProfileResponse }>(ApiEnrichmentTrack.profile(query.binusianid))
        .then((res) => res.data),
    options,
  );

export const useQueryGetProfile = ({
  query,
  options,
}: {
  query: { binusianid: string };
  options?: UseQueryOptions<ProfileResponse>;
}) =>
  useQuery(
    ['GetProfile'],
    () =>
      apiClient
        .get<unknown, { data: ProfileResponse }>(ApiEnrichmentTrack.profile(query.binusianid))
        .then((res) => res.data),
    options,
  );

export const useMutationSubmitProfile = (options?: UseMutationOptions) =>
  useMutation(
    (data: FormData) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEnrichmentTrack.submitProfile}`, data)
        .then((res) => res.data),
    options,
  );

export const useQueryGetEnrichmentTrack = ({
  query,
  options,
}: {
  query: { email: string, binusianid: string };
  options?: UseQueryOptions<EnrichmentTrackResponse>;
}) =>
  useQuery(
    ['GetEnrichmentTrack'],
    () =>
      apiClient
        .get<unknown, { data: EnrichmentTrackResponse }>(ApiEnrichmentTrack.enrichmentList(query.email, query.binusianid))
        .then((res) => res.data),
    options,
  );

export const useQueryCourseList = ({
  query,
  options,
}: {
  query: { acadcareer: string };
  options?: UseQueryOptions<CourseListResponse>;
}) =>
  useQuery(
    ['courseList'],
    () =>
      apiClient
        .get<unknown, { data: CourseListResponse }>(ApiEnrichmentTrack.courseList(query.acadcareer))
        .then((res) => res.data),
    options,
  );

export const useQueryMinorList = ({
  query,
  options,
}: {
  query: { acadcareer: string, track: string };
  options?: UseQueryOptions<MinorListResponse>;
}) =>
  useQuery(
    ['minorList'],
    () =>
      apiClient
        .get<unknown, { data: MinorListResponse }>(ApiEnrichmentTrack.minorList(query.acadcareer, query.track))
        .then((res) => res.data),
    options,
  );

export const useMutationSubmitEnrichment = (options?: UseMutationOptions) =>
  useMutation(
    (data: SubmitEnrichmentRequest) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEnrichmentTrack.submitEnrichment}`, data)
        .then((res) => res.data),
    options,
  );

export const useMutationConfirmEnrichment = (options?: UseMutationOptions) =>
  useMutation(
    (data: ConfirmEnrichmentRequest) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEnrichmentTrack.confirmEnrichment}`, data)
        .then((res) => res.data),
    options,
  );