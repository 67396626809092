import { IApi } from "../interfaces/IApi";

/**
 * if want to call API, just do (example):
 *
 * const url = `${ApiService.Identity}/YourEndPointHere`;
 */
export const ApiService: IApi = {
  blob: "https://stresources.blob.core.windows.net/profilepicture",
  graph: "https://graph.microsoft.com/v1.0/me",
  identity: "https://func-bion-flexibleprogram-be-dev.azurewebsites.net",
  apiPostman: "https://func-bion-flexibleprogram-be-dev.azurewebsites.net",
  enrichmentTrack: "https://func-bion-flexibleprogram-be-dev.azurewebsites.net/Enrichment",
  enrichmentApproval: "https://func-bion-flexibleprogram-be-dev.azurewebsites.net/EnrichmentTrackApproval",
  organization: "https://apim-bm7-dev.azure-api.net/func-organization-dev",
  profile:
    "https://apim-mydashboardbinusmaya-dev.azure-api.net/func-mydashboardbinusmaya-profile-dev",
  dashboard:
    "https://apim-mydashboardbinusmaya-dev.azure-api.net/func-mydashboardbinusmaya-dashboard-dev",
};

export const subscriptionKeyLMSBM7 = "3036affa50694b0fb6ba6552772007c2";
export const azureFunctionCode = "3j-Xk9PnipOdHwt5rEq8W2K5p1_stqLJie70D9pX_y_HAzFuTmmRYA%3D%3D";
export const subscriptionKeyMyDashboardBinusMaya ="c01143afab0f4501ac8c34f6586d6a94";
export const gaTrackingID = ""; /** dev */
export const TestMode = true;
export const REDPER = "";
export const ENVIRONMENT = "development";
