/* eslint-disable no-param-reassign */
/* eslint-disable spaced-comment */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { track } from '../action/dropdown.action';

interface DropdownState {
  loading: boolean;
  error: string | null; 
  data: any[];
}

const initialState: DropdownState = {
  loading: false,
  error: null,
  data: [],
};

const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Drop Down
      .addCase(track.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(track.fulfilled, (state, action: PayloadAction<any>) => {            
        state.loading = false;
        state.data = action.payload.data; 
        state.error = null;
      })
      .addCase(track.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.payload as string;
      })
    
  },
});

export default dropdownSlice.reducer;
