import { useMutation } from "@tanstack/react-query";
import apiClient from "../config/api-client";
import { ApiAsessmentMapping } from "../constants/setup-enrichment-program/ApiAsessmentMapping";
import { UseMutationOptions } from "../types/react-query";
import { ApiStreaming } from "../constants/ApiStreaming";

export const useMutationGetStreaming = (options?: UseMutationOptions) =>
  useMutation(
    (data: {}) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiStreaming.getStreaming}`, data)
        .then((res) => res.data),
    options
  );
