import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useQueryAcadGroup,
  useQueryAcadProg,
  useQueryAssesmentComp,
  useQueryListAcadCareerDropDown,
  useQueryListCampusDropDown,
  useQueryListInstitutionDropDown,
  useQueryListTrackDropDown,
} from "../../api/dropdown";
import { useQueryGetIndicatorById } from "../../api/setup-enrichment-program/assesment-mapping";
import {
  useMutationGenerateDueDate,
  useMutationSetDueDateSave,
  useMutationShowTableSetDueDate,
  useMutationShowTableUpdateDueDate,
  useMutationUpdateDueDateSave,
} from "../../api/setup-enrichment-program/set-due-date";
import { useQueryGetTermDropDown } from "../../api/student-registration";
import { CustomDataGrid } from "../../components/common/datagrid";
import useModal from "../../hooks/use-modal";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { ComponentCss } from "../../styles/page/ComponentCss";
import { StudentRegCss } from "../../styles/page/StudentRegCss";
import {
  AcadCareerDropdownResponseData,
  DropDownCampusLocationResponseData,
  DropdownResponseData,
  InstitutionDropdownResponseData,
} from "../../types/dropdown";
import { TermDataList } from "../../types/student-registration";
import { ShowTableUserMappingData } from "../../types/user-mapping";

export function SetDueDate() {
  const { showModal } = useModal();

  const [dataTable, setDataTable] = useState<ShowTableUserMappingData[]>([]);
  const [dataTableUpdate, setDataTableUpdate] = useState<
    ShowTableUserMappingData[]
  >([]);
  const [institution, setInstitution] = useState<
    InstitutionDropdownResponseData[]
  >([]);
  const [campus, setCampus] = useState<DropDownCampusLocationResponseData[]>(
    []
  );
  const [trackDueDates, setTrackDueDates] = useState([
    { track: "", dueDate: "" },
  ]);
  const [track, setTrack] = useState<DropdownResponseData[]>([]);
  const [acadProgList, setAcadProgList] = useState<DropdownResponseData[]>([]);
  const [acadGroupList, setAcadGroupList] = useState<DropdownResponseData[]>(
    []
  );

  const [assesmentCompList, setAssesmentCompList] = useState<
    DropdownResponseData[]
  >([]);
  const [listTerm, setListTerm] = useState<TermDataList[]>([]);
  const [dataAcadCareer, setDataAcadCareer] = useState<
    AcadCareerDropdownResponseData[]
  >([]);

  const [totalItems, setTotalItems] = useState(0);
  const [enrichmentTrack, setEnrichmentTrack] = useState<string[]>([]);

  const [modalGenerate, setModalGenerate] = useState(false);
  const [changeDateUpdate, setChangeDateUpdate] = useState("");

  const [id, setId] = useState("");
  const [modalMaxWeight, setModalMaxWeight] = useState(false);

  const [indicator, setIndicator] = useState("");
  const [weight, setWeight] = useState("");
  const [status, setStatus] = useState("");
  const [updateDueDate, setUpdateDueDate] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [correctorValue, setCorrectorValue] = useState("");
  const [studentValue, setStudentValue] = useState("");

  // Drop Down Value
  const [institutionDropValue, setInstitutionDropValue] = useState("");
  const [campusDropValue, setCampusDropValue] = useState("");
  const [acadProgValue, setAcadProgValue] = useState("");
  const [trackDropValue, setTrackDropValue] = useState("");
  const [acadGroupValue, setAcadGroupValue] = useState("");
  const [termValue, setTermValue] = useState("");
  const [acadCareerValue, setAcadCareerValue] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const submitShowTableSet = useMutationShowTableSetDueDate({
    onSuccess: async (res: any) => {
      setDataTable(res.data || []);

      const transformedData = res.data.map((item) => ({
        track: item.code,
        dueDate: item.dueDate || "",
      }));

      setTrackDueDates(transformedData);
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const submitShowTableUpdate = useMutationShowTableUpdateDueDate({
    onSuccess: async (res: any) => {
      setDataTableUpdate(res.data || []);
      console.log(res, "RES");
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const showTableSet = () => {
    submitShowTableSet.mutate({
      institution: institutionDropValue,
      academicCareer: acadCareerValue,
      term: termValue,
    });
  };

  const showTableUpdate = () => {
    submitShowTableUpdate.mutate({
      institution: "BNS01",
      academicCareer: "RS1",
      term: "2411",
      campus: "Binus",
      academicGroup: "BJKMN",
      academicProgram: "GKBCS",
      track: "FE",
      corrector: "test",
      student: "2301854315",

      // institution: institutionDropValue,
      // academicCareer: acadCareerValue,
      // term: termValue,
      // campus: campusDropValue,
      // academicGroup: acadGroupValue,
      // academicProgram: acadProgValue,
      // track: trackDropValue,
      // corrector: correctorValue,
      // student: studentValue,
    });
  };

  const generateDueDate = useMutationGenerateDueDate({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Due Date has been generated successfully.",
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk: () => {
            showTableSet();
            setModalGenerate(false);
          },
        },
      });
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const saveUpdatedDate = useMutationUpdateDueDateSave({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Due Date has been generated successfully.",
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk: () => {
            showTableSet();
          },
        },
      });
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const saveSetDueDate = useMutationSetDueDateSave({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Due Date has been generated successfully.",
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk: () => {
            showTableSet();
          },
        },
      });
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const updateDueDateSave = () => {
    saveUpdatedDate.mutate({
      institution: institutionDropValue,
      academicCareer: acadCareerValue,
      term: termValue,
      campus: campusDropValue,
      academicGroup: acadGroupValue,
      academicProgram: acadProgValue,
      track: trackDropValue,
      corrector: correctorValue,
      student: studentValue,
      dueDate: changeDateUpdate,
    });
  };

  const generate = () => {
    generateDueDate.mutate({
      institution: institutionDropValue,
      academicCareer: acadCareerValue,
      term: termValue,
      dueDate: dateValue,
    });
  };

  const dueDateSave = () => {
    saveSetDueDate.mutate({
      institution: institutionDropValue,
      academicCareer: acadCareerValue,
      term: termValue,
      trackDueDate: trackDueDates,
    });
  };

  const getTrack = useQueryListTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTrack(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      onSuccess: (res) => {
        setDataAcadCareer(res.data);
      },
      onError: () => {
        setDataAcadCareer([]);
      },
    },
  });

  const getTerm = useQueryGetTermDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setListTerm(res.data || []);
      },
      onError: () => {},
    },
  });

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setInstitution(res.data || []);
      },
      onError: () => {},
    },
  });

  const getCampus = useQueryListCampusDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setCampus(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadProg = useQueryAcadProg({
    query: {
      acadOrg: ""
    },
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAcadProgList(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadGroup = useQueryAcadGroup({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAcadGroupList(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAssesmentComp = useQueryAssesmentComp({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAssesmentCompList(res.data || []);
      },
      onError: () => {},
    },
  });

  const { refetch } = useQueryGetIndicatorById({
    query: { id },
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setIndicator(res.indicator || "");
        setWeight(res.weight || "");
        setStatus(res.status || "");
      },
      onError: () => {},
    },
  });

  const handleClose = () => {
    setModalGenerate(false);
  };

  const btnSetDueDate = () => {
    setUpdateDueDate(false);
  };

  const btnUpdateDueDate = () => {
    setUpdateDueDate(true);
  };

  const handleDateChange = (trackId: string, newDate: string) => {
    const updatedDates: { track: string; dueDate: string }[] =
      trackDueDates.map((dueDate) =>
        dueDate.track === trackId ? { ...dueDate, dueDate: newDate } : dueDate
      );
    setTrackDueDates(updatedDates);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Track",
      flex: 1,
      hideable: false,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      align: "center",
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <Grid sx={{ display: "flex", justifyContent: "start" }}>
          <OutlinedInput
            value={params.row.dueDate}
            type="date"
            onChange={(e) => handleDateChange(params.row.code, e.target.value)}
          />
        </Grid>
      ),
    },
  ];

  const columnsUpdate: GridColDef[] = [
    {
      field: "name",
      headerName: "Track",
      flex: 1,
      hideable: false,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      align: "center",
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <Grid sx={{ display: "flex", justifyContent: "start" }}>
          <OutlinedInput
            value={params.row.dueDate}
            type="date"
            onChange={(e) => setChangeDateUpdate(e.target.value)}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Setup Enrichment Program
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Set Due Date
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Set Due Date
      </Typography>

      <Divider />

      {updateDueDate ? (
        <>
          <Grid display="flex">
            <Grid xs={2}>
              <Box
                pt={1}
                pb={1}
                mr={1}
                sx={
                  !updateDueDate
                    ? StudentRegCss.headerActive
                    : StudentRegCss.headerInactive
                }
              >
                <Button onClick={() => btnSetDueDate()}>
                  <Typography variant="h6" gutterBottom>
                    Set Due Date
                  </Typography>
                </Button>
              </Box>
            </Grid>

            <Grid xs={2}>
              <Grid xs={2}>
                <Box
                  pt={1}
                  pb={1}
                  ml={1}
                  sx={
                    updateDueDate
                      ? StudentRegCss.headerActive
                      : StudentRegCss.headerInactive
                  }
                >
                  <Button onClick={() => btnUpdateDueDate()}>
                    <Typography variant="h6" gutterBottom>
                      Update Due Date
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Instiution
              </Typography>
              <Select
                fullWidth
                onChange={(e) =>
                  setInstitutionDropValue(e.target.value as string)
                }
              >
                {institution.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Academic Career
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setAcadCareerValue(e.target.value as string)}
              >
                {dataAcadCareer.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Term
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setTermValue(e.target.value as string)}
              >
                {listTerm.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Campus
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setCampusDropValue(e.target.value as string)}
              >
                {campus.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Academic Group
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setAcadGroupValue(e.target.value as string)}
              >
                {acadGroupList.map((item) => (
                  <MenuItem value={item.Id}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Academic Program
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setAcadProgValue(e.target.value as string)}
              >
                {acadProgList.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Track
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setTrackDropValue(e.target.value as string)}
              >
                {track.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Corrector
              </Typography>
              <OutlinedInput
                onChange={(e) => setCorrectorValue(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Student
              </Typography>
              <OutlinedInput
                onChange={(e) => setStudentValue(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="end" mt={2} mb={2}>
            <Button
              onClick={() => showTableUpdate()}
              variant="contained"
              color="primary"
            >
              Search
            </Button>
          </Grid>

          <Divider />

          <Box mt={3}>
            <Paper elevation={3}>
              <CustomDataGrid
                columnsData={columnsUpdate}
                rowsData={dataTableUpdate}
                rowCount={totalItems}
                getRowId={(row) => row.id}
                setPaginationModel={(value) => {
                  setPaginationModel(value);
                }}
                paginationModel={paginationModel}
              />
            </Paper>

            <Grid mt={2} display="flex" justifyContent="end" mb={2}>
              <Grid>
                <Button
                  onClick={() => updateDueDateSave()}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Grid display="flex">
            <Grid xs={2}>
              <Box
                pt={1}
                pb={1}
                mr={1}
                sx={
                  !updateDueDate
                    ? StudentRegCss.headerActive
                    : StudentRegCss.headerInactive
                }
              >
                <Button onClick={() => setUpdateDueDate(false)}>
                  <Typography variant="h6" gutterBottom>
                    Set Due Date
                  </Typography>
                </Button>
              </Box>
            </Grid>

            <Grid xs={2}>
              <Grid xs={2}>
                <Box
                  pt={1}
                  pb={1}
                  ml={1}
                  sx={
                    updateDueDate
                      ? StudentRegCss.headerActive
                      : StudentRegCss.headerInactive
                  }
                >
                  <Button onClick={() => setUpdateDueDate(true)}>
                    <Typography variant="h6" gutterBottom>
                      Update Due Date
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Instiution
              </Typography>
              <Select
                fullWidth
                onChange={(e) =>
                  setInstitutionDropValue(e.target.value as string)
                }
              >
                {institution.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Academic Career
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setAcadCareerValue(e.target.value as string)}
              >
                {dataAcadCareer.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography
                fontSize={16}
                color="#333333"
                variant="h5"
                gutterBottom
                mt={2}
              >
                Term
              </Typography>
              <Select
                fullWidth
                onChange={(e) => setTermValue(e.target.value as string)}
              >
                {listTerm.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="end" mt={2} mb={2}>
            <Button
              onClick={() => showTableSet()}
              variant="contained"
              color="primary"
            >
              Search
            </Button>
          </Grid>

          <Divider />

          {!updateDueDate && (
            <Grid display="flex" justifyContent="end" mt={2} mb={2}>
              <Button
                onClick={() => setModalGenerate(true)}
                variant="contained"
              >
                Generate Due Date
              </Button>
            </Grid>
          )}

          <Box mt={3}>
            <Paper elevation={3}>
              <CustomDataGrid
                columnsData={columns}
                rowsData={dataTable}
                rowCount={totalItems}
                getRowId={(row) => row.id}
                setPaginationModel={(value) => {
                  setPaginationModel(value);
                }}
                paginationModel={paginationModel}
              />
            </Paper>

            <Grid mt={2} display="flex" justifyContent="end" mb={2}>
              <Grid>
                <Button
                  onClick={() => dueDateSave()}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Modal
            open={modalGenerate}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ComponentCss.modalUploadLowStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontWeight="bold"
              >
                Generate Due Date
              </Typography>

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Institution
              </Typography>
              <OutlinedInput disabled value={institutionDropValue} fullWidth />

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Academic Career
              </Typography>
              <OutlinedInput disabled value={acadCareerValue} fullWidth />

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Term
              </Typography>
              <OutlinedInput disabled value={termValue} fullWidth />

              <Typography
                id="modal-modal-description"
                fontSize={12}
                mt={2}
                mb={1}
              >
                Due Date
              </Typography>
              <OutlinedInput
                onChange={(e) => setDateValue(e.target.value)}
                fullWidth
                type="date"
              />

              <Box mt={2} display="flex" justifyContent="end">
                <Button
                  onClick={() => setModalGenerate(false)}
                  variant="contained"
                  sx={{ marginRight: "10px" }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={() => generate()} variant="contained">
                  Save
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </Container>
  );
}
