import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CustomDataGrid } from "../../components/common/datagrid";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { ShowTableUserMappingData } from "../../types/user-mapping";
import { useMutationShowTableUserMapping } from "../../api/user-management";

export function TransferAssesmentComponent() {
  const [dataTable, setDataTable] = useState<ShowTableUserMappingData[]>([]);

  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const submitAddNewRole = useMutationShowTableUserMapping({
    query: {
      pageSize: 10,
      pageNumber: 1,
      orderBy: "id:asc",
      search: "",
    },
    options: {
      onSuccess: async (res: any) => {
        setDataTable(res.data || []);
      },
      onError: async (error) => {
        alert(error);
      },
    },
  });

  const showTable = () => {
    submitAddNewRole.mutate({
      campusLocation: "CSKMG",
      role: "1",
      faculty: "VCDG",
      status: "1",
    });
  };

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Course",
      flex: 1,
      hideable: false,
    },
    {
      field: "roleName",
      headerName: "Component",
      flex: 1,
      hideable: false,
    },
  ];

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Setup Enrichment Program
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Transfer Assessment Component
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Transfer Assessment Component
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Instiution
          </Typography>
          <Select
            fullWidth
            //   onChange={(e) => setListRoleValue(e.target.value as string)}
          >
            {/* {listRole.map((item) => (
            <MenuItem value={item.Id}>{item.Name}</MenuItem>
          ))} */}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Academic Career
          </Typography>
          <Select
            fullWidth
            //   onChange={(e) => setListRoleValue(e.target.value as string)}
          >
            {/* {listRole.map((item) => (
            <MenuItem value={item.Id}>{item.Name}</MenuItem>
          ))} */}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Term
          </Typography>
          <Select
            fullWidth
            //   onChange={(e) => setListRoleValue(e.target.value as string)}
          >
            {/* {listRole.map((item) => (
            <MenuItem value={item.Id}>{item.Name}</MenuItem>
          ))} */}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Course
          </Typography>
          <Select
            fullWidth
            //   onChange={(e) => setListRoleValue(e.target.value as string)}
          >
            {/* {listRole.map((item) => (
            <MenuItem value={item.Id}>{item.Name}</MenuItem>
          ))} */}
          </Select>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button
          // onClick={() => showTable()}
          variant="contained"
          color="primary"
          sx={{ marginRight: "14px" }}
        >
          Transfer Data
        </Button>
        <Button onClick={() => showTable()} variant="contained" color="primary">
          Search
        </Button>
      </Grid>

      <Divider />

      <Box mt={3}>
        <Paper elevation={3}>
          <CustomDataGrid
            columnsData={columns}
            rowsData={dataTable}
            rowCount={totalItems}
            getRowId={(row) => row.id}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
          />
        </Paper>
      </Box>
    </Container>
  );
}
