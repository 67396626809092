import { Route } from "../types/route";
import { Login } from "../pages/login";
import { Waiting } from "../pages/auth/Waiting";
import { Unauthorized } from "../pages/auth/Unauthorized";
import { Logout } from "../pages/logout";

export const publicRoutes: Route[] = [
  {
    key: "router-login",
    title: "Login",
    description: "Login",
    component: Login,
    path: "/login",
    isEnabled: true,
    appendDivider: true,
    noLayout: true,
  },
  {
    key: "router-waiting",
    title: "Waiting",
    description: "Waiting",
    component: Waiting,
    path: "/waiting",
    isEnabled: true,
    appendDivider: true,
    noLayout: true,
  },
  {
    key: "router-unauthorized",
    title: "Unauthorized",
    description: "Unauthorized",
    component: Unauthorized,
    path: "/unauthorized",
    isEnabled: true,
    appendDivider: true,
    noLayout: true,
  },
  {
    key: 'router-logout',
    title: 'Logout',
    description: 'Logout',
    component: Logout,
    path: '/logout',
    isEnabled: true,
    appendDivider: true,
    noLayout: true
  }
];
