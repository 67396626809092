import {
  Container,
  Stack,
  Typography,
  Breadcrumbs,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Paper,
  Chip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Edit, Search } from "@mui/icons-material";
import { UserRoleCss } from "../../../styles/page/UserRoleCss";
import { CustomDataGrid } from "../../../components/common/datagrid";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  useMutationDeleteUserMapping,
  useMutationShowTableUserMapping,
  useQueryFacultyDropDown,
  useQueryListRoleUserMappingDropDown,
  useQueryStatusDropDown,
} from "../../../api/user-management";
import {
  DropDownFacultyData,
  DropDownRoleListData,
  DropDownStatusData,
  ShowTableUserMappingData,
} from "../../../types/user-mapping";
import { breadCrumbLink } from "../../../styles/common/bread-crumb-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DropDownCampusLocationResponseData } from "../../../types/dropdown";
import { useQueryListCampusLocationDropDown } from "../../../api/dropdown";
import useModal from "../../../hooks/use-modal";

export function UserMapping() {
  const { showModal } = useModal();

  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const [listCampusDropDown, setListCampusDropDown] = useState<
    DropDownCampusLocationResponseData[]
  >([]);
  const [listRole, setListRole] = useState<DropDownRoleListData[]>([]);
  const [listFaculty, setListFaculty] = useState<DropDownFacultyData[]>([]);
  const [listStatus, setListStatus] = useState<DropDownStatusData[]>([]);
  const [dataTable, setDataTable] = useState<ShowTableUserMappingData[]>([]);
  const [idList, setIdList] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [campusLocationValue, setCampusLocationValue] = useState("");
  const [listRoleValue, setListRoleValue] = useState("");
  const [facultyValue, setFacultiyValue] = useState("");
  const [statusValue, setStatusValue] = useState("");

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      hideable: false,
    },
    {
      field: "roleName",
      headerName: "Role",
      flex: 1,
      hideable: false,
    },
    {
      field: "campusDesc",
      headerName: "Campus",
      flex: 1,
      hideable: false,
    },
    {
      field: "acadGroupDesc",
      headerName: "Faculty",
      flex: 1,
      hideable: false,
    },
    {
      field: "acadProgDesc",
      headerName: "Program",
      flex: 1,
      hideable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      hideable: false,
      renderCell: (params) =>
        params.value === "Active" ? (
          <Chip color="warning" component="div" label={params.value} />
        ) : (
          <Chip color="error" component="div" label={params.value} />
        ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      align: "center",
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Link to={`/user-management/edit-user-mapping?${params.id}`}>
            <IconButton aria-label="delete">
              <Edit />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => handleDelete([params.id])}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      ),
    },
  ];

  const getDropDownRole = useQueryListRoleUserMappingDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListRole(res.data || []);
      },
      onError: (err) => {
        alert(err);
      },
    },
  });

  const getDropDownCampusLocation = useQueryListCampusLocationDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListCampusDropDown(res.data || []);
      },
      onError: (err) => {
        alert(err);
      },
    },
  });

  const getDropDownFaculty = useQueryFacultyDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListFaculty(res.data || []);
      },
      onError: (err) => {
        alert(err);
      },
    },
  });

  const getDropDownStatus = useQueryStatusDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListStatus(res.data || []);
      },
      onError: (err) => {
        alert(err);
      },
    },
  });

  const submitAddNewRole = useMutationShowTableUserMapping({
    query: {
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page + 1,
      orderBy: "id:asc",
      search: "",
    },
    options: {
      onSuccess: async (res: any) => {
        setDataTable(res.data || []);
        setTotalItems(res.paginationInfo.totalItems || 0);
      },
      onError: async (error) => {
        alert(error);
      },
    },
  });

  const submitDeleteRole = useMutationDeleteUserMapping({
    onSuccess: async (res) => {
      showModal({
        title: "Success",
        message: `Delete user success!`,
        options: {
          buttonTitle: "Ok",
          variant: "success",
        },
      });
      showTable();
    },
    onError: async (error) => {
      showModal({
        title: "Failed",
        message: `Delete user is failed.`,
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const handleDelete = (idDelete) => {
    showModal({
      title: "Alert",
      message: `Are you sure you want to delete this user?`,
      options: {
        buttonTitle: "Delete",
        buttonTitle2: "Cancel",
        variant: "info",
        onOk() {
          submitDeleteRole.mutate({
            id: idDelete,
          });
        },
      },
    });
  };
  const showTable = () => {
    submitAddNewRole.mutate({
      campusLocation: campusLocationValue,
      role: listRoleValue,
      faculty: facultyValue,
      status: statusValue,
    });
  };

  useEffect(() => {
    showTable();
  }, [paginationModel]); 


  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            User Management
          </Link>
          <Typography fontSize={14} color="#0097DA">
            User Mapping
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        User Mapping
      </Typography>

      <Divider />

      <Grid container spacing={2} mt={2}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="campusLocation-select-label" shrink>Campus Location</InputLabel>
            <Select
              displayEmpty
              labelId="campusLocation-select-label"
              id="campusLocation-select"
              value={campusLocationValue}
              label="Campus Location"
              onChange={(e) => setCampusLocationValue(e.target.value as string)}
            >
                <MenuItem value=""><em>All</em></MenuItem>
              {listCampusDropDown.map(item => (
                <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="role-select-label" shrink>Role</InputLabel>
            <Select
              displayEmpty
              labelId="role-select-label"
              id="role-select"
              value={listRoleValue}
              label="Role"
              onChange={(e) => setListRoleValue(e.target.value as string)}
            >
                <MenuItem value=""><em>All</em></MenuItem>
              {listRole.map(item => (
                <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="faculty-select-label" shrink>Faculty</InputLabel>
            <Select
              displayEmpty
              labelId="faculty-select-label"
              id="faculty-select"
              value={facultyValue}
              label="Faculty"
              onChange={(e) => setFacultiyValue(e.target.value as string)}
            >
                <MenuItem value=""><em>All</em></MenuItem>
              {listFaculty.map(item => (
                <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="status-select-label" shrink>Status</InputLabel>
            <Select
              displayEmpty
              labelId="status-select-label"
              id="status-select"
              value={statusValue}
              label="Status"
              onChange={(e) => setStatusValue(e.target.value as string)}
            >
                <MenuItem value=""><em>All</em></MenuItem>
              {listStatus.map(item => (
                <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button onClick={() => showTable()} variant="contained" color="primary">
          Search
        </Button>
      </Grid>
      <Divider />

      <Grid mt={2} container>
        <Grid container xs={6}>
          <Grid mr={1} item>
            <Link to="/user-management/add-user-mapping">
              <Button color="primary" variant="contained">
                Add User
              </Button>
            </Link>
          </Grid>
          <Grid ml={1} item>
            <Button
              onClick={() => handleDelete(idList)}
              variant="contained"
              startIcon={<DeleteIcon color="info" />}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Paper elevation={3}>
          <CustomDataGrid
            checkboxSelection
            columnsData={columns}
            rowsData={dataTable}
            rowCount={totalItems}
            getRowId={(row) => row.id}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
            onRowSelectionModelChange={(idRow) => {
              setIdList(idRow);
            }}
          />
        </Paper>
      </Box>
    </Container>
  );
}
