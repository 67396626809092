const Keys = {
    googleVision: '',
    vapid: '',
    firebase: '',
    // ClientID: '84771f27-f933-4f43-baa0-2e803a124abc',
    // ClientID: 'd52023ea-69f8-4698-94ee-46cf07ca6698',
    ClientID: 'eb847b7b-ddb8-48d0-b180-498400b15af3',
    TenantID: '3485b963-82ba-4a6f-810f-b5cc226ff898',
};

export default Keys;
