/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../config/api-client";
import { ApiIdentity } from "../constants/ApiIdentity";
import {
  IdentityLogin,
  IdentityLoginAzureAD,
  IdentityLoginResponse,
  IdentityLoginResponse2,
} from "../types/identity";
import { UseMutationOptions, UseQueryOptions } from "../types/react-query";

export const useSignInByEmail = ({
  query,
  options,
}: {
  query: { email?: string; token?: string };
  options?: UseQueryOptions<IdentityLoginResponse2>;
}) =>
  useQuery(
    ["signByEmail"],
    () =>
      apiClient
        .post<unknown, { data: IdentityLoginResponse2 }>(
          ApiIdentity.signInByEmail(query.email),
          {
            token: query.token,
          }
        )
        .then((res) => {
          // Store the user data in session storage
          localStorage.setItem("userData", JSON.stringify(res.data?.data));
          localStorage.setItem("token", JSON.stringify(res.data?.token));
          return res.data;
        }),
    options
  );

// ========================================
// MUTATIONS
// ========================================

export const useSignInByAzureAD = (
  options?: UseMutationOptions<IdentityLoginResponse>
) =>
  useMutation(
    (data: IdentityLoginAzureAD) =>
      apiClient
        .post<IdentityLoginResponse>(`${ApiIdentity.signInByAzureAdV2}`, data)
        .then((res) => res.data),
    options
  );

export const useRevokeRefreshToken = (options?: UseMutationOptions) =>
  useMutation(
    (data: IdentityLoginResponse) =>
      apiClient
        .post(`${ApiIdentity.revokeRefreshToken}`, data)
        .then((res) => res.data),
    options
  );

export const useRefreshAccessToken = (
  options?: UseMutationOptions<IdentityLoginResponse>
) =>
  useMutation(
    (data: IdentityLoginResponse) =>
      apiClient
        .post(`${ApiIdentity.refreshAccessToken}`, data)
        .then((res) => res.data),
    options
  );
