import { SxProps, Theme } from "@mui/material";
import { themes } from "../mui/theme";
import { SxStyle } from "../../types/style";

// LAYOUT

const searchBar: SxStyle = {
  position: "relative",
};

const iconSearch: SxStyle = {
  position: "absolute",
  right: 6,
  top: 10,
};

const inputSearch: SxStyle = {
  width: "281px",
};
export const UserRoleCss = {
  searchBar,
  iconSearch,
  inputSearch,
};
