import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../../config/api-client";
import { ApiGenerateGradeFail } from "../../constants/setup-enrichment-program/ApiGenerateGradeFail";
import { UseMutationOptions, UseQueryOptions } from "../../types/react-query";
import { GeneratePayload, ShowTableGenerateGradeFall } from "../../types/setup-enrichment-program/generate-grade-fail";

export const useMutationShowTableGenerateGradeFall = (
  options?: UseMutationOptions
) =>
  useMutation(
    (data: ShowTableGenerateGradeFall) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiGenerateGradeFail.showTableGenerateGradeFall}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useQueryExportExcelGenerateGradeFail = ({
  query,
  options,
}: {
  query: {
    institution: string;
    acadCareer: string;
    term: string;
    campus: string;
    track: string;
    acadProg: string;
    binusianId: string;
  };
  options?: UseQueryOptions<any>;
}) =>
  useQuery(
    ["exportExcelGenerateGradeFail", query],
    () =>
      apiClient
        .get<any>(
          ApiGenerateGradeFail.exportExcelGenerateGradeFail(
            query.institution,
            query.acadCareer,
            query.term,
            query.campus,
            query.track,
            query.acadProg,
            query.binusianId
          ),
          {
            responseType: "blob",
          }
        )
        .then((res) => res.data),
    options
  );

export const useMutationUploadGradeFail = (options?: UseMutationOptions) =>
  useMutation(
    (data: FormData) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiGenerateGradeFail.uploadGradeFail}`,
          data
        )
        .then((res) => res.data),
    options
  );

  export const useMutationGenerateSubmit = ({
    options,
  }: {
    options?: UseMutationOptions;
  }) =>
    useMutation(
      (data: GeneratePayload[]) =>
        apiClient
          .post<unknown, { data: string }>(
            `${ApiGenerateGradeFail.generateGradeFail}`,
            data
          )
          .then((res) => res.data),
      options
    );