import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Divider, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import { useQueryListRoleAdd } from "../../api/user-management";
import { RoleListResponse } from "../../types/user-role";

interface MenuItem {
  id: string;
  partitionKey: string;
  menuName: string;
  controllerName: string;
  isChecked?: boolean;
}

const data = {
  "User Management": [
    {
      id: "mnRole",
      partitionKey: "MsMenu",
      menuName: "User Role",
      controllerName: "UserRole",
    },
    {
      id: "mnMapping",
      partitionKey: "MsMenu",
      menuName: "User Mapping",
      controllerName: "UserMapping",
    },
  ],
  "Study Plan": [
    {
      id: "mnRegistration",
      partitionKey: "MsMenu",
      menuName: "Registration",
      controllerName: "Registration",
    },
  ],
};

interface MyComponentProps {
  setData: any;
}

export default function CheckboxListAdd({
  setData,
}: MyComponentProps) {
  const [checked, setChecked] = useState<RoleListResponse[]>([]);
  const [listRole, setListRole] = useState<RoleListResponse[]>([]);
  const [dropdown, setDropdown] = useState(() => {
    const initialDropdown = {};
    for (const key of Object.keys(listRole)) {
      initialDropdown[key.replace(/\s+/g, "")] = false;
    }
    return initialDropdown;
  });

  const handleParentCheck = (
    category: string,
    items: RoleListResponse[],
    isChecked: boolean
  ) => {
    setDropdown((prev) => ({ ...prev, [category]: true }));
    setChecked((prev) => {
      const updatedChecked = prev.filter(
        (item) => !items[0].some((i) => i.id === item.id)
      );
      if (isChecked) {
        return [
          ...updatedChecked,
          ...(Array.isArray(items[0]) ? items[0] : [items[0]]).map((item) => ({
            ...item,
            isChecked: true,
          })),
        ];
      }
      return updatedChecked;
    });
  };

  const handleChildCheck = (
    category: string,
    item: RoleListResponse,
    isChecked: boolean
  ) => {
    setChecked((prev) => {
      const updatedChecked = prev.filter((i) => i.id !== item.id);
      if (isChecked) {
        return [...updatedChecked, { ...item, isChecked: true }];
      }
      return updatedChecked;
    });
  };

  const setCheckedUserManagement = () => {
    const userManagementItems = listRole["User Management"];
    setChecked(
      userManagementItems.map((item) => ({ ...item, isChecked: true }))
    );
  };

  const handleSubmit = () => {
    const submittedData: { menuId: string; canRead: number }[] = [];

    // Iterate over each category
    Object.entries(listRole).forEach(([category, items]) => {
      // Iterate over each item in the category
      items.forEach(({ id }) => {
        // Change destructuring assignment to destructure 'id' property
        // Determine if the item is checked
        const isChecked = checked.some(
          (item) => item.id === id && item.isChecked
        ); // Use 'id' instead of 'menuId'
        // Add the item to the submittedData with canRead property
        submittedData.push({
          menuId: id, // Use 'id' instead of 'menuId'
          canRead: isChecked ? 1 : 0,
        });
      });
    });

    setData(submittedData);
  };

  const getListRoleAdd = useQueryListRoleAdd({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setListRole(res);
      },
      onError: () => {
        alert("Error");
      },
    },
  });

  useEffect(() => {
    const submittedData: { menuId: string; canRead: number }[] = [];

    // Iterate over each category
    Object.entries(listRole).forEach(([category, items]) => {
      // Iterate over each item in the category
      items.forEach(({ id }) => {
        // Change destructuring assignment to destructure 'id' property
        // Determine if the item is checked
        const isChecked = checked.some(
          (item) => item.id === id && item.isChecked
        ); // Use 'id' instead of 'menuId'
        // Add the item to the submittedData with canRead property
        submittedData.push({
          menuId: id, // Use 'id' instead of 'menuId'
          canRead: isChecked ? 1 : 0,
        });
      });
    });
    setData(submittedData);
  }, [checked, setData]);

  return (
    <>
      {Object.entries(listRole).map(([label, items]) => {
        const categoryKey = label.replace(/\s+/g, "");
        const checkedCategory = checked.filter((item) =>
          items.some((i) => i.id === item.id)
        );
        const dropCategory = dropdown[categoryKey];

        return (
          <div key={label}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {items.length > 1 && ( // Checking if items array has more than 1 item
                <IconButton
                  onClick={() =>
                    setDropdown((prev) => ({
                      ...prev,
                      [categoryKey]: !dropCategory,
                    }))
                  }
                >
                  {dropCategory ? <ExpandMore /> : <KeyboardArrowRightIcon />}
                </IconButton>
              )}

              <FormControlLabel
                label={label}
                control={
                  <Checkbox
                    sx={items.length <= 1 ? { marginLeft: "40px" } : null}
                    checked={checkedCategory.length === items.length}
                    indeterminate={
                      checkedCategory.length > 0 &&
                      checkedCategory.length < items.length
                    }
                    onChange={(event) =>
                      handleParentCheck(
                        categoryKey,
                        [items],
                        event.target.checked
                      )
                    }
                  />
                }
              />
            </Box>
            {dropCategory &&
              items.length > 1 && ( // Render child checkboxes only if items array has more than 1 item
                <Box sx={{ marginLeft: "50px" }}>
                  {items.map((item) => (
                    <Box sx={{ display: "flex" }} key={item.id}>
                      <Checkbox
                        checked={checked.some(
                          (i) => i.id === item.id && i.isChecked
                        )}
                        onChange={(event) =>
                          handleChildCheck(
                            categoryKey,
                            item,
                            event.target.checked
                          )
                        }
                      />
                      <Typography
                        sx={{ marginTop: "10px" }}
                        fontSize={14}
                        color="#333333"
                      >
                        {item.menuName}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            <Divider />
          </div>
        );
      })}
      {/* <Button
        onClick={setCheckedUserManagement}
        variant="contained"
        color="primary"
        fullWidth
      >
        Set User Management
      </Button>

      <Button
        onClick={() => handleSubmit()}
        variant="contained"
        color="primary"
        fullWidth
      >
        Submit
      </Button> */}
    </>
  );
}
