import apiClient from "../config/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { UseMutationOptions, UseQueryOptions } from "../types/react-query";
import { ApiDropDown } from "../constants/ApiDropdown";
import { ApiManageUser } from "../constants/ApiManageUser";
import {
  DropdownResponse,
  InstitutionDropDownResponse,
  AcadCareerDropDownResponse,
  AcadOrgDropDownResponse
} from "../types/dropdown";

import { RoleSubmit } from "../types/user-role";

export const useQueryListInstitutionDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<InstitutionDropDownResponse>;
}) =>
  useQuery(
    ["useRoleDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: InstitutionDropDownResponse[] }>(
          ApiDropDown.getInstitutionDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryListAcadCareerDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<AcadCareerDropDownResponse>;
}) =>
  useQuery(
    ["useAcadCareerDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: AcadCareerDropDownResponse[] }>(
          ApiDropDown.getAcadCareerDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryAcadOrg = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<AcadOrgDropDownResponse>;
}) =>
  useQuery(
    ["useAcadOrgDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: AcadOrgDropDownResponse[] }>(
          ApiDropDown.getAcadOrgDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryAcadProg = ({
  query,
  options,
}: {
  query: { acadOrg: string };
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useAcadProg", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getAcadProgDropdown(query.acadOrg)
        )
        .then((res) => res.data),
    options
  );
  
export const useQueryAcadPlan = ({
  query,
  options,
}: {
  query: { acadOrg: string, acadProg: string};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["GetAcadPlan", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getAcadPlanDropDown(query.acadOrg, query.acadProg)
        )
        .then((res) => res.data),
    options
  );

export const useQueryListCampusLocationDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useCampusLoactionDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getListCampusLocationDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryListCampusDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useCampusDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getListCampusDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryListTrackDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useTrackDropDown", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getListTrackDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useMutationAddNewRole = (options?: UseMutationOptions) =>
  useMutation(
    (data: RoleSubmit) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiManageUser.submitAddNewRoleApi}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useQueryAssesmentComp = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useAssesmentComp", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getListAssesmentComp()
        )
        .then((res) => res.data),
    options
  );

export const useQueryAcadGroup = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useAcadGroup", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getListAcadGroup()
        )
        .then((res) => res.data),
    options
  );

export const useQueryEvaluationComponent = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useAcadGroup", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getListEvaluationComponent()
        )
        .then((res) => res.data),
    options
  );

export const useQueryDropDownGradeStatus = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useGradeStatus", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getDropDownGradeStatus()
        )
        .then((res) => res.data),
    options
  );

export const useQueryDropDownTransferStatus = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<DropdownResponse>;
}) =>
  useQuery(
    ["useTransferStatus", query],
    () =>
      apiClient
        .get<unknown, { data: DropdownResponse[] }>(
          ApiDropDown.getDropDownTransferStatus()
        )
        .then((res) => res.data),
    options
  );

  export const useQueryDropDownAdmitTerm = ({
    query,
    options,
  }: {
    query: {};
    options?: UseQueryOptions<DropdownResponse>;
  }) =>
    useQuery(
      ["useDropAcadCareer", query],
      () =>
        apiClient
          .get<unknown, { data: DropdownResponse[] }>(
            ApiDropDown.getDropDownAdmitTerm()
          )
          .then((res) => res.data),
      options
    );