import { ApiService, azureFunctionCode } from "../ApiService";

export const ApiGenerateGradeFail = {
  showTableGenerateGradeFall: `${ApiService.apiPostman}/GenerateGradeFail/GetGenerateGradeFail?code=${azureFunctionCode}`,
  uploadGradeFail: `${ApiService.apiPostman}/GenerateGradeFail/UploadGenerateGradeFail?code=${azureFunctionCode}`,
  generateGradeFail: `${ApiService.apiPostman}/GenerateGradeFail/Generate?code=${azureFunctionCode}`,
  exportExcelGenerateGradeFail: (
    institution: string,
    acadCareer: string,
    term: string,
    campus: string,
    track: string,
    acadProg: string,
    binusianId: string
  ) =>
    `${ApiService.apiPostman}/GenerateGradeFail/ExportGenerateGradeFail?code=${azureFunctionCode}&institution=${institution}&acadcareer=${acadCareer}&term=${term}&campus=${campus}&track=${track}&acadprog=${acadProg}&binusianid=${binusianId}`,
};
