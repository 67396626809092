import { HeadCell } from "../../../components/table/types";

export interface EvaluationData {
    id: string;
    evaluationMonth: string;
    name: string;
    evaluationStatus: string;
    dueDate: string;
    entryDate: string;
    approvalDate: string;
    submissionDate: string;
    note: string;
    userType: string;
    action: string;
  }
  
  export interface EvaluationEntryData {
    id: string;
  externalSystemId: string;
  photo: string;
  name: string;
  binusId: string;
  institution: string;
  institutionDesc: string;
  acadCareer: string;
  acadCareerDesc: string;
  track: string;
  trackDesc: string;
  term: string;
  termDesc: string;
  approvalStatus: string;
  approvalBy: string;
  approvalByBinusianId: string | null;
  approvalDate: string;
  evaluationMonth: string;
  status: string;
  checkStatus: string;
  dueDate: string | null;
  startDate: string | null;
  endDate: string | null;
  isAdditional: string;
  isEvaluation: string;
  evaluationStatus: string;
  evaluationMapping: EvaluationMapping[];
  corrector: string | null;
  isDelete: boolean;
  }

  export interface Indicator {
    id: string;
    name: string;
    note: string | null;
  }
  
  export interface EvaluationMapping {
    id: string;
    partitionKey: string;
    institution: string;
    institutionDesc: string;
    acadCareer: string;
    acadCareerDesc: string;
    name: string;
    desc: string;
    indicator: Indicator[];
    userIn: string | null;
    userUp: string | null;
    dateIn: string;
    dateUp: string | null;
    isDelete: boolean;
  }

  export const EvaluationHeadCells: readonly HeadCell<EvaluationData>[] = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },    
    { id: 'evaluationMonth', numeric: false, disablePadding: false, label: 'Month' },    
    { id: 'name', numeric: false, disablePadding: false, label: 'Student' },    
    { id: 'evaluationStatus', numeric: false, disablePadding: false, label: 'Evaluation Status' },
    { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due Date' },    
    { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
  ];