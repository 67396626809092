import { AddOutlined, Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useQueryAcadProg,
  useQueryAssesmentComp,
  useQueryListCampusDropDown,
  useQueryListInstitutionDropDown,
  useQueryListTrackDropDown,
} from "../../api/dropdown";
import {
  useMutationAddNewIndicator,
  useMutationDeleteIndicator,
  useMutationSetMaxWeight,
  useMutationShowTableAssesmentMapping,
  useMutationUpdateIndicator,
  useQueryGetIndicatorById,
} from "../../api/setup-enrichment-program/assesment-mapping";
import { CustomDataGrid } from "../../components/common/datagrid";
import useModal from "../../hooks/use-modal";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { ComponentCss } from "../../styles/page/ComponentCss";
import {
  DropDownCampusLocationResponseData,
  DropdownResponseData,
  InstitutionDropdownResponseData,
} from "../../types/dropdown";
import { ShowTableUserMappingData } from "../../types/user-mapping";

export function AsessmentMapping() {
  const { showModal } = useModal();

  const [dataTable, setDataTable] = useState<ShowTableUserMappingData[]>([]);
  const [institution, setInstitution] = useState<
    InstitutionDropdownResponseData[]
  >([]);
  const [campus, setCampus] = useState<DropDownCampusLocationResponseData[]>(
    []
  );
  const [track, setTrack] = useState<DropdownResponseData[]>([]);
  const [acadProgList, setAcadProgList] = useState<DropdownResponseData[]>([]);
  const [assesmentCompList, setAssesmentCompList] = useState<
    DropdownResponseData[]
  >([]);

  const [totalItems, setTotalItems] = useState(0);
  const [enrichmentTrack, setEnrichmentTrack] = useState<string[]>([]);
  const [listStringArray, setListStringArray] = useState<
    { indicator: string; weight: string; status: string }[]
  >([{ indicator: "", weight: "", status: "Active" }]);

  const [modalAddNew, setModalAddNew] = useState(false);
  const [checkedUpdateAll, setCheckedUpdateAll] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const [id, setId] = useState("");
  const [modalMaxWeight, setModalMaxWeight] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [indicator, setIndicator] = useState("");
  const [weight, setWeight] = useState("");
  const [status, setStatus] = useState("");

  const [institutionValue, setInstitutionValue] = useState("");
  const [componentValue, setComponentValue] = useState("");
  const [trackValue, setTrackValue] = useState("");
  const [campusValue, setCampusValue] = useState("");

  // Drop Down Value
  const [institutionDropValue, setInstitutionDropValue] = useState("");
  const [campusDropValue, setCampusDropValue] = useState("");
  const [acadProgValue, setAcadProgValue] = useState("");
  const [trackDropValue, setTrackDropValue] = useState("");
  const [assesmentCompValue, setAssesmentCompValue] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const submitShowTable = useMutationShowTableAssesmentMapping({
    onSuccess: async (res: any) => {
      setDataTable(res.data || []);
      setShowTable(true);
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const submitEditIndicator = useMutationUpdateIndicator({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Indicator Successfully Edited",
        options: {
          variant: "success",
          onOk() {
            showTableData();
            setModalEdit(false);
          },
        },
      });
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const submitMaxWeight = useMutationSetMaxWeight({
    onSuccess: async (res: any) => {
      // setDataTable(res.data || []);
      console.log(res, "RES");
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const submitAddNewIndicator = useMutationAddNewIndicator({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Indicator Successfully Created",
        options: {
          variant: "success",
          onOk() {
            setModalAddNew(false);
            showTableData();
          },
        },
      });
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const submitDeleteIndicator = useMutationDeleteIndicator({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Indicator Successfully Deleted",
        options: {
          variant: "success",
        },
      });
    },
    onError: async (error) => {
      alert(error);
    },
  });

  const submitMax = () => {
    submitMaxWeight.mutate({
      idAssessmentMapping: "1",
      maxWeight: "10",
      assessmentComponent: "ESS1",
      updateAllAcadProg: true,
    });
  };

  const editIndicator = () => {
    submitEditIndicator.mutate({
      id,
      indicator,
      weight,
      status,
      updateAllAcadProg: checkedUpdateAll,
    });
  };

  const handleDelete = (idDelete: string, weightDelete: string) => {
    showModal({
      title: "Alert",
      message: "Are you sure want delete this indicator?",
      options: {
        variant: "info",
        buttonTitle: "Delete",
        buttonTitle2: "Cancel",
        onOk() {
          submitDeleteIndicator.mutate({
            id: idDelete,
            weight: weightDelete,
            assessmentComponent: assesmentCompValue,
            updateAllAcadProg: true,
          });
        },
      },
    });
  };

  const submitAddIndicator = () => {
    submitAddNewIndicator.mutate({
      institution: institutionDropValue,
      institutionDesc: institutionDropValue,
      campus: campusDropValue,
      campusDesc: campusDropValue,
      acadProg: enrichmentTrack.map((item) => ({
        acadProg: item,
        acadProgDesc: item,
      })),
      track: trackDropValue,
      trackDesc: trackDropValue,
      assessmentComponent: assesmentCompValue,
      indicator: listStringArray,
    });
  };

  const showTableData = () => {
    submitShowTable.mutate({
      institution: institutionDropValue,
      campus: campusDropValue,
      acadProg: acadProgValue,
      track: trackDropValue,
      assessmentComponent: assesmentCompValue,
    });
  };

  const handleAddFields = () => {
    setListStringArray([
      ...listStringArray,
      {
        indicator: "",
        weight: "",
        status: "Active",
      },
    ]);
  };

  const handleChangeSelect = (e) => {
    const {
      target: { value },
    } = e;

    if (value.includes("all")) {
      // Select all items
      if (selectAll) {
        // Deselect all if already selected
        setEnrichmentTrack([]);
      } else {
        // Select all items
        setEnrichmentTrack(acadProgList.map((item) => item.Code));
      }
    } else {
      // Normal selection logic
      setEnrichmentTrack(typeof value === "string" ? value.split(",") : value);
    }
  };

  const getTrack = useQueryListTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setTrack(res.data || []);
      },
      onError: () => {},
    },
  });

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setInstitution(res.data || []);
      },
      onError: () => {},
    },
  });

  const getCampus = useQueryListCampusDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setCampus(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAcadProg = useQueryAcadProg({
    query: {
      acadOrg: ""
    },
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAcadProgList(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAssesmentComp = useQueryAssesmentComp({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        console.log(res, "RES");
        setAssesmentCompList(res.data || []);
      },
      onError: () => {},
    },
  });

  const { refetch } = useQueryGetIndicatorById({
    query: { id },
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setIndicator(res.indicator || "");
        setWeight(res.weight || "");
        setStatus(res.status || "");
      },
      onError: () => {},
    },
  });

  const handleClose = () => {
    setModalAddNew(false);
    setModalMaxWeight(false);
    setModalEdit(false);
  };

  const handleChangeUpdateAll = (event) => {
    setCheckedUpdateAll(event.target.checked);
  };

  const columns: GridColDef[] = [
    {
      field: "indicator",
      headerName: "Indicator",
      flex: 1,
      hideable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 1,
      hideable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      hideable: false,
      renderCell: (params) =>
        params.value !== "Active" ? (
          <Chip color="error" component="div" label="Nonactive" />
        ) : (
          <Chip color="warning" component="div" label="Active" />
        ),
    },
    {
      field: "id",
      headerName: "Action",
      width: 100,
      align: "center",
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <IconButton
            onClick={() => {
              refetch();
              setModalEdit(true);
              setId(params.id as string);
            }}
            aria-label="delete"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() =>
              handleDelete(params.id as string, params.row.weight as string)
            }
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      ),
    },
  ];

  // useEffect(() => {
  //   showTable();
  // }, []);

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Setup Enrichment Program
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Assessment Mapping
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Assessment Mapping
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Instiution
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setInstitutionDropValue(e.target.value as string)}
          >
            {institution.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Campus
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setCampusDropValue(e.target.value as string)}
          >
            {campus.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Academic Program
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setAcadProgValue(e.target.value as string)}
          >
            {acadProgList.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Track
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setTrackDropValue(e.target.value as string)}
          >
            {track.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography
            fontSize={16}
            color="#333333"
            variant="h5"
            gutterBottom
            mt={2}
          >
            Assessment Component
          </Typography>
          <Select
            fullWidth
            onChange={(e) => setAssesmentCompValue(e.target.value as string)}
          >
            {assesmentCompList.map((item) => (
              <MenuItem value={item.Code}>{item.Name}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button
          onClick={() => showTableData()}
          variant="contained"
          color="primary"
        >
          Search
        </Button>
      </Grid>

      <Divider />

      {showTable && (
        <Box mt={3}>
          <Grid display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h5" gutterBottom>
              Asessment Indicator
            </Typography>
            <Grid>
              <Button
                sx={{ marginRight: "10px" }}
                onClick={() => setModalAddNew(true)}
                variant="contained"
                color="primary"
              >
                Add New Indicator
              </Button>
              {dataTable.length > 0 && (
                <Button
                  onClick={() => setModalMaxWeight(true)}
                  variant="contained"
                  color="primary"
                >
                  Set Max Weight
                </Button>
              )}
            </Grid>
            <Modal
              open={modalAddNew}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ComponentCss.modalUploadMedStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontWeight="bold"
                >
                  Add New Indicator
                </Typography>
                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Institution
                </Typography>
                <OutlinedInput
                  onChange={(e) => setInstitutionValue(e.target.value)}
                  fullWidth
                  disabled
                  value={institutionDropValue}
                />

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Assesment Component
                </Typography>
                <OutlinedInput
                  onChange={(e) => setComponentValue(e.target.value)}
                  fullWidth
                  disabled
                  value={assesmentCompValue}
                />

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Track
                </Typography>
                <OutlinedInput
                  onChange={(e) => setTrackValue(e.target.value)}
                  fullWidth
                  disabled
                  value={trackDropValue}
                />

                {listStringArray.map((value, index) => (
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Typography
                        id="modal-modal-description"
                        fontSize={12}
                        mt={2}
                        mb={1}
                      >
                        Indicator
                      </Typography>
                      <OutlinedInput
                        fullWidth
                        value={value.indicator}
                        onChange={(e) => {
                          const newList = [...listStringArray];
                          newList[index].indicator = e.target.value;
                          setListStringArray(newList);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        id="modal-modal-description"
                        fontSize={12}
                        mt={2}
                        mb={1}
                      >
                        Weight
                      </Typography>
                      <OutlinedInput
                        fullWidth
                        value={value.weight}
                        type="number"
                        onChange={(e) => {
                          const newList = [...listStringArray];
                          newList[index].weight = e.target.value;
                          setListStringArray(newList);
                        }}
                      />
                    </Grid>
                    <Grid mt={5} item xs={1}>
                      {index >= 1 ? (
                        <IconButton
                          onClick={() => {
                            const list = [...listStringArray];
                            list.splice(index, 1);
                            setListStringArray(list);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <AddOutlined onClick={() => handleAddFields()} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Campus
                </Typography>
                <OutlinedInput
                  onChange={(e) => setCampusValue(e.target.value)}
                  fullWidth
                  disabled
                  value={campusDropValue}
                />

                <FormControl sx={{ marginTop: "16px" }} fullWidth size="small">
                  <InputLabel id="academicProgram-multiple-chip-label">
                    Academic Program
                  </InputLabel>
                  <Select
                    labelId="academicProgram-multiple-chip-label"
                    id="academicProgram-multiple-chip"
                    multiple
                    value={enrichmentTrack}
                    onChange={handleChangeSelect}
                    input={
                      <OutlinedInput
                        id="academicProgram-multiple-chip"
                        label="Academic Program"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    <MenuItem
                      key="all"
                      value="all"
                      onClick={() => setSelectAll(!selectAll)}
                    >
                      <Checkbox checked={selectAll} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {acadProgList.map((item) => (
                      <MenuItem key={item.Id} value={item.Code}>
                        <Checkbox
                          checked={enrichmentTrack.includes(item.Code)}
                        />
                        <ListItemText primary={item.Name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box mt={2} display="flex" justifyContent="end">
                  <Button
                    onClick={() => setModalAddNew(false)}
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={submitAddIndicator} variant="contained">
                    Save
                  </Button>
                </Box>
              </Box>
            </Modal>

            <Modal
              open={modalMaxWeight}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ComponentCss.modalUploadLowStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontWeight="bold"
                >
                  Max Weight
                </Typography>

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Component
                </Typography>
                <OutlinedInput fullWidth />

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Max Weigth
                </Typography>
                <OutlinedInput type="number" fullWidth />

                <Box mt={2} display="flex">
                  <Checkbox
                    checked={checkedUpdateAll}
                    onClick={handleChangeUpdateAll}
                    sx={{ width: "18px", height: "18px" }}
                  />
                  <Typography fontSize={14} ml={1}>
                    Update for All Academic Program
                  </Typography>
                </Box>

                <Box mt={2} display="flex" justifyContent="end">
                  <Button
                    onClick={() => setModalMaxWeight(false)}
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={submitMax}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Modal>

            <Modal
              open={modalEdit}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ComponentCss.modalUploadLowStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontWeight="bold"
                >
                  Edit Indicator
                </Typography>

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Indicator
                </Typography>
                <OutlinedInput
                  value={indicator}
                  onChange={(e) => setIndicator(e.target.value)}
                  fullWidth
                />

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Weight
                </Typography>
                <OutlinedInput
                  type="number"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  fullWidth
                />

                <Typography
                  id="modal-modal-description"
                  fontSize={12}
                  mt={2}
                  mb={1}
                >
                  Status
                </Typography>
                <Select
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  fullWidth
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>

                <Box mt={2} display="flex">
                  <Checkbox
                    checked={checkedUpdateAll}
                    onChange={handleChangeUpdateAll}
                    sx={{ width: "18px", height: "18px" }}
                  />
                  <Typography fontSize={14} ml={1}>
                    Update for All Academic Program
                  </Typography>
                </Box>

                <Box mt={2} display="flex" justifyContent="end">
                  <Button
                    onClick={() => setModalEdit(false)}
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={editIndicator} variant="contained">
                    Save
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Grid>

          <Paper elevation={3}>
            <CustomDataGrid
              columnsData={columns}
              rowsData={dataTable}
              rowCount={totalItems}
              getRowId={(row) => row.id}
              setPaginationModel={(value) => {
                setPaginationModel(value);
              }}
              paginationModel={paginationModel}
            />
          </Paper>
        </Box>
      )}
    </Container>
  );
}
