import { SxStyle } from "../../types/style";

const modalUploadLowStyle: SxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "6px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const modalUploadMedStyle: SxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "6px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const modalUploadBigStyle: SxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: "6px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export const ComponentCss = {
  modalUploadLowStyle,
  modalUploadMedStyle,
  modalUploadBigStyle,
};
