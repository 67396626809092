import { Box, Typography } from "@mui/material";
import { TextCompProps } from "../../types/text-comp";

export function TextComp({ title, bodyText }: TextCompProps) {
  return (
    <Box mb={3}>
      <Typography fontSize={12} color="#999999" variant="body2">
        {title}
      </Typography>
      <Typography gutterBottom fontSize={14} color="#333333">
        {bodyText}
      </Typography>
    </Box>
  );
}
