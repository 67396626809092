import { combineReducers } from '@reduxjs/toolkit';

import { authReducer } from './auth/slice';
import { authTokenReducer } from './authToken/slice';
import { profileReducer } from './profile/slice';
import evaluationReducer from './assesment-evaluation/reducer/evaluation.reducer';
import dropdownAcadCarrerReducer from './dropdown/acad-carrer/reducer/dropdown.reducer';
import dropdownAcadGroupReducer from './dropdown/acad-group/reducer/dropdown.reducer';
import dropdownAcadProgReducer from './dropdown/acad-prog/reducer/dropdown.reducer';
import dropdownCampusReducer from './dropdown/campus/reducer/dropdown.reducer';
import dropdownEvaluationComponentReducer from './dropdown/evaluation-component/reducer/dropdown.reducer';
import dropdownEvaluationMonthReducer from './dropdown/evaluation-month/reducer/dropdown.reducer';
import dropdownInstitutionReducer from './dropdown/institution/reducer/dropdown.reducer';
import dropdownTermReducer from './dropdown/term/reducer/dropdown.reducer';
import dropdownTrackReducer from './dropdown/track/reducer/dropdown.reducer';


export default combineReducers({
  auth: authReducer,
  authToken: authTokenReducer,
//   preset: presetsReducer,
  dropdownAcadCarrer:dropdownAcadCarrerReducer,
  dropdownAcadGroup:dropdownAcadGroupReducer,
  dropdownAcadProg:dropdownAcadProgReducer,
  dropdownCampus:dropdownCampusReducer,  
  dropdownEvaluationComponent:dropdownEvaluationComponentReducer,  
  dropdownEvaluationMonth:dropdownEvaluationMonthReducer,  
  dropdownInstitution:dropdownInstitutionReducer,
  dropdownTerm:dropdownTermReducer,
  dropdownTrack:dropdownTrackReducer,
  evaluation: evaluationReducer,
  profile: profileReducer,
});
