import { HeadCell } from "../../../components/table/types";

export interface AssesmentData {
    id: string;
    month: string;
    name: string;
    assesmentStatus: string;
    dueDate: string;
    entryDate: string;
    approvalDate: string;
    submissionDate: string;
    note: string;
    userType: string;
    action: string;
  }
  
  export const AssesmentHeadCells: readonly HeadCell<AssesmentData>[] = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },    
    { id: 'name', numeric: false, disablePadding: false, label: 'Student' },
    { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due Date' },
    { id: 'assesmentStatus', numeric: false, disablePadding: false, label: 'Assessment Status' },
    { id: 'entryDate', numeric: false, disablePadding: false, label: 'Entry Date' },
    { id: 'approvalDate', numeric: false, disablePadding: false, label: 'Approved Date' },
    { id: 'note', numeric: false, disablePadding: false, label: 'Note' },
    { id: 'submissionDate', numeric: false, disablePadding: false, label: 'Submission Date' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
  ];