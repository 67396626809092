/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { useRevokeRefreshToken } from '../api/identity';
// import { useDeleteDeviceNotification } from 'api/notification';
import SEO from '../components/common/seo';
// import cookie from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { persistor } from '../store';
import { selectAuth } from '../store/auth/selector';
import { setAuth } from '../store/auth/slice';
import { selectAuthToken } from '../store/authToken/selector';
// import { selectNotificationToken } from '../store/notification/selector';
import { loginStyle } from '../styles/container/login';

export function Logout() {
  const Navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();

//   const notificationToken = useSelector(selectNotificationToken);
//   const deleteDeviceNotification = useDeleteDeviceNotification({
//     onSuccess: () => {
//       revokeRefreshToken.mutate({
//         accessToken: authToken.myDashboardToken,
//         refreshToken: authToken.refreshToken,
//       });
//     },
//   });

  const revokeRefreshToken = useRevokeRefreshToken({
    onSuccess: () => {
    //   cookie.remove('token');
    //   cookie.remove('azureADToken');
    //   cookie.remove('refreshToken');
      persistor.purge();
      localStorage.clear();
      localStorage.clear();
      window.location.href = '/login';
    },
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Perform your task here
      if (!auth.user) Navigate('/login');
    //   deleteDeviceNotification.mutate(`${notificationToken}`);
          // revokeRefreshToken.mutate({
          //   accessToken: authToken.myDashboardToken,
          //   refreshToken: authToken.refreshToken,
          // });
    }, 4000);

    return () => {
      clearTimeout(timeoutId); // Cleanup the timer if the component unmounts
    };
  }, []);

  return (
    <>
      <SEO />
      {/* <Container maxWidth={false} disableGutters>
        <Stack component="section" sx={loginStyle.contentLogoutStack}>
          <Box sx={loginStyle.contentLogoutStackBox}>
            <Typography color="primary" sx={loginStyle.contentLogoutStackBoxTypography}>
              Logout is in progress. We will immediately redirect you to the login page...
            </Typography>
          </Box>
        </Stack>
      </Container> */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="100vh"
      >
        <CircularProgress size={16} />
        <Box ml={1}>Loading...</Box>
      </Box>
    </>
  );
}
