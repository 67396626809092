/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import apiClient from '../../config/api-client';
import { useQuery, useMutation } from '@tanstack/react-query';
import { UseQueryOptions, UseMutationOptions } from '../../types/react-query';
import { ApiEvaluationIndicator } from '../../constants/setup-enrichment-program/ApiEvaluationIndicator';
import { EvaluationIndicatorRequest, EvaluationIndicatorResponse, AddEvaluationIndicatorRequest, UpdateEvaluationIndicatorRequest } from '../../types/setup-enrichment-program/evaluation-indicator';

export const useMutationEvaluationIndicatorList = ({
  query,
  options,
  }: {
  query: { search?: string; pageSize?: number; pageNumber?: number; orderBy?: string; }
  options?: UseMutationOptions<EvaluationIndicatorResponse>
  }) =>
  useMutation(
    (data: EvaluationIndicatorRequest) =>
      apiClient
        .post<unknown, { data: EvaluationIndicatorResponse }>(`${ApiEvaluationIndicator.evaluationIndicatorList(query.search, query.pageSize, query.pageNumber, query.orderBy)}`, data)
        .then((res) => res.data),
    options
  );

export const useMutationAddEvaluationIndicator = (options?: UseMutationOptions) =>
  useMutation(
    (data: AddEvaluationIndicatorRequest) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEvaluationIndicator.addEvaluationIndicator}`, data)
        .then((res) => res.data),
    options,
  );

export const useMutationDeleteEvaluationIndicator = ({
  query,
  options,
  }: {
  query: { id: string; }
  options?: UseMutationOptions
  }) =>
  useMutation(
    () =>
      apiClient
        .post(`${ApiEvaluationIndicator.deleteEvaluationIndicator(query.id)}`)
        .then((res) => res.data),
    options
  );

export const useMutationUpdateEvaluationIndicator = ({
  query,
  options,
  }: {
  query: { id: string; }
  options?: UseMutationOptions
  }) =>
  useMutation(
    (data: UpdateEvaluationIndicatorRequest) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiEvaluationIndicator.updateEvaluationIndicator(query.id)}`, data)
        .then((res) => res.data),
    options,
  );