import { HeadCell } from "../../../components/table/types";

export interface AssesmentApprovalData {
    id: string;
    track: string;
    corrector: string;
    student: string;    
    action: string;
  }
  
  export const AssesmentHeadCells: readonly HeadCell<AssesmentApprovalData>[] = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },    
    { id: 'track', numeric: false, disablePadding: false, label: 'Track' },
    { id: 'corrector', numeric: false, disablePadding: false, label: 'Corrector' },
    { id: 'student', numeric: false, disablePadding: false, label: 'Student' },    
    // { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
  ];