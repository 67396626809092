/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import { useMutation } from "@tanstack/react-query";
import apiClient from "../../config/api-client";
import { ApiEvaluationMapping } from "../../constants/setup-enrichment-program/ApiEvaluationMapping";
import { UseMutationOptions } from "../../types/react-query";
import {
  AddEvaluationMappingRequest,
  DeleteEvaluationMappingRequest,
  EvaluationMappingRequest,
  EvaluationMappingResponse,
} from "../../types/setup-enrichment-program/evaluation-mapping";

export const useMutationEvaluationMappingList = ({
  query,
  options,
}: {
  query: {
    search?: string;
    pageSize?: number;
    pageNumber?: number;
    orderBy?: string;
  };
  options?: UseMutationOptions<EvaluationMappingResponse>;
}) =>
  useMutation(
    (data: EvaluationMappingRequest) =>
      apiClient
        .post<unknown, { data: EvaluationMappingResponse }>(
          `${ApiEvaluationMapping.getEvaluationMapping(
            query.search,
            query.pageSize,
            query.pageNumber,
            query.orderBy
          )}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationDeleteEvaluationMapping = ({
  options,
}: {
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: DeleteEvaluationMappingRequest) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiEvaluationMapping.deleteEvaluationMapping}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationAddNewEvaluationIndicator = (
  options?: UseMutationOptions
) =>
  useMutation(
    (data: AddEvaluationMappingRequest) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiEvaluationMapping.addEvaluationMapping}`,
          data
        )
        .then((res) => res.data),
    options
  );
