import { Route } from "../types/route";
import { Dashboard } from "../pages/dashboard";
import { UserRole } from "../pages/user-management/role/UserRole";
import { AddNewRole } from "../pages/user-management/role/AddNewRole";
import { EditRole } from "../pages/user-management/role/EditRole";
import { UserMapping } from "../pages/user-management/user-mapping/UserMapping";
import { AddUserMapping } from "../pages/user-management/user-mapping/AddUserMapping";
import { EditUserMapping } from "../pages/user-management/user-mapping/EditUserMapping";
import { StudentTrackRegistration } from "../pages/student_registration/StudentTrackRegistration";
import { ChangeStudentTrack } from "../pages/student_registration/ChangeStudentTrack";
import { DropStudentTrack } from "../pages/student_registration/DropStudentTrack";
import { StudentPeriodMapping } from "../pages/student_registration/StudentPeriodMapping";
import { UpdateProfile } from "../pages/enrichment/UpdateProfile";
import { EditProfile } from "../pages/enrichment/EditProfile";
import { ChooseEnrichmentTrack } from "../pages/enrichment/ChooseEnrichmentTrack";
import { EnrichmentApproval } from "../pages/enrichment-approval/EnrichmentApproval";
import { TransferAssesmentComponent } from "../pages/setup-enrichment-program/TransferAssesmentComponent";
import { EvaluationComponent } from "../pages/setup-enrichment-program/EvaluationComponent";
import { AsessmentMapping } from "../pages/setup-enrichment-program/AsessmentMapping";
import { EvaluationIndicator } from "../pages/setup-enrichment-program/EvaluationIndicator";
import { EvaluationMapping } from "../pages/setup-enrichment-program/EvaluationMapping";
import { SendApprovalKey } from "../pages/setup-enrichment-program/SendApprovalKey";
import { SetDueDate } from "../pages/setup-enrichment-program/SetDueDate";
import { GenerateGradeFall } from "../pages/setup-enrichment-program/GenerateGradeFall";
import { TransferGradeToBcs } from "../pages/setup-enrichment-program/TransferGradeToBcs";
import { Assesment } from "../pages/assesment-evaluation/Assesment/Assesment";
import { AssesmentDetail } from "../components/dialog/assesment-evaluation/assesment/AssesmentDetail";
import { AssesmentForm } from "../components/dialog/assesment-evaluation/assesment/AssesmentForm";
import { AssesmentEntry } from "../pages/assesment-evaluation/Assesment/_partials/Entry/Entry";
import { Evaluation } from "../pages/assesment-evaluation/Evaluation/Evaluation";
import { EvaluationDetail } from "../components/dialog/assesment-evaluation/evaluation/EvaluationDetail";
import { EvaluationForm } from "../components/dialog/assesment-evaluation/evaluation/EvaluationForm";
import { EvaluationEntry } from "../pages/assesment-evaluation/Evaluation/_partials/Entry/Entry";
import { AssesmentApproval } from "../pages/assesment-evaluation-approval/Assesment/Assesment";
import { EvaluationApproval } from "../pages/assesment-evaluation-approval/Evaluation/Evaluation";
import { Registration } from "../pages/streaming/Registration";
import { Result } from "../pages/streaming/Result";

export const protectedRoutes: Route[] = [
  {
    key: "router-dashboard",
    title: "Dashboard",
    description: "Dashboard",
    component: Dashboard,
    path: "/dashboard",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-user-role",
    title: "User Role",
    description: "User Role",
    component: UserRole,
    path: "/user-management/user-role",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-user-add",
    title: "Add New Role",
    description: "Add New Role",
    component: AddNewRole,
    path: "/user-management/add-new-role",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-user-edit",
    title: "Add New Role",
    description: "Add New Role",
    component: EditRole,
    path: "/user-management/edit-role",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-user-mapping",
    title: "User Mapping",
    description: "User Mapping",
    component: UserMapping,
    path: "/user-management/user-mapping",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-add-user-mapping",
    title: "Add User Mapping",
    description: "Add User Mapping",
    component: AddUserMapping,
    path: "/user-management/add-user-mapping",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-edit-user-mapping",
    title: "Edit User Mapping",
    description: "Edit User Mapping",
    component: EditUserMapping,
    path: "/user-management/edit-user-mapping",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-student-track-registration",
    title: "Student Track Registration",
    description: "Student Track Registration",
    component: StudentTrackRegistration,
    path: "/student-registration/student-track-registration",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-drop-student-track",
    title: "Drop Student Track",
    description: "Drop Student Track",
    component: DropStudentTrack,
    path: "/student-registration/drop-student-track",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-change-student-track",
    title: "Change Student Track",
    description: "Change Student Track",
    component: ChangeStudentTrack,
    path: "/student-registration/change-student-track",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-student-period-mapping",
    title: "Student Period Mapping",
    description: "Student Period Mapping",
    component: StudentPeriodMapping,
    path: "/student-registration/student-period-mapping",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-update-profile",
    title: "Update Profile",
    description: "Update Profile",
    component: UpdateProfile,
    path: "/enrichment/update-profile",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-edit-profile",
    title: "Edit Profile",
    description: "Edit Profile",
    component: EditProfile,
    path: "/enrichment/edit-profile",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-choose-enrichment-track",
    title: "Choose Enrichment Track",
    description: "Choose Enrichment Track",
    component: ChooseEnrichmentTrack,
    path: "/enrichment/choose-enrichment-track",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-enrichment-approval",
    title: "Enrichment Approval",
    description: "Enrichment Approval",
    component: EnrichmentApproval,
    path: "/enrichment-approval",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-transfer-asessment-component",
    title: "Transfer Asessment Component",
    description: "Transfer Asessment Component",
    component: TransferAssesmentComponent,
    path: "/setup-enrichment-program/transfer-asessment-component",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-asessment-mapping",
    title: "Asessment Mapping",
    description: "Asessment Mapping",
    component: AsessmentMapping,
    path: "/setup-enrichment-program/asessment-mapping",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-component",
    title: "Evaluation Component",
    description: "Evaluation Component",
    component: EvaluationComponent,
    path: "/setup-enrichment-program/evaluation-component",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-indicator",
    title: "Evaluation Indicator",
    description: "Evaluation Indicator",
    component: EvaluationIndicator,
    path: "/setup-enrichment-program/evaluation-indicator",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-mapping",
    title: "Evaluation Mapping",
    description: "Evaluation Mapping",
    component: EvaluationMapping,
    path: "/setup-enrichment-program/evaluation-mapping",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-set-due-date",
    title: "Set Due Date",
    description: "Set Due Date",
    component: SetDueDate,
    path: "/setup-enrichment-program/set-due-date",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-send-approval-key",
    title: "Send Approval Key",
    description: "Send Approval Key",
    component: SendApprovalKey,
    path: "/setup-enrichment-program/send-approval-key",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-transfer-grade-to-bcs",
    title: "Transfer Grade To BCS",
    description: "Transfer Grade To BCS",
    component: TransferGradeToBcs,
    path: "/setup-enrichment-program/transfer-grade-to-bcs",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-generate-grade-fall",
    title: "Generate Grade Fall",
    description: "Generate Grade Fall",
    component: GenerateGradeFall,
    path: "/setup-enrichment-program/generate-grade-fall",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-assesment-main",
    title: "Assesment",
    description: "Assesment Data",
    component: Assesment,
    path: "/assesment-evaluation/assesment",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-assesment-entry",
    title: "Assesment Entry",
    description: "Assesment Entry",
    component: AssesmentEntry,
    path: "/assesment-evaluation/assesment/entry",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-assesment-detail",
    title: "Assesment Detail",
    description: "Assesment Detail",
    component: AssesmentDetail,
    path: "/assesment-evaluation/assesment/assesment-detail",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-assesment-form",
    title: "Assesment Form",
    description: "Assesment Form",
    component: AssesmentForm,
    path: "/assesment-evaluation/assesment/assesment-form",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-main",
    title: "Evaluation",
    description: "Evaluation Data",
    component: Evaluation,
    path: "/assesment-evaluation/evaluation",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-entry",
    title: "Evaluation Entry",
    description: "Evaluation Entry",
    component: EvaluationEntry,
    path: "/assesment-evaluation/evaluation/entry",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-detail",
    title: "Assesment Detail",
    description: "Assesment Detail",
    component: AssesmentDetail,
    path: "/assesment-evaluation/evaluation/evaluation-detail",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-form",
    title: "Evaluation Form",
    description: "Evaluation Form",
    component: AssesmentForm,
    path: "/assesment-evaluation/evaluation/evaluation-form",
    isEnabled: true,
    appendDivider: true,
  },  
  {
    key: "router-evaluation-approval-main",
    title: "Assesment",
    description: "Assesment Approval Data",
    component: AssesmentApproval,
    path: "/assesment-evaluation-approval/assesment",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-evaluation-approval-main",
    title: "Evaluation Approval",
    description: "Evaluation Approval Data",
    component: EvaluationApproval,
    path: "/assesment-evaluation-approval/evaluation",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-set-due-date",
    title: "Set Due Date",
    description: "Set Due Date",
    component: SetDueDate,
    path: "/setup-enrichment-program/set-due-date",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-send-approval-key",
    title: "Send Approval Key",
    description: "Send Approval Key",
    component: SendApprovalKey,
    path: "/setup-enrichment-program/send-approval-key",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "router-generate-grade-fall",
    title: "Generate Grade Fall",
    description: "Generate Grade Fall",
    component: GenerateGradeFall,
    path: "/setup-enrichment-program/generate-grade-fall",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "registration",
    title: "Registration",
    description: "Registration",
    component: Registration,
    path: "/streaming/registration",
    isEnabled: true,
    appendDivider: true,
  },
  {
    key: "result",
    title: "Result",
    description: "Result",
    component: Result,
    path: "/streaming/result",
    isEnabled: true,
    appendDivider: true,
  },
];