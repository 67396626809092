import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  MenuItem,
  OutlinedInput,
  Stack,
  TextareaAutosize,
  Typography,
  TextField,
  InputLabel,
} from "@mui/material";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import {
  useMutationSubmitChangeStudentTrack,
  useQueryGetAssignPartnerDropDown,
  useQueryGetStudentTrackChangeDrop,
  useQueryGetTermDropDown,
  useQueryGetTrackDropDown,
} from "../../api/student-registration";
import useModal from "../../hooks/use-modal";
import {
  AssigmentPartnerData,
  StudentTrackChangeDropData,
  TermDataList,
  TrackData,
} from "../../types/student-registration";
import { Link } from "react-router-dom";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DropdownResponseData } from "../../types/dropdown";
import {
  useQueryAcadGroup,
  useQueryAcadProg,
  useQueryDropDownAdmitTerm,
  useQueryListAcadCareerDropDown,
  useQueryListCampusLocationDropDown,
  useQueryListInstitutionDropDown,
} from "../../api/dropdown";

export function ChangeStudentTrack() {
  const { showModal } = useModal();
  const [dataTrue, setDataTrue] = useState(false);
  const [listTrack, setListTrack] = useState<TrackData[]>([]);
  const [listAssign, setListAssign] = useState<AssigmentPartnerData[]>([]);
  const [assignValue, setAssignValue] = useState("");
  const [trackValue, setTrackValue] = useState("");
  const [reason, setReason] = useState("");
  const [oldTrack, setOldTrack] = useState("");
  const [nim, setNim] = useState("");
  const [termSelect, setTermSelect] = useState("");
  const [admitTermSelect, setAdmitTermSelect] = useState("");
  const [dataStudentTrack, setDataStudentTrack] = useState<StudentTrackChangeDropData>({} as StudentTrackChangeDropData);

  const [showBox, setShowBox] = useState(false);

  // Dropdown Data
  const [listTerm, setListTerm] = useState<TermDataList[]>([]);
  const [dropInstitution, setDropInstitution] = useState<
    DropdownResponseData[]
  >([]);
  const [dropCampus, setDropCampus] = useState<DropdownResponseData[]>([]);
  const [listAdmitTerm, setListAdmitTerm] = useState<DropdownResponseData[]>(
    []
  );
  const [dropAcadCareer, setDropAcadCareer] = useState<DropdownResponseData[]>(
    []
  );
  const [dropAcadGroup, setDropAcadGroup] = useState<DropdownResponseData[]>(
    []
  );
  const [dropAcadProg, setDropAcadProg] = useState<DropdownResponseData[]>([]);
  const [dropAcadPlan, setDropAcadPlan] = useState<DropdownResponseData[]>([]);

  // Value Drop Down
  const [termValue, setTermValue] = useState("");
  const [institutionValue, setInstitutionValue] = useState("");
  const [campusValue, setCampusValue] = useState("");
  const [acadCareerValue, setAcadCareerValue] = useState("");
  const [acadGroupValue, setAcadGroupValue] = useState("");
  const [acadProgValue, setAcadProgValue] = useState("");
  const [acadPlanValue, setAcadPlanValue] = useState("");

  const getInstitution = useQueryListInstitutionDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setDropInstitution(res.data || []);
        setInstitutionValue(res.data[0].Code);
      },
      onError: () => {},
    },
  });

  const getAcadCareer = useQueryListAcadCareerDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setDropAcadCareer(res.data || []);
        setAcadCareerValue("OS1");
      },
      onError: () => {},
    },
  });

  const getStudentTrack = useQueryGetStudentTrackChangeDrop({
    query: {
      studentId: nim,
    },
    options: {
      enabled: false,
      retry: false,
      onSuccess: (res) => {
        setDataStudentTrack(res.data);
        setOldTrack(res.data?.track || "");
        setShowBox(true);
      },
      onError: (err) => {
        setShowBox(false);
        showModal({
          title: "Failed",
          message:
            (err.response?.data as { message?: string })?.message ??
            "An error occurred",
          options: {
            buttonTitle: "Ok",
            variant: "failed",
          },
        });
      },
    },
  });

  const handleSubmitSearch = () => {
    getStudentTrack.refetch();
  };

  const submitTrackRegIndividual = useMutationSubmitChangeStudentTrack({
    query: {
      institution: dataStudentTrack.institution,
      campus: dataStudentTrack.campus,
      acadCareer: dataStudentTrack.acadCareer,
      acadGroup: dataStudentTrack.acadGroup,
      acadProg: dataStudentTrack.acadProg,
      acadPlan: dataStudentTrack.acadPlan,
      studentId: nim,
      period: dataStudentTrack.term,
      acadOrg: dataStudentTrack.acadOrg,
      admitTerm: dataStudentTrack.admitTerm,
    },
    options: {
      onSuccess: async (res: any) => {
        setTrackValue("");
        setAssignValue("");
        setReason("");
        setShowBox(false)
        showModal({
          title: "Success",
          message: `Change student track success!`,
          options: {
            buttonTitle: "Ok",
            variant: "success",
          },
        });
      },
      onError: async (err) => {
        setTrackValue("");
        setAssignValue("");
        setReason("");
        showModal({
          title: "Failed",
          message: err.response?.data.message || `Change student is failed.`,
          options: {
            buttonTitle: "Ok",
            variant: "failed",
          },
        });
      },
    },
  });

  const getTrack = useQueryGetTrackDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setListTrack(res.data || []);
      },
      onError: () => {},
    },
  });

  const getAssign = useQueryGetAssignPartnerDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: (res) => {
        setListAssign(res.data || []);
      },
      onError: () => {},
    },
  });

  const submitIndividualStudentTrack = async () => {
    submitTrackRegIndividual.mutate({
      track: trackValue,
      reAssignPartner: assignValue,
      reason,
    });
  };

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Student Registration
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Change Student Track
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Change Student Track
      </Typography>

      <Divider />

      <Box mt={4} mb={2}>
        <Grid container spacing={2}>
          <Grid mb={2} xs={6} item>
            <FormControl fullWidth size="small">
              <InputLabel id="institution-select-label">Institution</InputLabel>
              <Select
                disabled
                labelId="institution-select-label"
                id="institution-select"
                label="Institution"
                value={institutionValue}
                onChange={(e) => {
                  setInstitutionValue(e.target.value as string);
                }}
              >
                {dropInstitution.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid mb={2} xs={6} item>
            <FormControl fullWidth size="small">
              <InputLabel id="academicCareer-select-label">Academic Career</InputLabel>
              <Select
                disabled
                labelId="academicCareer-select-label"
                id="academicCareer-select"
                label="academicCareer"
                value={acadCareerValue}
                onChange={(e) => {
                  setAcadCareerValue(e.target.value as string);
                }}
              >
                {dropAcadCareer.map((item) => (
                  <MenuItem value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small" 
              id="studentId"
              label={
                <>
                  Student ID<Typography component="span" color="error">*</Typography>
                </>
              } 
              variant="outlined"
              value={nim}
              onChange={(e) => setNim(e.target.value)}
            />
          </Grid>

          <Grid display="flex" justifyContent="end" xs={12} item>
            <Box component="div">
              <Button onClick={handleSubmitSearch} variant="contained">
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {showBox && <Divider />}

      <Box display={!showBox ? "none" : ""} mt={2} mb={2} component="div">
        <Typography variant="h6" mb={2}>
          Showing Form for Term : {dataStudentTrack.termDesc}
        </Typography>

        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="studentId"
              label="Student ID"
              variant="outlined"
              value={dataStudentTrack.externalSystemId || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="studentName"
              label="Student Name"
              variant="outlined"
              value={dataStudentTrack.name  || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="faculty/school"
              label="Faculty / School"
              variant="outlined"
              value={dataStudentTrack.campusDesc  || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="academicProgram"
              label="Academic Program"
              variant="outlined"
              value={dataStudentTrack.acadOrgDesc || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl disabled fullWidth size="small">
              <InputLabel id="oldTrack-select-label">Old Track</InputLabel>
              <Select
                labelId="oldTrack-select-label"
                id="oldTrack-select"
                label="Old Track"
                value={oldTrack}
              >
                {listTrack.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="newTrack-select-label">New Track<span style={{ color: "red" }}>*</span></InputLabel>
              <Select
                labelId="newTrack-select-label"
                id="newTrack-select"
                label="New Track"
                value={trackValue}
                onChange={(e) => {
                  setTrackValue(e.target.value as string);
                }}
              >
                {listTrack.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="currentJob"
              label="Current Job"
              variant="outlined"
              value={dataStudentTrack.currentJob || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              size="small" 
              id="position"
              label="Position"
              variant="outlined"
              value={dataStudentTrack.position || "-"}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="reassignPartner-select-label">Re-Assign Partner<span style={{ color: "red" }}>*</span></InputLabel>
              <Select
                labelId="reassignPartner-select-label"
                id="reassignPartner-select"
                label="Re-Assign Partner"
                value={assignValue}
                onChange={(e) => {
                  setAssignValue(e.target.value as string);
                }}
              >
                {listAssign.map((item) => (
                  <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="reason"
              label={
                <>Reason <span style={{ color: "red" }}>*</span></>
              }
              variant="outlined"
              multiline
              rows={4}
              placeholder="Type here"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>

          <Grid display="flex" justifyContent="end" xs={12} item>
            <Button onClick={submitIndividualStudentTrack} variant="contained">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
