import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ApiService, azureFunctionCode } from '../../../../constants/ApiService.Dev';

export const evaluationMonth = createAsyncThunk(
  'dropdown/evaluationMonth',
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `${ApiService.apiPostman}/DropDownEvaluationMonth?code=${azureFunctionCode}`;
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token?.replace(/"/g, '')}`,
        },
      });      
      return response.data;
    } catch (error: any) {
      return axios.isAxiosError(error)
        ? thunkAPI.rejectWithValue(error.message)
        : thunkAPI.rejectWithValue('An unknown error occurred');
    }
  }
);
