import { ApiService, azureFunctionCode } from "../ApiService";

export const ApiEvaluationMapping = {
  getEvaluationMapping: (
    search?: string,
    pageSize?: number,
    pageNumber?: number,
    orderBy?: string
  ) =>
    `${ApiService.identity}/GetEvaluationMapping?code=${azureFunctionCode}&search=${search}&pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}`,
  deleteEvaluationMapping: `${ApiService.identity}/DropEvaluationMapping?code=${azureFunctionCode}`,
  addEvaluationMapping: `${ApiService.identity}/AddEvaluationMapping?code=${azureFunctionCode}`,
};
