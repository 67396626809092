import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../../config/api-client";
import { UseMutationOptions, UseQueryOptions } from "../../types/react-query";
import { GetIndividual } from "../../types/student-registration";
import {
  AddNewIndicatorPayload,
  MaxWeightPayload,
  ShowTableAssesmentMappingPayload,
  UpdateIndicatorPayload,
} from "../../types/setup-enrichment-program/assesment-mapping";
import { ApiAsessmentMapping } from "../../constants/setup-enrichment-program/ApiAsessmentMapping";
import { EditIndicator, RoleListDropdownResponse } from "../../types/user-role";
import {
  TableGenerateDueDatePayload,
  TableSetDueDatePayload,
  TableSetDueDateSavePayload,
  TableUpdateDueDatePayload,
  TableUpdateDueDateSavePayload,
} from "../../types/setup-enrichment-program/set-due-date";

export const useMutationShowTableSetDueDate = (options?: UseMutationOptions) =>
  useMutation(
    (data: TableSetDueDatePayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.showTableSetDueDate}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationShowTableUpdateDueDate = (
  options?: UseMutationOptions
) =>
  useMutation(
    (data: TableUpdateDueDatePayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.showTableUpdateDueDate}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationGenerateDueDate = (options?: UseMutationOptions) =>
  useMutation(
    (data: TableGenerateDueDatePayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.generateDueDate}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationUpdateDueDateSave = (options?: UseMutationOptions) =>
  useMutation(
    (data: TableUpdateDueDateSavePayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.updateDueDateSave}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationSetDueDateSave = (options?: UseMutationOptions) =>
  useMutation(
    (data: TableSetDueDateSavePayload) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiAsessmentMapping.setDueDateSave}`,
          data
        )
        .then((res) => res.data),
    options
  );
