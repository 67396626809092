import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup'
import { breadCrumbLink } from "../../../../../styles/common/bread-crumb-link";
import { secondTableData, evaluationData } from '../../data';
import { AssesmentDetail } from "../../../../../components/dialog/assesment-evaluation/assesment/AssesmentDetail";
import { Order } from "../../../../../components/table/types";
import EnhancedTableHeadCheckbox from "../../../../../components/table/enhance-table-head-checbox";
import { getComparator, stableSort } from "../../../../../components/table/utils";
import { AssesmentData, AssesmentHeadCells } from "../../../../../types/assesment-evaluation/assesment/assesment-type";
import axios from "axios";
import { EvaluationEntryData } from "../../../../../types/assesment-evaluation/evaluation/evaluation-type";
import { fetchEvaluationDataById } from "../../../../../store/assesment-evaluation/action/evaluation.action";
import { AppDispatch, RootState } from "../../../../../store";
import Avatar from "react-avatar";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  // pagesWorker: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

export function EvaluationEntry() {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState<EvaluationEntryData>();
  const [selected, setSelected] = useState<string[]>([]);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [pages, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [searchInput, setSearchInput] = useState<any>('');
  const [pagesTab, setPagesTab] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const myId = queryParams.get('id');

  const { data, loading, error } = useSelector((state: RootState) => state.evaluation);

  useEffect(() => {
    dispatch(fetchEvaluationDataById({ id: myId }));
  }, [dispatch, myId]);

  useEffect(() => {
    console.log(data)
    setRows(data);
  }, [data]);


  // Function to handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPagesTab(newValue);
  };


  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleDetailClick = (id: string) => () => {
    navigate(`detail/${id}`);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Assesment & Evaluation
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Evaluation
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Entry Evaluation
      </Typography>

      <Paper
        sx={{
          p: '24px 16px',
          mt: 3,
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
          borderRadius: 1,
          overflow: 'auto'
        }}
      >
        <Grid container sx={{ alignItems: 'center', mb: 2 }} spacing={1}>
          <Grid item>
            <Typography
              variant="h5"
              component="div"
              fontWeight={700}
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              View and entry evaluation form
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            {rows?.photo ? (
              <img
                src={`data:image/jpeg;charset=utf-8;base64,${rows.photo}`}
                alt="user profile"
                style={{ borderRadius: 6 }}
                width={125}
                height={125}
              />
            ) : (
              <Avatar
                name={rows?.name || 'User'}
                size="125"
                round
                style={{ borderRadius: 6 }}
              />
            )}
          </Grid>
          <Grid item md={10}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Institution
                </Typography>
                <Typography variant='body2'>
                  {rows?.institution !== null ? rows?.institution : "-"}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Track
                </Typography>
                <Typography variant='body2'>
                  {rows?.trackDesc !== null ? rows?.trackDesc : "-"}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Academic Career
                </Typography>
                <Typography variant='body2'>
                  {rows?.acadCareerDesc !== null ? rows?.acadCareerDesc : "-"}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  Term
                </Typography>
                <Typography variant='body2'>
                  {rows?.termDesc !== null ? rows?.termDesc : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant='body1' color='#5E5873' fontWeight={700} sx={{ marginTop: 2 }}>
          {rows?.name}
        </Typography>
        <Typography variant='subtitle2' color='#5E5873'>
          {rows?.externalSystemId !== null ? rows?.externalSystemId : "-"}
        </Typography>
      </Paper>

      <Paper
        sx={{
          p: '24px 16px',
          mt: 3,
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
          borderRadius: 1,
          overflow: 'auto',
        }}
      >

        {rows?.evaluationMapping?.length === 0 ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              No data available
            </Typography>
          </Box>
        ) : (
          rows?.evaluationMapping.map((detail, detailIndex) => (
            <Box key={detail.id} sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {detail.desc}
              </Typography>
              {detail.indicator.map((comp, compIndex) => (
                <Box key={comp.id} sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {comp.name}
                  </Typography>
                  {/* Render multiple text fields */}
                  <TextField
                    label="Enter value"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={comp.note}
                    margin="normal"
                  // Add onChange handler as needed
                  />
                </Box>
              ))}
            </Box>
          ))
        )}

        <Box columnGap={2} display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>

          <Button variant="contained" color="secondary" sx={{ ml: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" sx={{ ml: 2 }}>
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};