import {
  Container,
  Stack,
  Typography,
  Breadcrumbs,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField
} from "@mui/material";
import {
  DropDownFacultyData,
  DropDownProgramData,
  DropDownRoleListData,
  DropDownStatusData,
} from "../../../types/user-mapping";
import { useState } from "react";
import {
  useMutationAddUserMapping,
  useQueryFacultyDropDown,
  useQueryListRoleUserMappingDropDown,
  useQueryProgramDropDown,
  useQueryStatusDropDown,
} from "../../../api/user-management";
import { breadCrumbLink } from "../../../styles/common/bread-crumb-link";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DropDownCampusLocationResponseData } from "../../../types/dropdown";
import { useQueryListCampusLocationDropDown } from "../../../api/dropdown";
import useModal from "../../../hooks/use-modal";

export function AddUserMapping() {
  const navigate = useNavigate();
  const { showModal } = useModal();

  const [listCampusDropDown, setListCampusDropDown] = useState<
    DropDownCampusLocationResponseData[]
  >([]);
  const [listRole, setListRole] = useState<DropDownRoleListData[]>([]);
  const [listFaculty, setListFaculty] = useState<DropDownFacultyData[]>([]);
  const [listStatus, setListStatus] = useState<DropDownStatusData[]>([]);
  const [listProgram, setListProgram] = useState<DropDownProgramData[]>([]);

  const [campusLocationValue, setCampusLocationValue] = useState("");
  const [listRoleValue, setListRoleValue] = useState("");
  const [facultyValue, setFacultiyValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [programValue, setProgramValue] = useState("");
  const [email, setEmail] = useState("");

  const getDropDownRole = useQueryListRoleUserMappingDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListRole(res.data || []);
      },
      onError: (err) => {
      },
    },
  });

  const getDropDownCampusLocation = useQueryListCampusLocationDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListCampusDropDown(res.data || []);
      },
      onError: (err) => {
      },
    },
  });

  const getDropDownFaculty = useQueryFacultyDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListFaculty(res.data || []);
      },
      onError: (err) => {
      },
    },
  });

  const getDropDownStatus = useQueryStatusDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListStatus(res.data || []);
      },
      onError: (err) => {
      },
    },
  });

  const getDropDownProgram = useQueryProgramDropDown({
    query: {},
    options: {
      // enabled: false,
      onSuccess: async (res) => {
        setListProgram(res.data || []);
      },
      onError: (err) => {
      },
    },
  });

  const submitAddUserMapping = useMutationAddUserMapping({
    onSuccess: async (res: any) => {
      showModal({
        title: "Success",
        message: "Add user mapping success!",
        options: {
          variant: "success",
          onOk() {
            navigate("/user-management/user-mapping");
          },
        },
      });
    },
    onError: async (err: any) => {
      showModal({
        title: "Failed",
        message: err?.response?.data?.details,
        options: {
          variant: "failed",
        },
      });
    },
  });

  const submitAdd = () => {
    submitAddUserMapping.mutate({
      email,
      roleId: listRoleValue,
      campus: campusLocationValue,
      faculty: facultyValue,
      status: statusValue,
      program: programValue,
      // program: "CSCI"
    });
  };

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            User Management
          </Link>
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            User Mapping
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Add User Mapping
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2}>
        Add User Mapping
      </Typography>

      <Divider />

      <Grid container columnSpacing={2} rowSpacing={4} mt={1}>
        <Grid item xs={6}>
          <TextField 
            fullWidth
            size="small" 
            id="email"
            label="Email" 
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              value={listRoleValue}
              label="Role"
              onChange={(e) => setListRoleValue(e.target.value as string)}
            >
              {listRole.map(item => (
                <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="campusLocation-select-label">Campus Location</InputLabel>
            <Select
              labelId="campusLocation-select-label"
              id="campusLocation-select"
              value={campusLocationValue}
              label="Campus Location"
              onChange={(e) => setCampusLocationValue(e.target.value as string)}
            >
              {listCampusDropDown.map(item => (
                <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="faculty-select-label">Faculty</InputLabel>
            <Select
              labelId="faculty-select-label"
              id="faculty-select"
              value={facultyValue}
              label="Faculty"
              onChange={(e) => setFacultiyValue(e.target.value as string)}
            >
              {listFaculty.map(item => (
                <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="program-select-label">Program</InputLabel>
            <Select
              labelId="program-select-label"
              id="program-select"
              value={programValue}
              label="Program"
              onChange={(e) => setProgramValue(e.target.value as string)}
            >
              {listProgram.map(item => (
                <MenuItem key={item.Code} value={item.Code}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={statusValue}
              label="Status"
              onChange={(e) => setStatusValue(e.target.value as string)}
            >
              {listStatus.map(item => (
                <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid display="flex" justifyContent="end" mt={2} mb={2}>
        <Button onClick={() => submitAdd()} variant="contained" color="primary">
          Save
        </Button>
      </Grid>
      <Divider />
    </Container>
  );
}
