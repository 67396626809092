import { SxStyle } from "../../types/style";

const tableCss: SxStyle = {
  border: "1px solid #d3d3d3",
  borderRadius: "10px",
  minHeight: "50vh",
};

const boxCssActive: SxStyle = {
  background: "#E0F9FC",
  padding: "16px",
  margin: "16px",
  borderRadius: "10px",
  cursor: "pointer",
};

const boxCssInactive: SxStyle = {
  padding: "16px",
  margin: "16px",
  borderRadius: "10px",
  cursor: "pointer",
};

const gridList: SxStyle = {
  background: "white",
  borderRight: "1px solid #d3d3d3",
  borderRadius: "10px 0px 0px 10px",
};

export const StreamingCss = {
  tableCss,
  boxCssActive,
  boxCssInactive,
  gridList,
};
