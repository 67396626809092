/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */

import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Collapse,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import EnrichmentIcon from "@mui/icons-material/Assignment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import StudentRegistrationIcon from "@mui/icons-material/PersonAddAlt";
import SpeedIcon from "@mui/icons-material/Speed";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";

import { motion } from "framer-motion";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AdminMenuProps } from "../../../types/admin-menu";
import { NavbarResponse } from "../../../types/user-role";
import { useQueryGetProfileForValidateEnrichment } from "../../../api/enrichment-track";
import { useQueryListNavbar } from "../../../api/user-management";
import { selectAuthUser } from "../../../store/auth/selector";
import { selectProfilePrivileges } from "../../../store/profile/selector";

import { layoutPrivateStyle } from "../../../styles/layout/private-routes";

export function SideMenuAdmin({
  adminMenu,
  setAdminMenu,
  activeMenu,
  setActiveMenu,
}: AdminMenuProps) {
  const listPrivileges = useSelector(selectProfilePrivileges);
  const profile = useSelector(selectAuthUser);
  const Navigate = useNavigate();

  const [openCollapse, setOpenCollapse] = useState<string[]>([]);
  const isSmall = useMediaQuery("(min-width: 600px)");
  const [modulOpen, setModulOpen] = useState(adminMenu);
  const [validateForEnrichment, setValidateForEnrichment] = useState(false);
  const [listRole, setListRole] = useState<NavbarResponse[]>([]);

  const userData = JSON.parse(localStorage.getItem("userData") ?? "{}");

  useEffect(() => {
    if (
      window.location.pathname === "/manage-presets/define-preset" ||
      window.location.pathname === "/dashboard/editor"
    ) {
      setModulOpen(true);
      setAdminMenu?.(true);
    }
    setActiveMenu?.(window.location.pathname);
  }, [window.location.pathname]);

  const sideMenuModel = {
    initial1: {
      height: "100%",
      width: "240px",
    },
    initial2: {
      height: "100%",
      width: "52px",
    },
    animate1: { width: adminMenu ? "52px" : "240px" },
    animate2: { width: adminMenu ? "240px" : "52px" },
  };

  const variantSideMenu = isSmall
    ? {
        initial: sideMenuModel.initial1,
        animate: sideMenuModel.animate1,
      }
    : !modulOpen
    ? {
        initial: sideMenuModel.initial2,
        animate: sideMenuModel.animate2,
      }
    : {
        initial: sideMenuModel.initial1,
        animate: sideMenuModel.animate1,
      };

  const cardModel = {
    initial1: {
      width: "220px",
      height: "140px",
      margin: "10px 10px 20px 10px",
      overflow: "hidden",
    },
    initial2: {
      width: "40px",
      height: "140px",
      margin: "10px 6px 20px 6px",
      overflow: "hidden",
    },
    animate1: {
      width: adminMenu ? "40px" : "220px",
      margin: adminMenu ? "10px 6px 20px 6px" : "10px 10px 20px 10px",
    },
    animate2: {
      width: adminMenu ? "220px" : "40px",
      margin: adminMenu ? "10px 10px 20px 10px" : "10px 6px 20px 6px",
    },
  };

  const cards = isSmall
    ? {
        initial: cardModel.initial1,
        animate: cardModel.animate1,
      }
    : !modulOpen
    ? {
        initial: cardModel.initial2,
        animate: cardModel.animate2,
      }
    : {
        initial: cardModel.initial1,
        animate: cardModel.animate1,
      };

  const sideMenuTitleModul = isSmall
    ? adminMenu
      ? { display: "none" }
      : layoutPrivateStyle.sideMenuTitle
    : modulOpen
    ? !adminMenu
      ? layoutPrivateStyle.sideMenuTitle
      : { display: "none" }
    : adminMenu
    ? layoutPrivateStyle.sideMenuTitle
    : { display: "none" };

  const sideMenuIconCollapseModul = isSmall
    ? adminMenu
      ? { display: "none" }
      : layoutPrivateStyle.sideMenuIconCollapse
    : modulOpen
    ? !adminMenu
      ? layoutPrivateStyle.sideMenuIconCollapse
      : { display: "none" }
    : adminMenu
    ? layoutPrivateStyle.sideMenuIconCollapse
    : { display: "none" };

  const sideMenuCollapseModul = isSmall
    ? adminMenu
      ? { display: "none" }
      : { display: "block" }
    : modulOpen
    ? !adminMenu
      ? { display: "block" }
      : { display: "none" }
    : adminMenu
    ? { display: "block" }
    : { display: "none" };

  const sideMenuIconButtonModul = isSmall
    ? adminMenu
      ? layoutPrivateStyle.sideMenuPrivateIconButton
      : layoutPrivateStyle.sideMenuPrivateIconButtonActive
    : modulOpen
    ? !adminMenu
      ? layoutPrivateStyle.sideMenuPrivateIconButtonActive
      : layoutPrivateStyle.sideMenuPrivateIconButton
    : adminMenu
    ? layoutPrivateStyle.sideMenuPrivateIconButtonActive
    : layoutPrivateStyle.sideMenuPrivateIconButton;

  const handleOpen = () => {
    if (isSmall) setModulOpen(!modulOpen);
    setAdminMenu?.(!adminMenu);
  };

  const getProfile = useQueryGetProfileForValidateEnrichment({
    query: {
      binusianid: profile.binusianId,
    },
    options: {
      enabled: validateForEnrichment,
      onSuccess: (res) => {
        setValidateForEnrichment(false);
        res.data.action === "Submit"
          ? Navigate("/enrichment/update-profile")
          : res.data.action === "Edit"
          ? Navigate("/enrichment/choose-enrichment-track")
          : Navigate("/dashboard");
      },
      onError: () => {
        setValidateForEnrichment(false);
        Navigate("/dashboard");
      },
    },
  });

  const getNavbarList = useQueryListNavbar({
    query: {
      roleId: userData.roleId,
    },
    options: {
      onSuccess: (res) => {
        setListRole(res || []);
      },
      onError: () => {
        setListRole([]);
      },
    },
  });

  const handleClick = (link) => {
    if (link === "/enrichment/choose-enrichment-track") {
      setValidateForEnrichment(true);
    } else {
      setValidateForEnrichment(false);
    }

    setActiveMenu?.(link);
    Navigate(link);
  };

  const handleCollapse = (e) => {
    if (openCollapse?.find((collapse) => collapse === e))
      setOpenCollapse?.(openCollapse?.filter((collapse) => collapse !== e));
    else setOpenCollapse((prev) => [...prev, e]);
  };

  const findCollapse = (e) => {
    if (openCollapse?.find((collapse) => collapse === e)) return true;
    return false;
  };

  return (
    <motion.div
      key="animate-sidemenu"
      initial={variantSideMenu.initial}
      animate={variantSideMenu.animate}
    >
      <Box sx={layoutPrivateStyle.sideMenuPaperBox}>
        {modulOpen !== true && (
          <Box sx={layoutPrivateStyle.sidebarCard}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex" }}>
                <Box
                  component="img"
                  src="/assets/icon/icon-face-shape.svg"
                  // sx={layoutPrivateStyle.headerIcon}
                />
                <Typography
                  sx={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {userData.roleDesc || "Role"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ marginTop: "30px" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                {userData.name || "Name"}
              </Typography>

              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Semester {userData.semester || "-"}
              </Typography>

              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                {userData.termDesc || "Periode"}
              </Typography>
            </Box>
          </Box>
        )}

        <IconButton
          onClick={handleOpen}
          color="primary"
          sx={sideMenuIconButtonModul}
        >
          {isSmall ? (
            !adminMenu ? (
              <KeyboardDoubleArrowLeftIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            ) : (
              <KeyboardDoubleArrowRightIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            )
          ) : modulOpen ? (
            !adminMenu ? (
              <KeyboardDoubleArrowLeftIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            ) : (
              <KeyboardDoubleArrowRightIcon
                sx={layoutPrivateStyle.sideMenuPrivateIcon}
              />
            )
          ) : adminMenu ? (
            <KeyboardDoubleArrowLeftIcon
              sx={layoutPrivateStyle.sideMenuPrivateIcon}
            />
          ) : (
            <KeyboardDoubleArrowRightIcon
              sx={layoutPrivateStyle.sideMenuPrivateIcon}
            />
          )}
        </IconButton>
        {/* <motion.div key="animate-userinfocard" initial={cards.initial} animate={cards.animate}>
          <UserInfoCard adminMenu={adminMenu} modulOpen={modulOpen} />
        </motion.div> */}
        <Box
          sx={{
            height: "1px",
            background: "#008DC7",
            width: "100%",
            marginBottom: "16px",
          }}
        />
        <List sx={{ width: "100%", padding: "0" }}>
          {listRole.length > 0 ? 
            listRole.map((list) => (
              <Stack
                key={list.name}
                sx={{
                  display:
                    list.key !== "" && !listPrivileges?.includes(`${list?.key}`)
                      ? "none"
                      : undefined,
                }}
              >
                <ListItemButton
                  key={list.name}
                  sx={
                    activeMenu?.includes(`${list.link}`) && list.link !== "/"
                      ? layoutPrivateStyle.sideMenuLinkActive
                      : findCollapse(list.name)
                      ? layoutPrivateStyle.sideMenuLinkCollapse
                      : layoutPrivateStyle.sideMenuLink
                  }
                  onClick={
                    list.collapseList.length === 0
                      ? () => handleClick(list.link)
                      : () => handleCollapse(list.name)
                  }
                >
                  <Stack direction="row" gap="4px" alignItems="center">
                    {list.name === "Dashboard" && (
                      <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "User Management" && (
                      <AssignmentIndIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Student Registration" && (
                      <StudentRegistrationIcon
                        sx={layoutPrivateStyle.sideMenuIcon}
                      />
                    )}
  
                    {list.name === "Study Plan" && (
                      <CalendarMonthIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Setup Due Date" && (
                      <DateRangeIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Score Entry Evaluation" && (
                      <AssignmentIndIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Enrichment" && (
                      <DashboardIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Enrichment Approval" && (
                      <EnrichmentIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Setup Enrichment Program" && (
                      <AnalyticsIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Assesment & Evaluation" && (
                      <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Assesment & Evaluation Approval" && (
                      <SpeedIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
  
                    {list.name === "Streaming" && (
                      <VideoLibraryIcon sx={layoutPrivateStyle.sideMenuIcon} />
                    )}
                    <Typography sx={sideMenuTitleModul}>{list.name}</Typography>
                  </Stack>
                  {findCollapse(list.name) ? (
                    <ExpandMore sx={sideMenuIconCollapseModul} />
                  ) : (
                    list.collapseList.length > 0 && (
                      <KeyboardArrowLeftIcon sx={sideMenuIconCollapseModul} />
                    )
                  )}
                </ListItemButton>
                {list.collapseList.length > 0 && (
                  <Collapse
                    in={findCollapse(list.name)}
                    timeout="auto"
                    unmountOnExit
                    sx={sideMenuCollapseModul}
                  >
                    <List component="div" disablePadding>
                      {list.collapseList?.map((collapse) => (
                        <ListItemButton
                          key={collapse.name}
                          sx={layoutPrivateStyle.sideMenuBoxCollapse}
                          onClick={() => handleClick(collapse.link)}
                        >
                          <Stack
                            sx={
                              activeMenu === collapse.link
                                ? layoutPrivateStyle.sideMenuBoxCollapseLinkActive
                                : layoutPrivateStyle.sideMenuBoxCollapseLink
                            }
                          >
                            <Typography
                              sx={
                                !adminMenu
                                  ? layoutPrivateStyle.sideMenuTitle
                                  : layoutPrivateStyle.sideMenuTitleMobile
                              }
                            >
                              {collapse.name}
                            </Typography>
                          </Stack>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Stack>
            ))
          : (
            <>
              <ListItem><Skeleton variant="rounded" width={700} height={25} /></ListItem>
              <ListItem><Skeleton variant="rounded" width={700} height={25} /></ListItem>
              <ListItem><Skeleton variant="rounded" width={700} height={25} /></ListItem>
              <ListItem><Skeleton variant="rounded" width={700} height={25} /></ListItem>
              <ListItem><Skeleton variant="rounded" width={700} height={25} /></ListItem>
            </>
          )}
        </List>
      </Box>
    </motion.div>
  );
}
