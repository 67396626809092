import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from '@mui/material/IconButton';
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Search from "@mui/icons-material/Search";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { styled } from '@mui/material/styles';
import { MuiFileInput } from 'mui-file-input';
import { GridColDef } from '@mui/x-data-grid';

import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query';

import useModal from '../../hooks/use-modal';
import { CustomDataGrid } from '../../components/common/datagrid';
import { StudentRegCss } from "../../styles/page/StudentRegCss";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";

import { EnrichmentApprovalList, UploadExcelList } from '../../types/enrichment-approval'
import { useQueryEnrichmentApprovalList, useQueryExportExcel, useMutationUploadExcel, useMutationBulkApproval } from '../../api/enrichment-approval';

export const StyledMuiFileInput = styled(MuiFileInput)(({ theme }) => ({
  background: 'white',
  border: '1px solid #CCCCCC',
  borderRadius: '6px'
}));

export function EnrichmentApproval() {
  const { showModal } = useModal();
  const queryClient = useQueryClient();

  const dataPriority = [
    {
      priorityId: 1,
      priorityName: 'Priority 1'
    },
    {
      priorityId: 2,
      priorityName: 'Priority 2'
    }
  ]

  const [binusianIdFilter, setBinusianIdFilter] = useState('');
  const [studentIdFilter, setStudentIdFilter] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('');
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [enrichmentApprovalList, setEnrichmentApprovalList] = useState<EnrichmentApprovalList[]>([]);
  const [selectedIdApprovalList, setSelectedIdApprovalList] = useState<[]>([]);
  const [loadExportExcel, setLoadExportExcel] = useState(false);
  const [doExportExcel, setDoExportExcel] = useState(false);
  const [uploadFile, setUploadFile] =  useState<File | null>(null);
  const [previewData, setPreviewData] =  useState<UploadExcelList[]>([]);

  const [totalItems, setTotalItems] = useState(0)
  const [paginationModel, setPaginationModel] = useState({pageSize: 10, page: 0})
  const [openUploadExcelModal, setOpenUploadExcelModal] = useState(false);
  const [openUploadExcelPreviewModal, setOpenUploadExcelPreviewModal] = useState(false);

  const columnsData: GridColDef[] = [
    {
      field: 'binusianId',
      headerName: 'Binusian ID',
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: 'externalSystemId',
      headerName: 'Student ID',
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: 'trackDesc',
      headerName: 'Enrichment Track',
      flex: 0.5,
      minWidth: 100,
      hideable: false,
    },
    {
      field: 'sks',
      headerName: 'SKS',
      flex: 1,
      minWidth: 200,
      hideable: false,
    }
  ];

  const getEnrichmentApprovalList = useQueryEnrichmentApprovalList({
    query: {
      search: binusianIdFilter,
      studentid: studentIdFilter,
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page + 1,
      orderBy: "name:desc",
      priority: priorityFilter
    },
    options: {
      onSuccess: (res) => {
        setEnrichmentApprovalList(res.data);
        setTotalItems(res.paginationInfo.totalItems);
      },
      onError: () => {
        setEnrichmentApprovalList([]);
      }
    },
  });

  const exportExcel = useQueryExportExcel({
    query: {
      search: binusianIdFilter,
      priority: priorityFilter
    },
    options: {
      enabled: doExportExcel,
      onSuccess: (res) => {
        setLoadExportExcel(false);
        setDoExportExcel(false);

        const now = new Date();
        const dateNow = now.toISOString().split('T')[0];

        const blob = new Blob([res], { type: 'xlsx' });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `Enrichment Approval-${dateNow}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);
      },
      onError: () => {
        setLoadExportExcel(false);
        setDoExportExcel(false);

        showModal({
          title: 'Failed',
          message:
            'Export excel is failed.',
          options: {
            variant: 'failed',
          },
        });
      }
    },
  });

  const uploadExcel = useMutationUploadExcel({
    onSuccess: async (res) => {
      setPreviewData(res);
    },
    onError: (error) => {
      setPreviewData([]);
    },
  });

  const bulkEnrichmentApproval = useMutationBulkApproval({
    query: {
      all: "",
    },
    options: {
      onSettled: async (res) => {
        if (res) {
          queryClient.invalidateQueries({ queryKey: ['enrichmentApprovalList'] });
        }
      },
      onSuccess: async (res) => {
        setPreviewData([]);
        setSelectedIdApprovalList([]);
        setOpenUploadExcelPreviewModal(false);
        showModal({
          title: 'Success',
          message:
            'Data successfully Approved.',
          options: {
            variant: 'success',
          },
          
        });
      },
      onError: (error) => {
        showModal({
          title: 'Failed',
          message:
            'Approve data is failed.',
          options: {
            variant: 'failed',
          },
        });
      },
    },
  });

  const handleSearch = () => {
    priorityFilter !== "" ? setCheckboxSelection(true) : setCheckboxSelection(false);

    getEnrichmentApprovalList.refetch();

  };

  const handleUploadChange = (newValue: File | null) => {
    const file = newValue;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB limit

    if (file && file.size > maxSizeInBytes) {
      showModal({
        title: 'Upload Failed',
        message:
          'File size exceeds 5 MB.',
        options: {
          variant: 'failed',
        },
      });

      setUploadFile(null);
    } else {
      setUploadFile(file);
    }
  };

  const handlePreviewDataExcel = () => {
    setOpenUploadExcelModal(false);
    
    const formData = new FormData();
    uploadFile ? formData.append('file', uploadFile) : formData.append('file', new Blob());
    
    uploadExcel.mutate(formData);

    setUploadFile(null);
    setOpenUploadExcelPreviewModal(true);
  };

  const handleConfirmationApproveData = () => {
    showModal({
      title: "Alert",
      message: `Are you sure you want to approve this data?`,
      options: {
        buttonTitle: "Ok",
        buttonTitle2: "Cancel",
        variant: "info",
        onOk() {
          handleBulkApproval();
        },
      },
    });
  };

  const handleBulkApproval = () => {
    let selectedApprovalListResults;
    if(previewData.length > 0){
      selectedApprovalListResults = previewData;

    }else if(selectedIdApprovalList.length > 0){
      selectedApprovalListResults = selectedIdApprovalList.map((id) => {
        const selectedRow = enrichmentApprovalList.find((row) => row.id === id);
        if (selectedRow) {
          return {
            id: selectedRow.id,
            binusianId: selectedRow.binusianId,
            externalSystemId: selectedRow.externalSystemId,
            name: selectedRow.name,
            priority: selectedRow.priority,
            track: selectedRow.track,
            trackDesc: selectedRow.trackDesc,
            sks: selectedRow.sks,
            approvalStatus: selectedRow.approvalStatus
          };
        }
        return {
          id: '',
          binusianId: '',
          externalSystemId: '',
          name: '',
          priority: '',
          track: '',
          trackDesc: '',
          sks: '',
          approvalStatus: ''
        };
      });
    }else{
      selectedApprovalListResults = [];
    }

    bulkEnrichmentApproval.mutate(selectedApprovalListResults);
  };

  return (
    <>
      <Container maxWidth={false}>
        <Stack mt={2} spacing={2}>
          <Breadcrumbs aria-label="breadcrumb" separator="">
            <Typography fontSize={14} color="#0097DA">
              Enrichment Approval
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Typography variant="h5" gutterBottom mt={2} component="div">
          Enrichment Approval
        </Typography>
        <Divider />

        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs>
            <Box sx={StudentRegCss.searchBar}>
              <TextField 
                fullWidth
                size="small" 
                id="search-binusianId"
                label="Binusian ID" 
                variant="outlined"
                value={binusianIdFilter}
                onChange={(e) => setBinusianIdFilter(e.target.value)}
                />
              <Search sx={StudentRegCss.iconSearch}/>
            </Box>
          </Grid>
          <Grid item xs>
            <Box sx={StudentRegCss.searchBar}>
              <TextField 
                fullWidth
                size="small" 
                id="search-studentId"
                label="Student ID" 
                variant="outlined"
                value={studentIdFilter}
                onChange={(e) => setStudentIdFilter(e.target.value)}
                />
              <Search sx={StudentRegCss.iconSearch}/>
            </Box>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth size="small">
              <InputLabel id="priority-select-label" shrink>Priority</InputLabel>
              <Select
                displayEmpty
                labelId="priority-select-label"
                id="priority-select"
                value={priorityFilter}
                label="Priority"
                onChange={(e) => setPriorityFilter(e.target.value)}
              >
                  <MenuItem value=""><em>All</em></MenuItem>
                {dataPriority.map(item => (
                  <MenuItem key={item.priorityId} value={item.priorityName}>{item.priorityName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" onClick={handleSearch}>Search</Button>
          </Grid>
        </Grid>
        <Divider />

        <Stack direction="row" spacing={2} mt={2} mb={2}>
          <LoadingButton
            variant="contained"
            loading={loadExportExcel}
            disabled={enrichmentApprovalList?.length === 0 && true}
            onClick={() => {
              setLoadExportExcel(true)
              setDoExportExcel(true)
            }}
            >
              <span>Export to Excel</span>
            </LoadingButton>
          {/* <Button variant="contained" onClick={() => setOpenUploadExcelModal(true)}>Upload Excel</Button> */}
        </Stack>

        <Paper elevation={3}>
          <CustomDataGrid
            columnsData={columnsData}
            rowsData={enrichmentApprovalList}
            rowCount={totalItems}
            getRowId={(row) => row.id}
            setPaginationModel={(value) => {
              setPaginationModel(value);
            }}
            paginationModel={paginationModel}
            checkboxSelection={checkboxSelection}
            hideFooterSelectedRowCount
            rowSelectionModel={selectedIdApprovalList}
            onRowSelectionModelChange={(newRowSelectionMode) => {
              setSelectedIdApprovalList(newRowSelectionMode);
            }}
          />
        </Paper>

        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing="2" mt={2} mb={2}>
          <Button variant="contained" color="warning" onClick={handleConfirmationApproveData} disabled={selectedIdApprovalList.length === 0}>Approve</Button>
        </Stack>

      </Container>
      <Dialog
      open={openUploadExcelPreviewModal}
      onClose={() => {setOpenUploadExcelPreviewModal(false)}}
      fullWidth
      maxWidth="md"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" sx={{fontWeight: 700}}>Bulk Approval</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {setOpenUploadExcelPreviewModal(false)}}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <CustomDataGrid
          hideFooterPagination
          columnsData={columnsData}
          rowsData={previewData}
          rowCount={10}
          getRowId={(row) => row.id}
          setPaginationModel={(value) => {
            setPaginationModel(value);
          }}
          paginationModel={paginationModel}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleBulkApproval}>Submit</Button>
      </DialogActions>
      </Dialog>
      <Dialog
        open={openUploadExcelModal}
        onClose={() => {setOpenUploadExcelModal(false)}}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{fontWeight: 700}}>Upload Excel</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {setOpenUploadExcelModal(false)}}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography mb={2}>Upload Here</Typography>
          <StyledMuiFileInput
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Choose File"
            InputProps={{
              inputProps: {
                accept: '.xlsx, .xls'
                
              },
              startAdornment: <AttachFileIcon />
            }}
            clearIconButtonProps={{
              title: "Remove",
              children: <CloseIcon fontSize="small" />
            }}
            value={uploadFile}
            onChange={handleUploadChange}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained" 
            onClick={handlePreviewDataExcel}
          >
              Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
