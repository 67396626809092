import { SxStyle } from '../../types/style';

// datagrid
const styledDataGrid: SxStyle = {
    width: '100%',
    height: '80vh',
    // minHeight: '600px',
    // maxHeight: '600px',
    overflowY: 'auto',
    '--DataGrid-overlayHeight': '300px'
}

// custom pagination
const paginationStack: SxStyle = {
    width: '100%',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}

const paginationStackStack: SxStyle = {
    alignItems: 'center'
}

const paginationStackStackSelect: SxStyle = {
    width: '72px',
    height: '32px'
}

export const datagridStyle = {
    styledDataGrid,
    paginationStack,
    paginationStackStack,
    paginationStackStackSelect
};