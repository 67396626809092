import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../config/api-client";
import { ApiIdentity } from "../constants/ApiIdentity";
import { UseMutationOptions, UseQueryOptions } from "../types/react-query";
import {
  AcadCareer,
  AssigmentPartner,
  CheckFor,
  DetailStudentPeriod,
  GetIndividual,
  GetStudentPeriodData,
  GetTrack,
  StudentBulk,
  StudentData,
  StudentDataResponse,
  StudentGetResponse,
  SubmitChangeStudent,
  SubmitDropStudent,
  SubmitSetMappingAditional,
  SubmitSetMappingBulk,
  SubmitStudentPeriod,
  SubmitStudentTrackReg,
  TermData,
  StudentEnrichmentTrackResponse,
  ResetMappingRequest,
  StudentTrackChangeDropResponse
} from "../types/student-registration";
import { ApiDropDown } from "../constants/ApiDropdown";
import { ApiStudentRegistration } from "../constants/ApiStudentRegistration";

export const useMutationStudentTrack = (options?: UseMutationOptions) =>
  useMutation(
    (data: StudentData) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.getStudentTrackApi}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationStudentBulk = ({
  query,
  options,
}: {
  query?: {
    search?: string,
    pageSize?: number,
    pageNumber?: number,
    orderBy?: string
  };
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: StudentBulk) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.getStudentBulk(query?.search, query?.pageSize, query?.pageNumber, query?.orderBy)}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationUploadRegistration = (options?: UseMutationOptions) =>
  useMutation(
    (data: FormData) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.submitUploadRegistration}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useQueryStudent = ({
  query,
  options,
}: {
  query: {
    id?: string;
    partitionKey?: string;
  };
  options?: UseQueryOptions<StudentGetResponse>;
}) =>
  useQuery(
    ["student", query.id, query.partitionKey],
    () =>
      apiClient
        .get<unknown, { data: StudentGetResponse }>(
          ApiStudentRegistration.getStudentIdApi(query.id, query.partitionKey)
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetTermDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<TermData>;
}) =>
  useQuery(
    ["getTermDropDown"],
    () =>
      apiClient
        .get<unknown, { data: TermData }>(
          ApiDropDown.getTermDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetTermPeriodDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<TermData>;
}) =>
  useQuery(
    ["getTermPeriodDropDown"],
    () =>
      apiClient
        .get<unknown, { data: TermData }>(
          ApiDropDown.getTermPeriodDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetTermRegularDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<TermData>;
}) =>
  useQuery(
    ["getTermRegularDropDown"],
    () =>
      apiClient
        .get<unknown, { data: TermData }>(
          ApiDropDown.getTermRegulerDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetIndividual = ({
  query,
  options,
}: {
  query: {
    studentId: string;
  };
  options?: UseQueryOptions<GetIndividual>;
}) =>
  useQuery(
    ["getIndividual", query.studentId],
    () =>
      apiClient
        .get<unknown, { data: GetIndividual }>(
          ApiStudentRegistration.getIndividual(query.studentId)
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetTrackDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<GetTrack>;
}) =>
  useQuery(
    ["getTrackDropDown"],
    () =>
      apiClient
        .get<unknown, { data: GetTrack }>(
          ApiStudentRegistration.getTrackDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetAssignPartnerDropDown = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<AssigmentPartner>;
}) =>
  useQuery(
    ["getAssignPartner"],
    () =>
      apiClient
        .get<unknown, { data: AssigmentPartner }>(
          ApiStudentRegistration.getAssignPartner()
        )
        .then((res) => res.data),
    options
  );

export const useMutationSubmitStudentTrackRegistration = ({
  query,
  options,
}: {
  query?: {
    institution: string;
    status: string;
    acadCareer: string;
    acadOrg: string;
    acadProg: string;
    acadPlan: string;
    studentId: string;
    period: string;
    admitTerm: string;
  };
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: SubmitStudentTrackReg) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.submitStudentTrackReg(
            query?.institution,
            query?.status,
            query?.acadCareer,
            query?.acadOrg,
            query?.acadProg,
            query?.acadPlan,
            query?.studentId,
            query?.period,
            query?.admitTerm
          )}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationSubmitChangeStudentTrack = ({
  query,
  options,
}: {
  query?: {
    institution: string;
    campus: string;
    acadCareer: string;
    acadGroup: string;
    acadProg: string;
    acadPlan: string;
    studentId: string;
    period: string;
    admitTerm: string;
    acadOrg: string;
  };
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: SubmitChangeStudent) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.submitChangeStudent(
            query?.institution,
            query?.campus,
            query?.acadCareer,
            query?.acadGroup,
            query?.acadProg,
            query?.acadPlan,
            query?.studentId,
            query?.period,
            query?.admitTerm
          )}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationSubmitDropStudent = ({
  query,
  options,
}: {
  query?: {
    studentId: string;
  };
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: SubmitDropStudent) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.submitDropStudent(query?.studentId)}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetDropDownCheckFor = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<CheckFor>;
}) =>
  useQuery(
    ["getDropDownCheckFor"],
    () =>
      apiClient
        .get<unknown, { data: CheckFor[] }>(
          ApiStudentRegistration.getCheckForDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useMutationGetStudentPeriod = ({
  query,
  options,
}: {
  query?: {
    pageSize?: number;
    pageNumber?: number;
    orderBy?: string;
  };
  options?: UseMutationOptions;
}) =>
  useMutation(
    (data: SubmitStudentPeriod) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.getStudentPeriod(
            query?.pageSize,
            query?.pageNumber
          )}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetDropDownAcadCareer = ({
  query,
  options,
}: {
  query: {};
  options?: UseQueryOptions<AcadCareer>;
}) =>
  useQuery(
    ["getDropDownAcadCareer"],
    () =>
      apiClient
        .get<unknown, { data: AcadCareer[] }>(
          ApiStudentRegistration.getAcadCareerDropDown()
        )
        .then((res) => res.data),
    options
  );

export const useMutationSubmitSetMappingBulk = (options?: UseMutationOptions) =>
  useMutation(
    (data: SubmitSetMappingBulk) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.submitSetMappingBulk}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationSubmitSetMappingAditional = (
  options?: UseMutationOptions
) =>
  useMutation(
    (data: SubmitSetMappingAditional) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.submitSetMappingBulk}`,
          data
        )
        .then((res) => res.data),
    options
  );

export const useMutationDownloadTemplate = (options?: UseMutationOptions) =>
  useMutation(
    (data: StudentBulk) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.downloadTemplate}`,
          data,
          {
            headers: { "Content-Type": "application/json" },
            responseType: "arraybuffer",
          }
        )
        .then((res) => res.data),
    options
  );

export const useMutationDownloadReport = (options?: UseMutationOptions) =>
  useMutation(
    (data: StudentBulk) =>
      apiClient
        .post<unknown, { data: string }>(
          `${ApiStudentRegistration.downloadReport}`,
          data,
          {
            headers: { "Content-Type": "application/json" },
            responseType: "arraybuffer",
          }
        )
        .then((res) => res.data),
    options
  );

export const useQueryGetDetailStudentPeriod = ({
  query,
  options,
}: {
  query: { id: string };
  options?: UseQueryOptions<DetailStudentPeriod>;
}) =>
  useQuery(
    ["getDetailStudentPeriod", query.id],
    () =>
      apiClient
        .get<unknown, { data: DetailStudentPeriod }>(
          ApiStudentRegistration.getDetailStudentPeriod(query?.id)
        )
        .then((res) => res.data.data),
    options
  );

// Student Period Mapping
export const useMutationResetMapping = (options?: UseMutationOptions) =>
  useMutation(
    (data: ResetMappingRequest) =>
      apiClient
        .post<unknown, { data: string }>(`${ApiStudentRegistration.resetMapping}`, data)
        .then((res) => res.data),
    options,
  );

// Student Track Registration 
export const useQueryGetDetailStudentEnrichmentTrack = ({
  query,
  options,
}: {
  query: { id: string };
  options?: UseQueryOptions<StudentEnrichmentTrackResponse>;
}) =>
  useQuery(
    ["getDetailStudentEnrichmentTrack", query.id],
    () =>
      apiClient
        .get<unknown, { data: StudentEnrichmentTrackResponse }>(
          ApiStudentRegistration.getDetailStudentEnrichmentTrack(query?.id)
        )
        .then((res) => res.data),
    options
  );

// Change and Drop Student Track 
export const useQueryGetStudentTrackChangeDrop = ({
  query,
  options,
}: {
  query: {
    studentId: string;
  };
  options?: UseQueryOptions<StudentTrackChangeDropResponse>;
}) =>
  useQuery(
    ["getIndividual", query.studentId],
    () =>
      apiClient
        .get<unknown, { data: StudentTrackChangeDropResponse }>(
          ApiStudentRegistration.getStudentTrackChangeDrop(query.studentId)
        )
        .then((res) => res.data),
    options
  );
