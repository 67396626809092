import { ApiService, azureFunctionCode } from "./ApiService";

export const ApiDropDown = {
  getInstitutionDropDown: () =>
    `${ApiService.apiPostman}/GetInstitution?code=${azureFunctionCode}`,
  getAcadCareerDropDown: () =>
    `${ApiService.apiPostman}/GetAcadCareer?code=${azureFunctionCode}`,
  getAcadOrgDropDown: () =>
    `${ApiService.apiPostman}/DropDownAcadOrg?code=${azureFunctionCode}`,
  getAcadProgDropdown: (acadOrg: string) =>
    `${ApiService.apiPostman}/DropDownProg?code=${azureFunctionCode}&acadOrg=${acadOrg}`,
  getAcadPlanDropDown: (acadOrg: string, acadProg: string) =>
    `${ApiService.apiPostman}/DropDownAcadPlan?code=${azureFunctionCode}&acadOrg=${acadOrg}&acadProg=${acadProg}`,
  getTermDropDown: () =>
    `${ApiService.apiPostman}/GetTerm?code=${azureFunctionCode}`,
  getTermPeriodDropDown: () =>
    `${ApiService.apiPostman}/GetTermPeriod?code=${azureFunctionCode}`,
  getTermRegulerDropDown: () =>
    `${ApiService.apiPostman}/GetTermReguler?code=${azureFunctionCode}`,
  getListCampusLocationDropDown: () =>
    `${ApiService.apiPostman}/DropDownCampusLocation?code=${azureFunctionCode}`,
  getListCampusDropDown: () =>
    `${ApiService.apiPostman}/GetCampus?code=${azureFunctionCode}`,
  getListTrackDropDown: () =>
    `${ApiService.apiPostman}/GetTrack?code=${azureFunctionCode}`,
  getListAssesmentComp: () =>
    `${ApiService.apiPostman}/DropDownComponent?code=${azureFunctionCode}`,
  getListAcadGroup: () =>
    `${ApiService.apiPostman}/GetAcadGroup?code=${azureFunctionCode}`,
  getListEvaluationComponent: () =>
    `${ApiService.apiPostman}/DropDownEvaluationCompenent?code=${azureFunctionCode}`,
  getDropDownGradeStatus: () =>
    `${ApiService.apiPostman}/DropDownGradeStatus?code=${azureFunctionCode}`,
  getDropDownTransferStatus: () =>
    `${ApiService.apiPostman}/DropDownTransferStatus?code=${azureFunctionCode}`,
  getDropDownAdmitTerm: () =>
    `${ApiService.apiPostman}/GetTerm?code=${azureFunctionCode}`,
  
};
