import {
  Container,
  Stack,
  Breadcrumbs,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useModal from "../../../hooks/use-modal";
import CheckboxListEdit from "../../../components/checkbox/checkbox-list-edit";
import { breadCrumbLink } from "../../../styles/common/bread-crumb-link";

import { RoleListDropdownResponseData } from "../../../types/user-role";
import { useQueryListRoleDropDown, useMutationEditRole } from "../../../api/user-management";

export function EditRole() {
  const location = useLocation();
  const navigation = useNavigate();
  const { showModal } = useModal();

  const [dataRole, setDataRole] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("");
  const [idRole, setIdrole] = useState(location.search.replace("?", ""));
  const [listRoleDropDown, setListRoleDropDown] = useState<RoleListDropdownResponseData[]>([] as RoleListDropdownResponseData[]);

  const getDropDownRole = useQueryListRoleDropDown({
    query: {},
    options: {
      onSuccess: async (res) => {
        setListRoleDropDown(res.data || []);
      },
      onError: (err) => {
        setListRoleDropDown([]);
      },
    },
  });

  const submitEditRole = useMutationEditRole({
    onSuccess: async (res) => {
      showModal({
        title: "Success",
        message: `Edit role success!`,
        options: {
          buttonTitle: "Ok",
          variant: "success",
          onOk() {
            navigation("/user-management/user-role");
          },
        },
      });
    },
    onError: async (error) => {
      showModal({
        title: "Failed",
        message: `Edit role is failed.`,
        options: {
          buttonTitle: "Ok",
          variant: "failed",
        },
      });
    },
  });

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRoleName(event.target.value as string);
  };

  const handleSubmit = () => {
    submitEditRole.mutate({
      Name: roleName,
      IsActive: status === "Active",
      listRole: dataRole,
    });
  };

  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            User Management
          </Link>
          <Link
            style={breadCrumbLink.styleBreadCrumbLink}
            to="/user-management/user-role"
          >
            User Role
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Edit Role
          </Typography>
        </Breadcrumbs>
      </Stack>

      <Typography variant="h5" gutterBottom mt={2}>
        Edit Role
      </Typography>

      <Divider />

      <Grid container mt={2} mb={2}>
        <Grid item xs={4}>
          <FormControl disabled fullWidth size="small">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              value={roleName}
              label="Role"
              onChange={handleRoleChange}
            >
                <MenuItem value=""><em>None</em></MenuItem>
              {listRoleDropDown.map(item => (
                <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Divider />

      <CheckboxListEdit
        setDataRoleName={setRoleName}
        setDataRole={setDataRole}
        setDataRoleStatus={setStatus}
        idRoleList={idRole}
      />

      <Grid container spacing={2} mt={2} mb={2}>
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={status}
              label="Status"
              onChange={handleStatusChange}
            >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Nonactive">Nonactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Stack spacing={2} direction="row" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigation("/user-management/user-role")}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </Stack>
    </Container>
  );
}
