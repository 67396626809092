import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  Skeleton
} from "@mui/material";
import { Link } from "react-router-dom";
import { TextComp } from "../../components/atom/text-comp";
import { breadCrumbLink } from "../../styles/common/bread-crumb-link";
import { StreamingCss } from "../../styles/page/StreamingCss";
import { useEffect, useState } from "react";
import { useMutationGetStreaming } from "../../api/streaming";

export function Result() {
  const [dataStream, setDataStream] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  interface DataSelectStream {
    acadProgDesc: string;
    desc: string;
  }

  const [dataSelectStream, setDataSelectStream] = useState<DataSelectStream>(
    {} as DataSelectStream
  );
  const streaming = useMutationGetStreaming({
    onSuccess: async (res: any) => {
      setDataStream(res.data || []);
      setDataSelectStream(res.data[0]);
      setSelectedItem(res.data[0].id);
    },
    onError: async (error) => {
      alert(error);
    },
  });

  useEffect(() => {
    const getStreamingData = () => {
      streaming.mutate({});
    };

    getStreamingData();
  }, []);
  return (
    <Container maxWidth={false}>
      <Stack mt={2} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link style={breadCrumbLink.styleBreadCrumbLink} to="">
            Streaming
          </Link>
          <Typography fontSize={14} color="#0097DA">
            Result
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h5" gutterBottom mt={2} component="div">
        Streaming Result
      </Typography>
      <Divider />

      <Grid container spacing={2} mt={1}>
      {dataStream.length > 0 ? (
        <>
          <Grid item xs={5}>
            <TextComp title="NIM / Student Id" bodyText="22009838928" />
            <TextComp
              title="Jenjang Pendidikan / Academic Career"
              bodyText="Undergraduate"
            />
            <TextComp title="Jurusan / Academic Program" bodyText="Accounting" />
            <TextComp title="Hasil Peminatan Anda / Your Streaming Result" bodyText="Streaming - Akuntansi Manajemen" />
          </Grid>
          <Grid item xs={7}>
            <TextComp title="Nama Lengkap /  Full Name" bodyText="Herwin " />
            <TextComp title="Semester/Term" bodyText="2022, Odd Semester" />
            <TextComp title="Periode Masuk /  Admit Term" bodyText="2019" />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={5} mb={3}>
            <Stack>
              <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
              <Skeleton variant="text" sx={{ fontSize: '14px' }} />
            </Stack>
          </Grid>
          <Grid item xs={7} mb={3}>
            <Stack>
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" width={120} sx={{ fontSize: '14px' }} />
                <Skeleton variant="text" sx={{ fontSize: '14px' }} />
            </Stack>
          </Grid>
        </>
      )}
      </Grid>
      <Divider />

      <Typography
        variant="h5"
        fontSize={16}
        mb={2}
        gutterBottom
        mt={2}
        component="div"
      >
        Penjelasan Streaming / Streaming Description
      </Typography>

      <Box mb={3}>
        <Grid wrap="nowrap" sx={StreamingCss.tableCss} container>
          <Grid sx={StreamingCss.gridList} item xs={3}>
            {dataStream.map((item: any) => (
              <Box
                onClick={() => {
                  setDataSelectStream(item);
                  setSelectedItem(item.id);
                }}
                sx={
                  item.id === selectedItem
                    ? StreamingCss.boxCssActive
                    : StreamingCss.boxCssInactive
                }
              >
                <Typography variant="h6" fontSize={14} mb={0} gutterBottom>
                  {item.acadProgDesc}
                </Typography>
              </Box>
            ))}
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid xs={12}>
                <Box
                  p={2}
                  sx={{ background: "white", borderRadius: "0px 10px 0px 0px" }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ marginTop: "4px" }}
                    variant="h6"
                    fontSize={14}
                    mb={0}
                    gutterBottom
                  >
                    {dataSelectStream.acadProgDesc}
                  </Typography>
                  <Button
                    sx={{
                      height: "32px",
                      background: "#0097DA",
                      color: "white",
                    }}
                    variant="contained"
                    color="info"
                  >
                    Download
                  </Button>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box p={2}>
                  <Typography variant="h6" fontSize={14} mb={0}>
                    {dataSelectStream.desc}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
