import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ApiService, azureFunctionCode } from '../../../constants/ApiService.Dev';

export const fetchEvaluationData = createAsyncThunk(
  'evaluation/fetchEvaluationData',
  async ({
    rowsPerPage,
    pages,
    searchInput,
    institution,
    academicCareer,
    term,
    track
  }: {
    rowsPerPage: number,
    pages: number,
    searchInput: string,
    institution: string,
    academicCareer: string,
    term: string,
    track: string,
  }, thunkAPI) => {
    try {
      const apiUrl = `${ApiService.apiPostman}/GetEvaluationData?code=${azureFunctionCode}`;
      const requestBody = {
        institution,
        academicCareer,
        term,
        track
      };
      const response = await axios.post(
        apiUrl,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            pageSize: rowsPerPage,
            pageNumber: pages,
            orderBy: 'id:asc',
            search: searchInput,
          },
        }
      );      
      console.log(response.data, 'Response Data');
      return response.data;
    } catch (error: any) {
      return axios.isAxiosError(error)
        ? thunkAPI.rejectWithValue(error.message)
        : thunkAPI.rejectWithValue('An unknown error occurred');
    }
  }
);

export const fetchEvaluationDataById = createAsyncThunk(
  'evaluation/fetchEvaluationDataById',
  async ({ id }: { id: string | null}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${ApiService.apiPostman}/GetEvaluationDataById?code=${azureFunctionCode}`,      
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            id,            
          },
        }
      );      
      return response.data;
    } catch (error: any) {
      return axios.isAxiosError(error)
        ? thunkAPI.rejectWithValue(error.message)
        : thunkAPI.rejectWithValue('An unknown error occurred');
    }
  }
);
