/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable unicorn/prefer-node-protocol */
import { useQuery } from '@tanstack/react-query';
import apiClient from '../config/api-client';
import { ApiGraph } from '../constants/ApiGraph';
import { GraphDataResponse } from '../types/graph';
import { UseQueryOptions } from '../types/react-query';

export const useQueryGetGraphData = ({
  options,
}: {
  options?: UseQueryOptions<GraphDataResponse>;
}) =>
  useQuery(
    ['graphData'],
    () =>
      apiClient
        .get<unknown, { data: GraphDataResponse }>(ApiGraph.getGraphData)
        .then((res) => res.data),
    options,
  );